import shm1 from "../images/shm1.png";
import shm2 from "../images/shm2.png";
import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';


const SystemHeatmap = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="System View - Heatmap" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <p> The System View provides a heatmap that shows the OEE for the machines in a line with a color going from red, to yellow, to green,
                                in comparison with the other machines on the line.</p>
                            <ol>
                                <br/>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img alt="Image of Optimizer" src={shm1}  width={"100%"} resizeMode={'center'}></img>
                                </Grid>
                                <br/>
                                <li>Hovering over one of the cells will show the availability, yield, OEE, and efficiency for that machine during that
                                time period.</li>
                                <br/>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img alt="Image of Optimizer" src={shm2}  width={"100%"} resizeMode={'center'}></img>
                                </Grid>
                                <br/>
                                <li>Similar to the Output chart, the Heatmap has a built in drilldown feature.</li>
                                <li>Clicking on any one of the boxes will drill down the other widgets to display data for that
                                machine for that time period.</li>
                                <li>The deselect for the the Heatmap works the same as the Output chart. Click on an already selected
                                    box/column header/row header to deselect the current selection, or double click within the chart.</li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {SystemHeatmap}