import {createContext, useContext, useEffect, useState} from "react";


const JokeContext = createContext({
                                      currentJoke: "",
                                      newJoke    : _ => _
                                  });


const JokeProvider = (props) => {
    const [currentJoke, setJoke]        = useState("")
    const [jokeList, setJokeList]       = useState([])
    const [jokeCounter, setJokeCounter] = useState(0)

    const newJoke = _ => {
        if (jokeCounter <= 30) {
            setJokeCounter(x => x + 1)
        }
        else {
            setJokeCounter(0)
        }
    }

    useEffect(() => {
        setJoke("")
        // setJoke(jokeList[jokeCounter])
    }, [jokeList, jokeCounter])

    useEffect(() => {
        fetch("https://api.api-ninjas.com/v1/dadjokes?limit=30", {
            method        : "GET",
            'Content-Type': 'application/json',
            headers       : {
                'X-Api-Key': 'kyWBct8W9ytT2ssCopV2Dw==MciO7C45bSIrWPtP'
            }
        })
            .then(async res => {
                let jokes = await res.json()
                setJokeList(jokes.map(({joke}) => joke))
            })
    }, [])

    const value = {
        currentJoke,
        newJoke
    }
    return (
        <JokeContext.Provider value={value}>
            {props.children}
        </JokeContext.Provider>
    )
}

function useJokeContext() {
    return useContext(JokeContext)
}

export {JokeProvider, useJokeContext}