import {createContext, useContext, useEffect, useState} from "react";
import {darkTheme, lightTheme, darkThemeCB, lightThemeCB, cursedTheme} from "../theme.js";
import { ThemeProvider, StyledEngineProvider, createTheme} from '@mui/material';
import * as d3 from 'd3'
const themes = {
	darkTheme:createTheme(darkTheme),
	lightTheme:createTheme(lightTheme),
	darkThemeCB:createTheme(darkThemeCB),
	lightThemeCB:createTheme(lightThemeCB),
	cursedTheme:createTheme(cursedTheme)
};

const ThemeContext = createContext({
	theme:themes.darkTheme, setTheme:()=>{},
	themeToggle:true, setThemeToggle:()=>{},
	colorblind:false, setColorblind:()=>{},
});

const ThemeContextProvider = (props)=>{
	const [theme, setTheme] = useState(themes.darkTheme);
	const [themeToggle, setThemeToggle] = useState(true);
	const [colorblind, setColorblind] = useState(false);
	const [cursedToggle, setCursed] = useState(false);
	const [axisRef, setAxisRef] = useState()

	useEffect(()=>{
		d3.select('body').style('background', theme.palette.background.default);
	},[theme]);

	useEffect(()=>{
		if(cursedToggle) {
			setTheme(themes.cursedTheme)
			return
		}
		if(colorblind)
			if(themeToggle)
				setTheme(themes.darkThemeCB);
			else
				setTheme(themes.lightThemeCB);
		else
			if(themeToggle)
				setTheme(themes.darkTheme);
			else
				setTheme(themes.lightTheme);
	},[themeToggle,colorblind,cursedToggle]);

	const value = {
		theme,setTheme,
		themeToggle,setThemeToggle,
		colorblind,setColorblind,
		axisRef, setAxisRef,
		setCursed
	}
	return (
        <ThemeContext.Provider value={value}>
			<StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {props.children}
                </ThemeProvider>
            </StyledEngineProvider>
		</ThemeContext.Provider>
    );
}

function useThemeContext() {
	return useContext(ThemeContext);
}

export {ThemeContextProvider, useThemeContext, themes};