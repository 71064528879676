import {CircularProgress, Typography} from "@mui/material";
import {models}                       from "powerbi-client";
import {PowerBIEmbed}                        from "powerbi-client-react";
import React, {useEffect, useMemo, useState} from "react";
import {useJokeContext}                      from "../Contexts/JokeProvider";


function EmbeddedDashboard(props) {
    const {
              loading,
              dashToken,
              refresh,
              loadingCircleRef,
              layoutType,
              setIsLoaded,
              setIsRendered,
              setDashboard,
          }             = props
    const {currentJoke} = useJokeContext()

    const RenderEmbed = useMemo(() => <>
        <div ref={loadingCircleRef}
             id="PBILoadingCircle"
             style={{
                 position: 'fixed',
                 top     : "Calc(50vh - 100px)",
                 left    : "Calc(50vw - 100px)",
                 zIndex: -99
             }}
        >
            <CircularProgress size={200}/>
            <img id="loadingLogo" src="/logo192.png" alt="loading logo"/>
            <Typography id="loadingText" textAlign="center" style={{color: "#fff"}}>
                Loading 4
            </Typography>
            <br/>
            <Typography id="loadingText"
                        textAlign="center"
                        style={{
                            color: "#fff",
                            width: 200
                        }}
            >{currentJoke}</Typography>
        </div>
        <PowerBIEmbed
            embedConfig={{
                accessToken: dashToken?.token,
                embedUrl   : dashToken?.embedUrl,
                id         : dashToken?.ID,
                type       : 'dashboard',
                pageView   : layoutType,
                tokenType  : models.TokenType.Embed
            }}
            eventHandlers={new Map(
                [
                    [
                        'loaded', (e) => {
                        e.target.style["opacity"]                 = 1
                        // loadingCircleRef.current.style["opacity"] = 0
                        setIsLoaded(true)
                        setIsRendered(true)
                    }
                    ]
                ]
            )}
            cssClassName="dashboardContainer"
            getEmbeddedComponent={(embedObject) => {
                setDashboard(embedObject);
            }}
        />
    </>, [dashToken?.token, dashToken?.embedUrl, dashToken?.ID, layoutType, refresh])

    const RenderLoading = useMemo(() => <>
        <div ref={loadingCircleRef}
             id="PBILoadingCircle"
             style={{
                 position: 'fixed',
                 top     : "Calc(50vh - 100px)",
                 left    : "Calc(50vw - 100px)",
             }}
        >
            <CircularProgress size={200}/>
            <img id="loadingLogo" src="/logo192.png" alt="loading logo"/>
            <Typography id="loadingText" textAlign="center" style={{color: "#fff"}}>
                Loading 3
            </Typography>
        </div>
    </>, [loadingCircleRef])

    const loadDashboard = useMemo(() => (!loading && (dashToken?.token && dashToken?.ID && dashToken?.embedUrl) && refresh), [loading, dashToken?.token, dashToken?.ID, dashToken?.embedUrl, refresh])

    return loadDashboard ? RenderEmbed : RenderLoading
}

export default EmbeddedDashboard