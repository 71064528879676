/*** Icon components using font-awesome icons ***/
import {Icon} from '@mui/material'
import './styles/fa-icon.css'
/**
 * font-awesome icon
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FAIcon = (props) => {
	const {
		style,
		id
	} = props

	return (
		<Icon id={id}
		      className={'fa'}
		      fontSize={props.size || "small"}
		      sx={{
			      padding: '5px',
			      color: "#2b2b2b",
			      ...style
		      }}>{props.icon}
		</Icon>);
}

/***
 * This one spins!
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FAIconSpin = props => {
	return (
		<Icon className='fa spinner'
		      style={{
			      padding: '5px', fontSize: props.size, ...props.style
		      }}>
			{props.icon}
		</Icon>);
};

export {FAIcon, FAIconSpin};
