import {LocalizationProvider, MobileDateTimePicker}     from "@mui/x-date-pickers";
import {AdapterMoment}                                  from "@mui/x-date-pickers/AdapterMoment";
import moment                                           from "moment/moment";
import {createContext, useContext, useEffect, useState} from "react";
import "../components/styles/Datepicker.css"
import {setSlicerToValues}                              from "../utils/PBI Utils/ApplyEventHandlers";
import {applyTimeRangeFilterToSlicer}                   from "../utils/PBI Utils/createMirrorFilter";


const DatePickerContext = createContext({
                                            startDate        : new Date(),
                                            endDate          : new Date(),
                                            setDates         : _ => _,
                                            setDatepickerOpen: _ => _,
                                            setReport        : _ => _
                                        });


const DateTimePickerProvider = (props) => {
    const [report, setReport]       = useState(undefined)
    const [startDate, setStart]     = useState(moment())
    const [endDate, setEnd]         = useState(moment())
    const [setter, setSetter]       = useState(false)
    const [openStart, setStartOpen] = useState(false)
    const [openEnd, setEndOpen]     = useState(false)

    const setDates = (start, end) => {
        setStart(start)
        setEnd(end)
    }

    const setDatepickerOpen = type => {
        if (type === "start") {
            setStartOpen(true)
        }
        else {
            setEndOpen(true)
        }
    }

    useEffect(_ => {
        if (!setter || !report) return

        async function becauseAsyncIsAThing() {
            await applyTimeRangeFilterToSlicer(startDate?.toISOString(), endDate?.toISOString(), report)
                .catch(e => console.log(e))
            await setSlicerToValues(report, "Quick Select", ["Custom Range"])
                .catch(e => console.log(e))
            setSetter(false)
        }

        becauseAsyncIsAThing()
            .catch(e=>console.log(e))
    }, [setter])

    const value = {
        startDate,
        endDate,
        setDates,
        setDatepickerOpen,
        setReport
    }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePickerContext.Provider value={value}>
                <MobileDateTimePicker open={openStart}
                                      onChange={setStart}
                                      onClose={() => {
                                          setStartOpen(false)
                                          setSetter(true)
                                      }}
                                      value={startDate}
                                      renderInput={props => <></>}
                                      label="Start Date"
                />
                <MobileDateTimePicker open={openEnd}
                                      onChange={setEnd}
                                      onClose={() => {
                                          setEndOpen(false)
                                          setSetter(true)
                                      }}
                                      value={endDate}
                                      renderInput={props => <></>}
                                      label="End Date"
                />
                {props.children}
            </DatePickerContext.Provider>
        </LocalizationProvider>
    )
}

function useDatePickerContext() {
    return useContext(DatePickerContext)
}

export {DateTimePickerProvider, useDatePickerContext}