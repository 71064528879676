import so1 from "../images/so1.png";
import so2 from "../images/so2.png";
import so3 from "../images/so3.png";
import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';


const SystemOutput = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="System View - Output" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <p>The System View provides an output chart that displays the output grouped in multiple forms:</p>
                            <ol>
                                <br/>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img alt="Image of Optimizer" src={so1}  width={"100%"} resizeMode={'center'}></img>
                                </Grid>
                                <br/>
                                <li>The first chart displays output grouped by which Lines the machines are a part of.</li>
                                <br/>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img alt="Image of Optimizer" src={so2}  width={"100%"} resizeMode={'center'}></img>
                                </Grid>
                                <br/>
                                <li>The second chart shows the output grouped by machine type.</li>
                                <br/>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img alt="Image of Optimizer" src={so3}  width={"100%"} resizeMode={'center'}></img>
                                </Grid>
                                <li>The third chart shows the output grouped by time interval.</li>
                            </ol>
                            <p>The purple line represents downtime percentage during the time period of each bar,
                                while the yellow bar represents reject percentage in the same manner.</p>
                            <p>All three of these charts have a drill down feature, allowing you to click on a bar to take a closer look at the data based on where you are in the hierarchy.</p>
                            <ol>
                                <li>Clicking on a bar for a designated machine in the Total By Machine output chart will drill down to the
                                machine designated to that bar. The machine's respective box will be highlighted on the heatmap, and the other widgets will drill
                                down to data for that time period for that specific machine.</li>
                                <li>The Total By Interval chart will allow you to drill down as well,
                                however, selecting a bar here will display the data for that entire line within the selected time period.</li>
                                <li>To deselect a bar once it has been clicked, either click on the bar that is currently selected again, or double click anywhere
                                inside the Output Chart.</li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {SystemOutput}