import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import "url-search-params-polyfill"
import {B64Encoder} from "../Encoder";
import {slicerLookup} from "./FilterUtils";


class urlLoad {
    constructor(url) {
        this.url = url;
        this.en = new B64Encoder()
        //if(schema.length > 0)
        //	this.en = new Encoder(schema,categories,tags);
    }

    load(prop, def) {
        const url = this.url;
        if (url.hasOwnProperty(prop)) {
            if (def instanceof Date) {
                if (!isNaN(Date.parse(url[prop]))) {
                    return new Date(url[prop]);
                } else {
                    return def;
                }
            } else if (Array.isArray(def)) {
                return url[prop].split(',');
            } else if (typeof def === 'object' && def !== null) {
                return this.en.decode(url[prop]);
            } else if (typeof def === 'boolean') {
                return url[prop] === 'true';
            } else {
                return url[prop];
            }
        } else {
            return def;
        }
    }
}

function setParams(params) {
    const searchParams = new URLSearchParams(window.location.search.substring(1));
    //console.log("THE URL PARAMS HERE ARee ", Object.fromEntries(searchParams.entries()));
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            if (params[key] && params[key].toString()) {
                searchParams.set(key, params[key])
            } else {
                searchParams.delete(key)
            }
        }
    }
    return searchParams.toString()
}

function useQuery() {
    return new URLSearchParams(useLocation()
        .search
        .substring(1));
}

const PBIReportURLSetter = (props) => {

    const {
        currentDataset,
        currentReport,
        setCurrentDataset,
        setCurrentReport,
        report,
        renderCount,
        isRendered,
        isLoaded
    } = props

    const query = useQuery();
    const url = Object.fromEntries(query.entries());
    const params = new urlLoad(url);

    //const en = new Encoder(selection.schema,selection.categories,selection.tags)
    // const en = new B64Encoder()

    window.onpopstate = () => {
        setCurrentDataset(params.load("product", currentDataset))
        setCurrentReport(params.load("view", currentReport))
    };

    const updateURL = (params) => {
        window.history.pushState({}, null, `?`)
        const url = setParams(params);
        window.history.pushState({}, null, `?${url}`)
    }

    const updateURL_DB = (params) => {
        clearTimeout(window.updateURLTimeout)
        window.updateURLTimeout = setTimeout(() => updateURL(params), 1000)
    }

    //Machine slicers
    // - title = location/machines/etc
    // - type = ChicletSlicer....

    const [URLFilters, setFilters] = useState([])
    const [ChartSelect, setChart] = useState()
    const [ViewSelect, setView] = useState("")
    const [PageSelect, setPage] = useState("")
    const [currentBookmarks, setBookmarks] = useState([])
    const [currentViews, setViews] = useState([])
    const [currentPages, setPages] = useState([])
    useEffect(() => {
        async function onRenderedFilters() {
            let page = await report.getActivePage()
            let slicers = await page.getVisuals()
            slicers = slicers.filter(visual => visual.type.toLowerCase()
                .includes("slicer"))
            let filters = [
                ["view", ViewSelect],
                ["charts", ChartSelect],
                PageSelect !== "Dashboard" ? ["page", PageSelect] : null
            ]
            for (const slicer of slicers) {
                let state = await slicer.getSlicerState()
                if (state.filters[0]) {
                    for (let filter of state.filters) {
                        if (filter.target.table !== "TimeZoneMapping") {
                            filters.push([filter.target, filter.values])
                        } else {
                            filters.push([filter.target, filter.conditions])
                        }
                    }
                }
            }
            return filters
        }

        if (report !== undefined && renderCount) {
            onRenderedFilters()
                .then(setFilters)
        }
        if (!renderCount) {
            setFilters([])
        }
    }, [report, renderCount, ChartSelect, ViewSelect, PageSelect])

    //Automatic event listeners for views and charts
    useEffect(() => {
        if (isRendered) {
            report.bookmarksManager.getBookmarks()
                .then(bookmarks => {
                    let bookmarkGroups = bookmarks.filter(bookmark => bookmark.displayName !== "Dashboards")
                        .map(bookmark => bookmark.displayName)
                    let views = bookmarks.filter(bookmark => bookmark.displayName
                        === "Dashboards")[0]?.children
                    if (!views) return
                    let displayNames = []
                    views.forEach(view => {
                        window.addEventListener(view.displayName.toLowerCase(), _ => {
                            setView(view.displayName)
                        })
                        displayNames.push(view.displayName)
                    })
                    setBookmarks(displayNames)
                    displayNames = []
                    bookmarkGroups.forEach(group => {
                        bookmarks.find(x => x.displayName === group)
                            .children
                            .forEach(bookmark => {
                                window.addEventListener(group.toLowerCase() + bookmark.displayName.toLowerCase(),
                                    _ => {
                                        setChart(x => ({
                                            ...x,
                                            [group]: bookmark.displayName
                                        }))
                                    })
                                displayNames.push([group, bookmark.displayName])
                            })
                    })
                    setViews(displayNames)
                })
            report.getPages()
                .then(pages => {
                    let displayNames = []
                    pages.forEach(page => {
                        window.addEventListener(page.displayName.toLowerCase(), _ => {
                            setPage(page.displayName)
                        })
                        displayNames.push(page.displayName)
                    })
                    setPages(displayNames)
                })
        } else {
            // remove event listeners
            currentBookmarks.forEach(bookmark => {
                window.removeEventListener(bookmark.toLowerCase(), _ => {
                    setView(bookmark)
                })
            })
            currentViews.forEach(view => {
                window.removeEventListener(view[0].toLowerCase() + view[1].toLowerCase(), _ => {
                    setChart(x => ({
                        ...x,
                        [view[0]]: view[1]
                    }))
                })
            })
            currentPages.forEach(page => {
                window.removeEventListener(page.toLowerCase(), _ => {
                    setPage(page)
                })
            })
            setBookmarks([])
        }
    }, [isRendered, isLoaded])

    useEffect(() => {
        try {
            let newParams = {}
            if (currentDataset && currentReport) {
                newParams = {
                    product: currentDataset
                }
            }
            if (URLFilters.length) {
                URLFilters.forEach(value => {
                    if (value[0] === "page") {
                        newParams["page"] = value[1]
                        return
                    }
                    if (value[0] === "view") {
                        newParams["view"] = value[1]
                        return
                    }
                    if (value[0] === "charts" && value[1]) {
                        newParams["charts"] = JSON.stringify(value[1])
                        return
                    }
                    if (value[0].table === "MACHINELOCATIONS" || value[0].table === "SCHEMA") {
                        newParams[value[0].column] = value[1].join('|')
                    }
                    let type = value[0].table + "|" + value[0].column
                    if (slicerLookup[type]?.parameter === "Time") {
                        newParams["Time"] = [value[1][0].value, value[1][1].value]
                    } else if (slicerLookup[type]) {
                        newParams[slicerLookup[type].parameter] = value[1].join('|')
                    }
                })
            }
            updateURL_DB(newParams)
        } catch (e) {
            console.log("ERROR IN URL SETTER", e)
        }
    }, [currentDataset, currentReport, updateURL, URLFilters])

    return null
}

const PBIDashURLSetter = _ => {
    return <></>
}

export {urlLoad, PBIReportURLSetter, PBIDashURLSetter}