import Grid from "@mui/material/Grid";
import './404.css';

const PageNotFound = props => {
	return <Grid className="grid404" container>
		<section className="pressed">
			<span>404</span><br/>
			<h6>Page Not Found</h6>
			<p>Check URL and try again</p>
		</section>
	</Grid>
}

export default PageNotFound