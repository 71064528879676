import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';
import download from "../images/Ellipses.png";
import download2 from "../images/download2.png";


const DownloadingData = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Downloading Data" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img alt="Image of Optimizer" src={download} height={"100%"} resizeMode={'contain'}></img>
                            </Grid>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img alt="Image of Optimizer" src={download2} height={"100%"} resizeMode={'contain'}></img>
                            </Grid>
                            <br/>
                            <ol>
                                <p>You can download data from any chart within OEEOptimizer ®.</p>
                                <li>To download data from a specific chart, click the ellipses (...) button, which appears
                                in the top right of the chart when you hover within it.</li>
                                <li>Clicking on this button will open a small menu. Click "Export Data" to download the data
                                from that chart as a CSV.</li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {DownloadingData}