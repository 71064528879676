import {WikiEntry} from "../WikiEntry";
import datepicker1 from "../images/datepicker1.JPG";
import datepicker2 from "../images/datepicker2.png";
import quick1 from "../images/quick1.png";
import {Grid, Typography} from '@mui/material';

const DatePicker = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Datepicker" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography style={{overflowWrap: 'break-word'}}>
                            OEEOptimizer ® has two ways to select the timeframe of data that displays:
                        </Typography>
                        <br/>
                        <ol>
                        <Grid item xs style={{textAlign: 'center'}}>
                            <img src={datepicker1} width={null} height={"25%"} resizeMode={'center'}
                                 alt="Image of Optimizer"
                            ></img>
                        {/*    <img src={datepicker2}  width={"15%"} resizeMode={'center'}></img>*/}
                        {/*    <img src={datepicker3} width={"15%"} resizeMode={'center'}></img>*/}
                        </Grid>
                            <br/>
                            <Typography>
                                <li> Indicator 1: Calendar Selection</li>
                                    <ol>
                                        <li>Selecting the left calendar date will open the start date calendar menu.</li>
                                        <li>Select a day and time that you would like the timeframe to start from in the menus that appear.</li>
                                        <Grid item xs style={{textAlign: 'center'}}>
                                            <img src={datepicker2} flex={1} width={null} height={"25%"} resizeMode={'contain'}
                                                 alt="Image of Optimizer"
                                            ></img>
                                        </Grid>
                                        {/*<li>After selecting the day, Select the hour by clicking on the ring of numbers (seen in the 2nd picture above). The external ring of numbers*/}
                                        {/*    denotes the hours between 12PM to 11AM. The internal ring of numbers denotes the hours between 1PM to 12AM*/}
                                        {/*    (In 24-hour time format with “13” being for 1PM and “00” for 12AM)</li>*/}
                                        {/*<li>Then, select the minute of your timeframe by clicking on a number (seen in the 3rd picture above). The selections are in 5-minute intervals starting at the top of the*/}
                                        {/*    hour (HH:00). Individual minute selections can be made within this window (i.e. HH:02) but it is advised to use Indicator 2: User Input*/}
                                        {/*    Selection instead for higher accuracy.</li>*/}
                                        <li>Repeat for the right calendar icon for the end date and time for your desired timeframe.</li>
                                    </ol>
                            </Typography>
                            <br/>
                            <br/>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={quick1} width={"20%"}  resizeMode={'center'}
                                 alt="Image of Optimizer"
                            ></img>
                            </Grid>
                            <br/>
                            <Typography>
                                <li>Indicator 2: Quick Selection</li>
                                    <ol>
                                        <li>Clicking on the dropdown will open the quick select menu as shown in the figure above.</li>
                                        <ul>
                                            <li>This Hour: Returns data for the current hour.</li>
                                            <li>Last 60 Minutes: Returns rolling data for the last hour.</li>
                                            <li>Last Hour: Returns data for the previous hour, starting at 0 minutes and ending at the current hour at 0 minutes.</li>
                                            <li>Today: Returns data starting from midnight to the current time of the current day.</li>
                                            <li>Yesterday: Returns data between midnight the day prior and the current day.</li>
                                            <li>This Week: Returns data starting from midnight on Sunday of the current week to the current time of the current day.</li>
                                            <li>Last 7 Days: Returns data from the last 7 days.</li>
                                            <li>Last Week: Returns data from the last calendar week.</li>
                                            <li>This Month: Returns data starting from midnight on the first day of the current month to the current time of the current day.</li>
                                            <li>Last 30 Days: Returns data from the last 30 days.</li>
                                            <li>Last Month: Returns data from the last calendar month.</li>
                                            <li>This Quarter: Returns data from midnight on the first day of the current quarter to the current time of the current day.</li>
                                            <li>Last Quarter: Returns data from the last quarter.</li>
                                            <li>This Year: Returns data from the beginning of the current calendar year to the current time of the current day.</li>
                                            <li>Last Year: Returns data from the last calendar year.</li>
                                            <li>Custom Range: Autofilled option when using Calendar Selection.</li>
                                        </ul>
                                    </ol>
                            </Typography>
                        </ol>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }



    return (<>
            {page()}
        </>
    )
}

export {DatePicker}