import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';
import mach1 from "../images/mach1.png";
import mach2 from "../images/mach2.png";
import mach3 from "../images/mach3.PNG";
import mach4 from "../images/mach4.PNG";


const MachineSelections = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
    return (<>
        {/*The total height for each page should add up to 15*/}
        <WikiEntry key='cellBC' title="Machine Selections" height={15} width={3} screenHeight={screenSize.screen.height}>
            <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                  style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
            </Grid>
            <Grid container direction='column' alignItems="center" justifyContent={"center"}
                  style={{width: "100%", height: "80%", userSelect: "none"}}>
                <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Grid item xs style={{textAlign: 'center'}}>
                            <img alt="Image of Optimizer" src={mach1} flex={1} width={null} height={"25%"} resizeMode={'contain'}></img>
                        </Grid>
                    <br/>
                    <Typography>
                        <ol>
                            <li>The location selection buttons are underneath the date selection options on the left side of the page.
                                Click on any of these buttons and they will highlight with a light gray fill. The above selections are for Cell View.</li>
                            <li>After selecting a location, the Machine selection button group will filter to the machines available in that location.
                                Click on the available options to display data from only those machines.</li>
                            <br/>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img alt="Image of Optimizer" src={mach2} flex={1} width={null} height={"25%"} resizeMode={'contain'}></img>
                            </Grid>
                            <br/>
                            <li>After the loading wheels disappear from the chart areas, the data from the selected machines will display.</li>
                            <br/>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img alt="Image of Optimizer" src={mach3} flex={1} width={null} height={"25%"} resizeMode={'contain'}></img>
                            </Grid>
                            <br/>
                            <li>System View provides a different set of selections. It provides selections
                                for island, location, and line.</li>
                            <br/>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </WikiEntry>
    </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {MachineSelections}