import {Button, Grid, Paper, useMediaQuery, useTheme} from '@mui/material';
import {useAuth0} from "@auth0/auth0-react";
import {PbiContextProvider} from "../Contexts/PbiContextProvider";
import React, {useEffect, useMemo, useState} from 'react'
import {TokenProvider} from "../Contexts/TokenProvider"
import NthDrawer from "../components/NthDrawer";
import {themes, useThemeContext} from "../Contexts/ThemeProvider";
import {useParams} from "react-router-dom";
import UserActivity from "./UserActivity";

/**
 * Provides Layout for Power BI page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PbiLayout(props) {
	const {isAuthenticated, loginWithPopup, user, logout} = useAuth0();
	const [loginError, setLoginError] = useState(false)
	/*** User Metadata Variables ***/
	const userAccess = useMemo(() => {
		if (user && user[`${window.location.origin}/app_metadata`]) {
			return user[`${window.location.origin}/app_metadata`] || {}
		}
		return {}
	}, [user])
	/*** End User Metadata Variables ***/
	const pdfConfig = useParams()

	const theme = useTheme()
	const {setTheme} = useThemeContext()
	const mobile = useMediaQuery(theme.breakpoints.down('md'))

	useEffect(() => {
		setTheme(themes.darkTheme)
	}, [])

	return (
		<TokenProvider loginError={{loginError, setLoginError}}>
			{isAuthenticated ?
				userAccess?.superAdmin ?
					<PbiContextProvider>
						<UserActivity {...{pdfConfig}}/>
					</PbiContextProvider> :
					<Grid item container justifyContent='center' alignContent='center'>
						<Button variant="outlined" onClick={() => logout({returnTo: window.location.origin})}>
							You do not have access to this page. Click to log out
						</Button>
					</Grid> :
				<>
					{pdfConfig.pdf ? null : <NthDrawer/>}
					<Paper style={{width: '100%', height: '100%'}}>
						<Grid style={{height: '100%'}} xs={12} item container
						      justifyContent="center"
						      alignContent="center">
							<Grid item>Please <Button
								onClick={loginWithPopup}>Log
								in</Button> to
								continue</Grid>
						</Grid>
					</Paper>
				</>}
		</TokenProvider>
	)
}