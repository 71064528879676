import timeRangeISOStringGeneratorSwitch from "../timeRangeISOStringGeneratorSwitch";
import {applyTimeRangeFilterToSlicer} from "./createMirrorFilter";

/**
 * Sets the report's custom range to the initial value.
 *
 * @param {Object} report - The report object.
 * @param {string} timeRange - The time range.
 * @param {Array[Date]} timeFilterInput - The time filter input.
 */
export function setCustomRangeToInitialValue(report, timeRange, timeFilterInput) {
    if (report === undefined) return
    const [startDateISOString, endDateISOString] = timeRangeISOStringGeneratorSwitch(timeRange)
    let [startISO, endISO] = timeFilterInput?.length ? setInputDateRange(
        timeFilterInput[0],
        timeFilterInput[1]) : [null, null]
    startISO ? applyTimeRangeFilterToSlicer(startISO, endISO, report)
        .catch(e => console.log(e)) : applyTimeRangeFilterToSlicer(startDateISOString,
        endDateISOString,
        report)
        .catch(e => console.log(e))
}

/**
 * Generates an ISO date range based on the given start and end dates.
 *
 * @param {Date} start - The starting date of the range.
 * @param {Date} end - The ending date of the range.
 * @return {Array[string]} An array containing the start and end dates in ISO string format.
 */
export function setInputDateRange(start, end) {
    let dateStart = new Date(start)
    let dateEnd = new Date(end)
    dateEnd.setMinutes(dateEnd.getMinutes() + 10)
    return [dateStart.toISOString(), dateEnd.toISOString()]
}