import NthDrawer from "../components/NthDrawer";
import {usePbiContext} from "../Contexts/PbiContextProvider";
import EmbeddedReport               from "../components/EmbeddedReport";
import {useEffect, useMemo, useRef} from "react";
import {models}                     from "powerbi-client";
import {CircularProgress} from "@mui/material";

function UserActivity(props) {
	const {
		tokens,
		productPath,
		loading,
		datasetIDs,
		reportIDs,
		setDevReport,
		setDevDataset,
		setDevSubmit
	} = usePbiContext()

	const reportToken = useMemo(()=>tokens[productPath], [tokens, productPath])

	const refresh = true
	const loadingCircleRef = useRef()
	const devMode = true
	const layoutType = models.LayoutType.Custom
	const setIsLoaded = () => {
	}
	const setReport = () => {
	}
	const setRenderCount = () => {
	}
	const setIsRendered = () => {
	}
	const [currentReport, currentDataset] = ["1c348aae-b8ce-4191-a385-feba8e8c6455", "ea0d3762-44ab-46d8-a28c-6daff9612249"]

	useEffect(() => {
		setDevSubmit(true)
		setDevDataset(currentDataset)
		setDevReport(currentReport)
	})

	return <>
		<NthDrawer/>
		<div style={{
			position: 'absolute', left: 32, width: `Calc(100% - 32px)`
		}}>
			{reportToken ? <EmbeddedReport {...{
				loading,
				reportToken,
				refresh,
				currentReport,
				currentDataset,
				loadingCircleRef,
				devMode,
				reportIDs,
				datasetIDs,
				layoutType,
				setIsLoaded,
				setIsRendered,
				setReport,
				setRenderCount
			}}/> : <div ref={loadingCircleRef}
			            id="PBILoadingCircle"
			            style={{position: 'fixed', top: "Calc(50vh - 100px)", left: "Calc(50vw - 100px)"}}
			>
				<CircularProgress size={200}/>
				<img id="loadingLogo" src="/logo192.png" alt="loading logo"/>
			</div>}
			<div>Future Home of User Activity</div>
		</div>
	</>
}

export default UserActivity