import {WikiEntry} from "../WikiEntry";
import navbar from "../images/navbar.png";
import productselect from "../images/productselect.PNG";
import productPane from "../images/ProductPane.png";
import productPaneLinks from "../images/ProductPaneLinks.png";
import {Grid, Typography} from '@mui/material';

const NavigationIcons = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Product Navigation" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%", overflowWrap: 'break-word', textAlign: 'center'}}>
                        <Grid item xs style={{textAlign: 'center'}}>
                            <img alt="Image of Optimizer" src={productPane} flex={1} width={null} height={"100%"} resizeMode={'contain'}></img>
                        </Grid>
                        <br/>
                        <Typography>To access product selection options, click on the products pane located on the right side of the screen.
                                    This will then provide all of the options shown below.</Typography>
                        <br/>
                        <Grid item xs style={{textAlign: 'center'}}>
                            <img alt="Image of Optimizer" src={navbar} flex={1} width={null} height={"100%"} resizeMode={'contain'}></img>
                        </Grid>
                        <br/>
                        <Typography>
                            From left to right, the navigation buttons do the following:
                                <li>The first button takes you back to the home page.</li>
                                <li>The second button can be used to sign in/sign out.</li>
                                <li>The third button can be used to send feedback or submit bugs.</li>
                                <li>The fourth button can be used to access additional resources, such as the release notes, this wiki,
                                    and a tutorial playlist on YouTube.</li>
                        </Typography>
                        <br/>
                        <Grid item xs style={{textAlign: 'center'}}>
                            <img alt="Image of Optimizer" src={productselect} flex={1} width={null} height={"100%"} resizeMode={'contain'}></img>
                        </Grid>
                        <br/>
                        <Typography>
                            Use the product selector to go to the product of your choice.<br></br>
                            *Note: Product selection options may vary depending on access level.
                        </Typography>
                        <br/>
                        <Grid item xs style={{textAlign: 'center'}}>
                            <img alt="Image of Optimizer" src={productPaneLinks} flex={1} width={null} height={"100%"} resizeMode={'contain'}></img>
                        </Grid>
                        <br/>
                        <Typography>There are additional options below the product choices as well. These may vary depending on access level. Below is an explanation of each shown in the image:
                            <li>OEE Optimizer: Returns you to the main page of the Optimizer.</li>
                            <li>PLC Code Repository: Takes you to the AutomationNTH PLC code repository (requires Copia credentials).</li>
                            <li>Knowledge Base: Takes you to the AutomationNTH knowledge base (requires Atlassian credentials).</li>
                            <li>Submit a Ticket: Allows you to submit a support ticket to CSO.</li>
                        </Typography>
                        <br/>
                        {/*<Grid item xs style={{textAlign: 'center'}}>*/}
                        {/*    <img src={themetoggle} flex={1} width={null} height={"100%"} resizeMode={'contain'}></img>*/}
                        {/*</Grid>*/}
                        {/*<Typography>*/}
                        {/*    This toggle changes the theme of the page between Dark theme and Light theme*/}
                        {/*</Typography>*/}
                        {/*<Grid item xs style={{textAlign: 'center'}}>*/}
                        {/*    <img src={colorblindtoggle} flex={1} width={null} height={"100%"} resizeMode={'contain'}></img>*/}
                        {/*</Grid>*/}
                        {/*<Typography>*/}
                        {/*    The Colorblind Mode Toggle changes up the chart colors to more Colorblind-Friendly colors*/}
                        {/*</Typography>*/}
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }



    return (<>
            {page()}
        </>
    )
}

export {NavigationIcons}