/**
 * Contains all metadata, and their equivalent roles and permission level
 * @type {{}}
 */

// FIXME: Programmatically add customer by automating (in this order):
	//	- add productAccess, productAdmin, companyAdmin, companySuperAdmin
	//  - add Product Credentials
	//  - add Company Credentials
	//  - add Company to Roles

const metadataToRole = {
	"g6Access": "G6",
	"g7Access": "G7",
	"g7FASTrAccess": "G7",
	"g6Admin": "DEXCOMSUPER",
	"g7Admin": "DEXCOMSUPER",
	"g7FASTrAdmin": "DEXCOMSUPER",
	"cavistAdmin": "CAVISTADMIN",
	"cavistAccess": "CAVIST",
	"dexcomAdmin": "DEXCOMSUPER",
	"dexcomSuperAdmin": "DEXCOMSUPER",
	"scotchAccess": "SCOTCH",
	"scotchAdmin": "ILLUMINASUPER",
	"scotchSuperAdmin": "ILLUMINASUPER",
	"demoAccess": "DEMO",
	"devAdmin": "DEV",
	"superAdmin": "SUPER",
}
/*** Dexcom Credentials ***/
const G6 = {
	roles: [
		"rol_HmvnaWX30kckjsGo"
	],
	metadata: {"g6Access": true}
}

const G7 = {
	roles: [
		"rol_maOsx72mGKuzYS0I"
	],
	metadata: {"g7Access": true}
}

const G7FASTr = {
	roles: [
		"rol_aVt7CjhtMpI17Mpu"
	],
	metadata: {"g7FASTrAccess": true}
}

const CAVIST = {
	roles: [
		"rol_F4f8jwP2lGX9ve2K",
		"rol_maOsx72mGKuzYS0I"
	],
	metadata: {"cavistAccess": true}
}

const CAVISTADMIN = {
	roles: CAVIST.roles,
	metadata: {
		...CAVIST.metadata,
		"cavistAdmin": true
	}
}

const DEXCOMSUPER = {
	roles: [
		...G6.roles,
		...G7.roles,
		...G7FASTr.roles,
	],
	metadata: {
		...G6.metadata,
		...G7.metadata,
		...G7FASTr.metadata,
		"g6Admin": true,
		"g7Admin": true,
		"g7FASTrAdmin": true,
		"dexcomSuperAdmin": true,
		"dexcomAdmin": true,
	}
}

const DEXCOM = {
	G6,
	G7,
	G7FASTr,
	DEXCOM: {
		roles: [
			...G6.roles,
			...G7.roles,
			...G7FASTr.roles,
		],
		metadata: {
			...G6.metadata,
			...G7.metadata,
			...G7FASTr.metadata,
		}
	},
	DEXCOMSUPER
}

/*** Illumina Credentials ***/
const SCOTCH = {
	roles: [
		"rol_TPhy7junFC7D3mHK"
	],
	metadata: {"scotchAccess" : true}
}

const ILLUMINASUPER = {
	roles: [
		...SCOTCH.roles
	],
	metadata: {
		...SCOTCH.metadata,
		"scotchAdmin": true,
		"illuminaSuperAdmin": true,
		"illuminaAdmin": true
	}
}

const ILLUMINA = {
	SCOTCH: SCOTCH,
	ILLUMINA: {
		roles: [
			...SCOTCH.roles
		],
		metadata: {
			...SCOTCH.metadata
		}
	},
	ILLUMINASUPER
}

/*** Demo Credentials ***/
const DEMO = {
	roles: [
		...G6.roles
	],
	metadata: {"demoAccess": true}
}

/*** Roles Object ***/
const roles = {
	...DEXCOM,
	...ILLUMINA,
	CAVIST,
	CAVISTADMIN,
	DEMO,
	DEV: {
		roles: [
			"rol_lA4ITh1Dz19mUw7l"
		],
		metadata: {
			...DEMO.metadata,
			...DEXCOM.DEXCOMSUPER.metadata,
			...ILLUMINA.ILLUMINASUPER.metadata,
			...CAVISTADMIN.metadata,
			"devAdmin": true,
			"superAdmin": true,
		}
	},
	SUPER: {
		roles: [
			"rol_6bFB6gRG5l93sGn7"
		],
		metadata: {
			...DEMO.metadata,
			...DEXCOM.DEXCOM.metadata,
			...ILLUMINA.ILLUMINA.metadata,
			"superAdmin": true
		}
	}
}

export {roles, metadataToRole}