import {useEffect, useRef} from "react";

/**
 * Sets a React friendly interval hook
 * @param callback {function} - the function to run after the interval
 * @param delay {number} - the time for the interval
 * @param resetDelay
 */
function useInterval(callback, delay, resetDelay="") {
	const savedCallback = useRef(_=>_);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay, resetDelay]);
}
export default useInterval