import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
import {forwardRef} from "react";

const LoadingCircle = forwardRef((props, ref) => {
    return <div ref={ref} id="PBILoadingCircle" style={props.style}>
        <CircularProgress size={200}/>
        <img id="loadingLogo" src="/logo192.png" alt="loading logo"/>
        <Typography width="100%" textAlign="center" style={{color: "#fff"}} id="loadingText">
            {props.loadingText}
        </Typography>
        <br/>
        <Typography width={200} textAlign="center" style={{color: "#fff"}} id="loadingText">
            {props.currentJoke}
        </Typography>
    </div>
})

export default LoadingCircle