export default function calcWidgetAndTitleDimensions(height, width, screenHeight) {
    let widgetHeight, widgetWidth, titleSize, titleWidth
    let xHeight = screenHeight < 900 ? 900 : screenHeight

    switch (height) {
        case 5:
            widgetHeight = xHeight * 1 / 3
            titleSize = 12.5
            break;
        case 4:
            widgetHeight = xHeight * 4 / 15
            titleSize = 15
            break;
        case 3:
            widgetHeight = xHeight * 1 / 5
            titleSize = 20
            break;
        case 2:
            widgetHeight = xHeight * 2 / 15
            titleSize = 30
            break;
        case 1:
            widgetHeight = xHeight / 15
            titleSize = 60
            break;
        default:
            widgetHeight = height > 3 ? xHeight / 2 : xHeight / 16
            titleSize = 10
    }

    switch (width) {
        case 1:
            widgetWidth = 3
            titleWidth = 80
            break;
        case 2:
            widgetWidth = 6
            titleWidth = 40
            break;
        case 3:
            widgetWidth = 12
            titleWidth = 20
            break;
        default:
            widgetWidth = height > 3 ? 12 : 1
    }
    return {widgetHeight, widgetWidth, titleSize, titleWidth}
}