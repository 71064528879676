import {Typography, Grid, Paper, useTheme} from '@mui/material'
import {FAIcon} from "./fa-icon";

/**
 * Creates a widget with a random error GIF inside and provides an error message
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorMessage = (props)=>{
    let theme = useTheme();
    let {message=''} = props

    return (<>
        <Paper style={{width: "100%", height: "80%", maxHeight: '300px', background: theme.palette.background?.paperGradient}}>
            <Grid container direction='column' alignItems='center' alignContent='center' justifyContent='center' style={{width: '100%',height: '100%'}}>
                <Grid item style={{height: 'max-content',minHeight: '20%', maxHeight: '100%',width: 'max-content',maxWidth: '100%'}}>
                    <Typography alignItems='center'>
                        {message}
                    </Typography>
                </Grid>
                <Grid item style={{height: 'max-content'}}>
                    {/*<img alt="Comical GIF showing an error occurring" src={gifs[i]} style={{ maxHeight: '100%', maxWidth: '100%'}}/>*/}
                    <FAIcon icon="" style={{fontSize: '-webkit-xxx-large'}}/>
                </Grid>
            </Grid>
        </Paper>
    </>)
}

export default ErrorMessage