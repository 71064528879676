/**
 * function that creates date objects and transforms them based on string label selected. Returns ISO-strings
 * @param {string} timeRangeLabel - Time Range Selection in Text
 * @returns {Date[startDate, endDate]}
 */
function timeRangeISOStringGeneratorSwitch(timeRangeLabel) {
	let startDate = new Date();
	startDate.setHours(0, 0, 0, 1)
	let endDate = new Date()
	endDate.setHours(0, 0, 0, 1)

	let weekOffset = startDate.getDay()
	let thisQuarter = Math.floor(startDate.getMonth() / 3)
	let lastQuarter = thisQuarter === 0 ? 3 : thisQuarter - 1
	let nextQuarter = thisQuarter === 3 ? 0 : thisQuarter + 1
	let addYearForQuarter = thisQuarter === 3
	let subtractYearForQuarter = thisQuarter === 0

	switch (timeRangeLabel) {
		case 'This Hour':
			endDate = new Date()
			startDate = new Date()
			startDate.setMinutes(0)
			startDate.setSeconds(0, 0)
			break;
		case 'Last 60 Minutes':
			endDate = new Date()
			startDate = new Date()
			startDate.setMinutes(Math.floor(startDate.getMinutes() / 10) * 10)
			startDate.setSeconds(0, 0)
			startDate.setHours(startDate.getHours() - 1)
			break;
		case 'Last Hour':
			endDate = new Date()
			endDate.setMinutes(0)
			endDate.setSeconds(0,0)
			startDate = new Date()
			startDate.setHours(startDate.getHours() - 1)
			startDate.setMinutes(0)
			startDate.setSeconds(0, 0)
			break;
		case 'Today':
			endDate = new Date()
			break;
		case 'Yesterday':
			startDate.setDate(startDate.getDate() - 1)
			break;
		case 'This Week':
			startDate.setDate(startDate.getDate() - weekOffset)
			endDate.setDate(endDate.getDate() + 7 - weekOffset)
			break;
		case 'Last Week':
			startDate.setDate(startDate.getDate() - (weekOffset + 7))
			endDate.setDate(endDate.getDate() - weekOffset)
			break;
		case 'Last 7 Days':
			startDate.setDate(startDate.getDate() - 7)
			break;
		case 'This Month':
			startDate.setDate(1)
			endDate.setDate(1)
			endDate.setMonth(endDate.getMonth() + 1)
			break;
		case 'Last Month':
			startDate.setDate(1)
			startDate.setMonth(startDate.getMonth() - 1)
			endDate.setDate(1)
			break;
		case 'Last 30 Days':
			startDate.setDate(startDate.getDate() - 30)
			break;
		case 'This Quarter':
			startDate.setDate(1)
			startDate.setMonth(thisQuarter * 3)
			endDate.setDate(endDate.getDate() + 1)
			break;
		case 'Last Quarter':
			startDate.setDate(1)
			startDate.setMonth(lastQuarter * 3)
			if (subtractYearForQuarter) startDate.setFullYear(startDate.getFullYear() - 1)
			endDate.setDate(1)
			endDate.setMonth(thisQuarter * 3)
			break;
		case 'This Year':
			startDate.setMonth(0)
			startDate.setDate(1)
			endDate.setMonth(0)
			endDate.setDate(1)
			endDate.setFullYear(endDate.getFullYear() + 1)
			break;
		case 'Last Year':
		default:
			startDate.setMonth(0)
			startDate.setDate(1)
			startDate.setFullYear(startDate.getFullYear() - 1)
			endDate.setMonth(0)
			endDate.setDate(1)
			break;
	}
	startDate = startDate.toISOString()
	endDate = endDate.toISOString()
	return [startDate, endDate]
}

export default timeRangeISOStringGeneratorSwitch