import {createBrowserHistory} from "history";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";

const browserHistory = createBrowserHistory({basename: ''})

const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
    config: {
        connectionString: "InstrumentationKey=30819df5-6f32-49c4-9d57-d44b9d037437;",
        disableExceptionTracking: true,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory}
        }
    }
})
appInsights.loadAppInsights()
appInsights.trackPageView()

export {reactPlugin}