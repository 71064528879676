import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';
import gif from "../images/CellDetails.gif"
import cd from "../images/CellViewCellDetails.png";

const CellDetails = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Cell View - Cell Details" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <p>To access a more in-depth look at a single cell in the system, right-click a machine bar
                                in the Total by Machine output chart. Once you've done this, navigate to the drill through option,
                                then select Cell Details. An example of how to do this is shown in the gif below.</p>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={gif}  height={"100%"} width={"100%"} resizeMode={'left'}
                                     alt="Image of Output by Machine Chart"
                                ></img>
                            </Grid>
                            <p>The resulting page is shown below. This page provides a deep dive into events for the selected cell
                                within the selected time period.</p>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={cd}  height={"100%"} width={"100%"} resizeMode={'left'}
                                     alt="Image of Output by Machine Chart"
                                ></img>
                            </Grid>
                        </Typography>
                        {/*<p>The Reject Reasons Chart sits either to the right or below of the Downtime Events chart depending on the viewing width.</p>*/}
                        {/*<p>Selecting a Reject Reason will now highlight every occurrence in the timeline at when they occurred.*/}
                        {/*    Left-clicking on a Reject Reason bar will highlight the bar within the chart area as well as*/}
                        {/*    filter the reject reasons within the timeline so that only the selected reject reason is displayed. </p>*/}
                        {/*<h2>Reject Reasons Chart: </h2>*/}
                        {/*<ol>*/}
                        {/*    <h3>Chart Area: </h3>*/}
                        {/*    <Grid item xs style={{textAlign: 'center'}}>*/}
                        {/*        <img src={crr1}  height={"25%"} width={"100%"} resizeMode={'center'}></img>*/}
                        {/*    </Grid>*/}
                        {/*    <ol> </ol>*/}
                        {/*    <p></p> /!*paragraph break*!/*/}
                        {/*    <h3>Chart Area: Scroll Buttons</h3>*/}
                        {/*    <Grid item xs style={{textAlign: 'center'}}>*/}
                        {/*        <img src={crr2}  height={"25%"} width={"100%"} resizeMode={'center'}></img>*/}
                        {/*    </Grid>*/}
                        {/*    <ul> Like Downtime Events, the scrolling buttons allow you to quickly view all reject reasons. From left to right,*/}
                        {/*        <li>The double up arrow brings you to the top of the Downtime Events chart. </li>*/}
                        {/*        <li>The single up arrow indexes the list and shows the next highest in the list if it exists.</li>*/}
                        {/*        <li>The single down arrow indexes the list and shows the next lowest in the list if it exists.</li>*/}
                        {/*        <li>The double down arrow brings you to the bottom of the Downtime Events chart.</li>*/}
                        {/*    </ul>*/}
                        {/*    <p></p> /!*paragraph break*!/*/}
                        {/*</ol>*/}
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {CellDetails}