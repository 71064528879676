import {useAuth0, User} from "@auth0/auth0-react";
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {throwError} from "rxjs";
import useInterval from "../utils/useInterval";
import {useAppInsightsContext, useTrackEvent} from "@microsoft/applicationinsights-react-js";
import {FeedbackForm} from "../components/FeedbackForm";


const millisecondsInDay = 86400000
const msInWeek = millisecondsInDay * 7
const nextFeedbackTime = msInWeek * 12
const newFeedbackTime = nextFeedbackTime + millisecondsInDay
const noFeedbackTime = nextFeedbackTime - millisecondsInDay
const nameSpace = "https://optimizer.eastus.cloudapp.azure.com"

const TokenContext = createContext({
    token: "",
    user: new User(),
});

/**
 * Using Auth0Context, retrieves token to provide children with token, and user/access/permission information
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TokenProvider = (props) => {
    /*** Providers ***/
    const {
        loginError,
        setLoginError
    } = props.loginError
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithPopup,
        error: authErr,
    } = useAuth0();



    /*** States ***/
    const [token, setToken] = useState("");
    const [loginWaiter, heyWaiter] = useState(false)
    const [feedbackStatus, setFeedbackStatus] = useState(localStorage?.FeedbackStatus
        ? new Date(localStorage.FeedbackStatus)
        : new Date(Date.now() - newFeedbackTime)
    )
    const [feedbackLoad, setFeedbackLoad] = useState(0)
    const [feedbackOpen, setFeedbackOpen] = useState(feedbackStatus.getTime() < Date.now() - nextFeedbackTime)
    const [userAccess, setUserAccess] = useState([])
    const [allowedCustomers, setAllowedCustomers] = useState([])
    const [userRoles, setUserRoles] = useState([])
    // 604800 is 7 days in seconds.

    /*** App Insights Context/ Trackers ***/
    const appInsights = useAppInsightsContext();
    const skipFirstRun = true
    const trackUserAuthenticated = useTrackEvent(appInsights, "Auth0User Authenticated", user, skipFirstRun)

    /*** Access ***/
    useEffect(() => {
        trackUserAuthenticated({Auth0user:user})

        if (user && user[`${nameSpace}/products`]) {
            setUserAccess(user[`${nameSpace}/products`].map(x=>x.toUpperCase()) || [])
        }
        if (user && user[`${nameSpace}/customers`]) {
            setAllowedCustomers(user[`${nameSpace}/customers`].map(x=>x.toUpperCase()) || [])
        }
        if (user && user[`${nameSpace}/roles`]) {
            setUserRoles(user[`${nameSpace}/roles`])
        }
    }, [user])

    const permissions = useMemo(() => {
        let perm = userAccess?.map(x => x.toUpperCase())
        perm.sort((a, b) => {
            if (a === "SYSTEMDEMO") {
                return -1
            }
            if (a === "DEMO" && b !== "SYSTEMDEMO") {
                return -1
            }
            return a - b
        })
        return perm
    }, [userAccess])

    const feedbackLink = useMemo(() => {
        return `https://docs.google.com/forms/d/e/1FAIpQLSdowW2h4dsT-fooHYMIH51BmF8a_v9lCk5lN4NjCEJE05sclA/viewform?embedded=true&entry.1864990526=${user?.name}&entry.419073020=${user?.email}`
    }, [user])

    /*** Callbacks ***/
    const setGen = useCallback(_ => {
    }, [])

    const handleFeedback = useCallback(_ => {
        setFeedbackOpen(false)
        if (feedbackLoad > 1)
            setFeedbackStatus(new Date())
        else setFeedbackStatus(new Date(Date.now() - noFeedbackTime))
    }, [setFeedbackStatus, setFeedbackOpen, feedbackLoad])

    const handleFormLoad = useCallback(() => {
        setFeedbackLoad(x => x + 1)
    }, [feedbackLoad, setFeedbackLoad])

    /*** Intervals ***/
    useInterval(() => {
        const getToken = async () => {
            try {
                return await getAccessTokenSilently()
                    .then(token => {
                        setLoginError(false)
                        return token
                    }).catch(_ => {
                        setLoginError(true)
                    });
            } catch (e) {
                console.error(e);
                setLoginError(true);
            }
        }
        if (isAuthenticated && Object.keys(userAccess).length === 1) {
            setGen(Object.keys(userAccess)[0].replace("Access", "")
                .toUpperCase())
        }
        if (isAuthenticated && user) {
            getToken()
                .then(x => setToken(x))
        }
    }, token ? 30000 : 2000);

    /*** Effects ***/
    useEffect(() => {
        localStorage.FeedbackStatus = feedbackStatus?.toISOString()
    }, [feedbackStatus])
    useEffect(() => {
        if (!isAuthenticated && !user && loginWaiter) {
            loginWithPopup()
                .catch(throwError)
        }
    }, [loginWaiter])

    useEffect(() => {
        setTimeout(() => heyWaiter(true), 1000)
    }, [])

    useEffect(() => {
        if (authErr?.message && authErr?.message !== "Popup closed") {
            setLoginError(authErr)
        }
    }, [authErr])

    const value = {
        token,
        setToken,
        user,
        userAccess,
        userRoles,
        allowedCustomers,
        permissions,
        loginError,
    }
    return (
        <TokenContext.Provider value={value}>
            {props.children}
            <FeedbackForm dontOpenForm={true} user={user} feedbackOpen={feedbackOpen} src={feedbackLink} onLoad={handleFormLoad}
                          onClick={handleFeedback}/>
        </TokenContext.Provider>
    );
}

/**
 * Consumer for TokenContext defined in this file. See return for context details
 * @returns {{user: User, token: string}}
 */
function useToken() {
    return useContext(TokenContext);
}

export {TokenProvider, useToken};