import {Grid, useTheme, useMediaQuery} from '@mui/material'

/**
 * AutomationNTH Logo
 * @returns {JSX.Element}
 * @constructor
 */
export const PinnedLogo = () => {

	const theme = useTheme();
	const logoOverlap = useMediaQuery(theme.breakpoints.down("xl")); //Xl is >100% Zoom
	const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

	return (
			<Grid container item id={"NTHLogo"} sm={12} md={3} lg={2} direction="column">
				<img alt="NTH logo"
					 id="NTHfixedLogo"
					 src={theme.palette.mode === "light"
						 ? "/nthDark.png"
						 : "/nthLite.png"}
					 width= "100px"
				/>
			</Grid>
	);
}
