import {IconButton, Modal, Paper} from "@mui/material";
import {FAIcon} from "./fa-icon";
import {useMemo} from "react";
import * as PropTypes from "prop-types";

FeedbackForm.propTypes = {
    user: PropTypes.any,
    feedbackOpen: PropTypes.bool,
    src: PropTypes.string,
    onLoad: PropTypes.func,
    onClick: PropTypes.func,
    dontOpenForm: PropTypes.bool,
};

export function FeedbackForm(props) {
    const openForm = useMemo(() => {
        if (props.dontOpenForm) {
            return false
        }
        return !!props.user?.email && (!props.user?.email?.includes("fakeuser") && !props.user?.email?.includes("oeeo")) ? props.feedbackOpen : false
    }, [props])
    return (
        <Modal
            open={openForm}
        >
            <Paper style={{
                position: "relative",
                width: "800px",
                height: "100%",
                maxWidth: "95vw",
                left: "Max(Calc(50vw - 400px), 2.5vw)"
            }}
            >
                <iframe title="FeedbackSurvey"
                        src={props.src}
                        height="2160"
                        style={{
                            maxHeight: "99vh",
                            width: "100%"
                        }}
                        onLoad={props.onLoad}
                >Loading…
                </iframe>
                <IconButton onClick={props.onClick}
                            sx={{
                                position: "fixed",
                                top: 0,
                                right: 0,
                                backgroundColor: "#fff9"
                            }}
                            size="small"
                            data-test-id="feedbackCloseIcon"
                >
                    <FAIcon icon={"\uf00d".toString()} si/>
                </IconButton>
            </Paper>
        </Modal>
    );
}