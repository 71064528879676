import {useAuth0}                                        from "@auth0/auth0-react";
import {Button, Grid, IconButton, Paper, Popper, styled} from "@mui/material";
import {DataGrid}                                        from "@mui/x-data-grid";
import {useCallback, useEffect, useMemo, useState}       from "react";
import {FAIcon}                                          from "../components/fa-icon";
import NthDrawer                                         from "../components/NthDrawer";
import {TokenProvider, useToken}                         from "../Contexts/TokenProvider";


const CenteredPaper = styled(Paper)({
                                        textAlign: "center"
                                    })

/**
 * Button inside Grid element of size 2 with text centered
 * @param props props passed onto Button Element
 * @param {{props}} [props.gridProps] props to pass onto Grid object
 * @param {JSX.Element} props.children Children elements defined by parent element
 * @returns {JSX.Element}
 * @constructor
 */
const GridButton = props => <Grid item xs={2} textAlign="center" {...props.gridProps}>
    <Button color="secondary"
            style={{
                backgroundColor: "#444",
            }}
            {...props}
    >{props.children}</Button>
</Grid>

/**
 * Requests to the machine access API.
 *
 * Can either fetch all machines and their access status, or grant/remove access from the selected machines.
 * @param {MouseEvent} [e] Mouse click event to set error popper anchor element
 * @param {string} [method] "fetch" | "grant" | "remove"
 * @param {string} token API Access Token
 * @param {[Object]} [selectedRows] Rows selected for access modification
 * @param {Function} setAnchor sets the popper anchor element
 * @param {Function} setErrorPopup sets the popper state
 * @param {Function} setErrorMessage sets the popper message
 * @param {Function} setLoading sets the table loading state
 * @param {Function} setAccessData sets the machine access data
 */
const accessAPI = (setAccessData,
                   setAnchor,
                   setErrorPopup,
                   setErrorMessage,
                   setLoading,
                   token,
                   selectedRows,
                   e      = undefined,
                   method = "fetch") => {
    if (method !== "fetch") {
        setAnchor(e?.currentTarget)
        setErrorPopup(false)
    }
    setLoading(true)
    fetch('/snow/oeeoAccess', {
        method : 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization : `Bearer ${token}`
        },
        body   : JSON.stringify({
                                    method,
                                    rows: method === "fetch" ? "" : selectedRows
                                })
    })
        .then(async res => {
            let jsonRes = await res.json()
            setLoading(false)
            if (jsonRes?.errors) {
                setErrorPopup(true)
                setErrorMessage(jsonRes.errors)
                return
            }
            if (method !== "fetch") {
                return accessAPI(setAccessData,
                                 setAnchor,
                                 setErrorPopup,
                                 setErrorMessage,
                                 setLoading,
                                 token)
            }
            setAccessData(jsonRes)
        })
        .catch(e => {
            console.log(e)
        })
}

/**
 * Access Admin Page with machine table and grant/remove buttons.
 *
 * Must be authenticated to render
 * @returns {JSX.Element}
 * @constructor
 */
const AuthenticatedAccessAdmin = () => {
    /** Auth variables **/
    const [accessData, setAccessData] = useState()
    const {token}                     = useToken()
    /** **/

    /** Table variables **/
    const [snowRows, setRows]     = useState([])
    const [rowCount, setRowCount] = useState(10)
    const snowCols                = [
        {
            field     : "id",
            headerName: "ID",
            width     : 60
        },
        {
            field     : "MACHINE",
            headerName: "Machine",
            width     : 130
        },
        {
            field     : "LOCATION",
            headerName: "Location",
            width     : 130
        },
        {
            field     : "PRODUCT",
            headerName: "Product",
            width     : 70
        },
        {
            field     : "COMPANY",
            headerName: "Company",
            width     : 130
        },
        {
            field     : "ACCESS",
            headerName: "Access",
            width     : 60
        },
    ]
    /** **/

    /** Selection Variables **/
    const [selectedRows, setSelectedRows] = useState([])
    const setSelections                   = useCallback(ids => {
        setSelectedRows(ids.map(id => snowRows[id]))
    }, [snowRows])
    /** **/

    /** Layout Variables **/
    const [anchorEl, setAnchor]           = useState()
    const [errorPopup, setErrorPopup]     = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading]           = useState(true)
    /** **/


    useEffect(() => {
        if (!accessData?.snow) return
        let rows = accessData.snow.rows.map((row, i) => ({id: i, ...row}))
        setRows(rows)
    }, [accessData])

    useEffect(() => {
        if (!token) return
        accessAPI(setAccessData, setAnchor, setErrorPopup, setErrorMessage, setLoading, token)
    }, [token])

    let renderTables = <>
        <Grid item xs={6}>
            <DataGrid
                columns={snowCols}
                rows={snowRows}
                pageSize={rowCount}
                onPageSizeChange={setRowCount}
                rowsPerPageOptions={[10, 25, 50, 100]}
                checkboxSelection
                autoHeight
                onSelectionModelChange={setSelections}
                loading={loading}
            />
        </Grid>
        <Paper component={Grid} item container xs={4} direction="column" height="50vh"
               justifyContent="space-evenly"
        >
            <GridButton onClick={e => accessAPI(setAccessData,
                                                setAnchor,
                                                setErrorPopup,
                                                setErrorMessage,
                                                setLoading,
                                                token,
                                                selectedRows,
                                                e,
                                                "remove")}
            >
                Remove Access
            </GridButton>
            <GridButton onClick={e => accessAPI(setAccessData,
                                                setAnchor,
                                                setErrorPopup,
                                                setErrorMessage,
                                                setLoading,
                                                token,
                                                selectedRows, e, "grant")}
            >
                Grant Access
            </GridButton>
        </Paper>
        <Popper open={errorPopup}
                anchorEl={anchorEl}
        >
            <Paper style={{
                backgroundColor: "#800",
                padding        : 5
            }}
            >
                {errorMessage}
                <IconButton onClick={() => setErrorPopup(false)}>
                    <FAIcon icon="" style={{color: "#fff"}}/>
                </IconButton>
            </Paper>
        </Popper>
    </>

    return token ? <>
        <CenteredPaper>
            You can sort the table by clicking on a header.<br/>
            To filter the table, use the three dots on the header you wish to filter.<br/>
            Z_ items are used for Demo purposes.
        </CenteredPaper>
        <Grid container padding={5} justifyContent="space-evenly">
            {accessData?.snow ? renderTables : null}
        </Grid>
    </> : <></>
}

/**
 * Layout for Access Admin Page
 *
 * Provides Authentication Shell
 * @returns {JSX.Element}
 * @constructor
 */
export default function AccessAdmin() {
    const pullerWidth = 32

    const {
              isAuthenticated,
              loginWithPopup,
              user
          }                           = useAuth0();
    const [loginError, setLoginError] = useState(false)

    const permission = useMemo(() => {
        if (!user?.hasOwnProperty("https://optimizer.eastus.cloudapp.azure.com/roles")) return false
        return user["https://optimizer.eastus.cloudapp.azure.com/roles"]?.includes("NTH Admin")
    }, [user, loginError])

    const appAuthRender = !isAuthenticated
                          ? <CenteredPaper>
                              Please
                              <Button onClick={loginWithPopup}>
                                  Log in
                              </Button>
                              to continue
                          </CenteredPaper>
                          : loginError || !permission
                            ? <CenteredPaper>
                                You are not allowed to access this app
                            </CenteredPaper>
                            : <AuthenticatedAccessAdmin/>

    return (<TokenProvider loginError={{
        loginError,
        setLoginError
    }}
    >
        <NthDrawer/>
        <div id="applicationRooter" style={{
            position: 'absolute',
            left    : pullerWidth,
            width   : `Calc(100% - ${pullerWidth}px)`
        }}
        >
            {appAuthRender}
        </div>
    </TokenProvider>)
}