class Encoder
{
	constructor(schema,cats,_tags) {
		this.all = schema.map(d => d[0]);
		this.schema = schema;
		this.categories = cats;
		this.tags = _tags;
		this.possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
	}

	arrToBin = (a,arr) =>{
		let bits = '';
		if(!a || !arr)
			return bits;
		for(let i = 0; i < arr.length; i++)
			bits += (i>arr.length || !a.includes(arr[i]))?'0':'1'
		return bits;
	}

	bitsToStr = (bits) =>{
		let str = '';
		const byteLength = Math.ceil(bits.length/6.0); //64
		for(let i = 0; i < byteLength; i++) {
			let bit = bits.slice(i * 6, (i + 1) * 6);
			while(bit.length < 6)
				bit += '0'
			str += this.possible[parseInt(bit, 2)];
		}
		return str;
	}

	strToBits = (str,arr) => {
		let bits = '';
		for(let i = 0; i < str.length; i++) {
			bits += ('00000'+this.possible.indexOf(str[i]).toString(2)).slice(-6)
		}
		return bits;
	}
	bitsToArr = (bits,arr) =>{
		if(!bits || !arr || arr.length<1)
			return '';
		const byteLength = Math.ceil(arr.length/6.0);
		const bytes = [];
		for(let i = 0; i < byteLength; i++)
			bytes.push(bits.slice(i*6,(i+1)*6))
		const a =[];
		for(let i = 0; i < bits.length; i++) {
			const byte = Math.floor(i/6)
			const pos = i%6;
			if (bytes[byte][pos] === '1')
				a.push(arr[i])
		}
		return a;
	}

	encodeFiltered = (filtered) =>{
		let bits = '';
		if (!this.categories || !filtered || !this.tags)
			return bits;
		for(let cat of this.categories) {
			bits += this.arrToBin(filtered[cat], this.tags[cat])
		}
		return this.bitsToStr(bits);
	}
	encodeSelected = (selected) =>{
		const bits = this.arrToBin(selected,this.all)
		const tmp = this.bitsToStr(bits);
		const fs = this.strToBits(tmp);
		console.log(bits,tmp,fs,this.bitsToArr(fs,this.all),this.all)
		return this.bitsToStr(bits);
	}

	decodeSelected = (str) => {
		const bits = this.strToBits(str)
		return this.bitsToArr(bits,this.all);
	}
	decodeFiltered = (str) =>{
		const bits = this.strToBits(str)
		const filtered = {};
		let i = 0;
		for(let cat of this.categories) {
			if(!this.tags.hasOwnProperty(cat))
				return;
			filtered[cat] = this.bitsToArr(bits.slice(i,i+this.tags[cat].length-1),this.tags[cat])
			i+=this.tags[cat].length;
		}
		return filtered;
	}
}

class B64Encoder
{
	constructor() {
	}
	replaceAll(str){
		str = str.replaceAll('+','.')
		str = str.replaceAll('/','_')
		str = str.replaceAll('=','-')
		return str;
	}
	emplaceAll(str){
		str = str.replaceAll('.','+')
		str = str.replaceAll('_','/')
		str = str.replaceAll('-','=')
		return str;
	}
	encode(obj){
		return this.replaceAll(btoa(JSON.stringify(obj)));
	}
	decode(str){
		return JSON.parse(atob(this.emplaceAll(str)));
	}
}
export {Encoder,B64Encoder}