import {useAuth0} from "@auth0/auth0-react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme
} from "@mui/material";
import {metadataToRole, roles} from "./roles";
import {Widget} from "../layout/Widget";
import {saveAs} from "file-saver"
import jsonListToCSV from "../utils/jsonListToCSV";
import UserListTable from "./UserListTable";
import NthToolbar from "../components/NthToolbar";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UserAdmin = props => {
	const theme = useTheme()
	const {user, isAuthenticated, loginWithPopup, logout} = useAuth0();
	const [accessToken, setToken] = useState()
	const metadata = useMemo(() => {
		if (user) {
			let w = []
			let y = user[`${window.location.origin}/app_metadata`]
			for (let x of Object.keys(y)) {
				if (y[x])
					w.push(x)
			}
			return w
		} else return {}
	}, [user])
	const mobile = useMediaQuery(theme.breakpoints.down('md'))
	const extractDomain = useCallback(email => email?.slice(email.search('@.*\\..*')), [])
	const userCompany = useMemo(() => extractDomain(user?.email)?.slice(1, -4), [extractDomain, user?.email])

	const userAccess = useMemo(() => {

		if (user && user[`${window.location.origin}/app_metadata`])
			return user[`${window.location.origin}/app_metadata`] || {}
		return {}
	}, [user])

	const permissions = useMemo(() => {
		let perm = []
		for (const key in userAccess) {
			if (userAccess.hasOwnProperty(key) && userAccess[key])
				if (key.split('Admin').length > 1 || key === 'demoAccess')
					perm.push(key.split('Admin')[0].split('Access')[0].toUpperCase())
		}
		return perm
	}, [userAccess])

	const canEditUsers = useMemo(() => {
		return !!permissions?.length
	}, [permissions])

	const nthAdmin = useMemo(() => {
		return user?.email?.indexOf('automationnth.com') !== -1
	}, [user])

	const [product, setProduct] = useState()
	const [credType, setCred] = useState(3)
	const [responseResolved, setResolved] = useState('Loading')
	const [confirmDelete, setConfirm] = useState(false)
	const [permType, setPermType] = useState('Add')
	const [searchID, setSearchID] = useState(['', ''])
	const [formEmail, setFormEmail] = useState('')
	const [formName, setFormName] = useState('')
	const [formPass, setFormPass] = useState('')
	const [errMsg, setErrMsg] = useState('')
	const [userList, setUserList] = useState([])
	const [IDList, setIDList] = useState([])
	const [selected, setSelected] = useState([])
	const filteredUserList = useMemo(() => {
		if ([0, 3].includes(credType))
			return userList.filter(x => x?.name.toLowerCase().startsWith(formName.toLowerCase()))
		else
			return userList.filter(x => x?.email.toLowerCase().startsWith(formEmail.toLowerCase()))
	}, [userList, formName, formEmail])
	const filteredIDs = useMemo(() => selected.map(x => {
		let m = IDList.find(y => x === y.email)
		if (m)
			return m.user_id
	}), [IDList, selected])

	useEffect(() => {
		setConfirm(false)
	}, [selected.length])

	useEffect(() => {
		if (!responseResolved)
			setErrMsg('')
	}, [responseResolved])

	useEffect(() => {
		setResolved('')
	}, [formName, formEmail, formPass])

	const userObject = useMemo(() => ({
		"email": formEmail,
		"name": formName,
		"connection": "Username-Password-Authentication",
		"password": formPass
	}), [formEmail, formName, formPass])

	const inDomain = useMemo(() => nthAdmin || (extractDomain(user?.email) === extractDomain(formEmail)), [extractDomain, formEmail, nthAdmin, user?.email])

	const findUser = async (reqType) => new Promise((resolve, reject) => {
		fetch(`https://optimizer.us.auth0.com/api/v2/users-by-email?email=${formEmail}`, {
			method: 'GET',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		})
			.then(async res => {
				return await res.json()
			})
			.then(async res => {
				if (res.length !== 1)
					return reject()
				await setSearchID([res[0].user_id, reqType])
			})
			.then(resolve)
	},)

	const listUsers = async (reqType) => new Promise(async (resolve, reject) => {
		setResolved("Loading")
		let domain = extractDomain(user?.email)
		if (userCompany === 'automationnth')
			domain = '*'
		let userNo = 0
		let pageNo = 0
		let totalUsers = 0
		let listOfUsers = []
		let listOfIDs = []
		let getUserList = async (page) => {
			await fetch(`https://optimizer.us.auth0.com/api/v2/users?page=${page}&per_page=100&include_totals=true&q=email%3A*${domain}`, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			})
				.then(async res => {
					return await res.json()
				})
				.then(async res => {
					if (res.length !== 1)
						reject()
					if (pageNo) {
						listOfUsers.push(...res.users.map(({email, name, app_metadata}) => ({
							email,
							name,
							app_metadata
						})))
						listOfIDs.push(...res.users.map(({email, user_id}) => ({email, user_id})))
					} else {
						listOfUsers = res.users.map(({email, name, app_metadata}) => ({email, name, app_metadata}))
						listOfIDs = res.users.map(({email, user_id}) => ({email, user_id}))
					}
					totalUsers = res.total
					userNo += 100
					pageNo++
				}).catch(e => console.log(e))
		}
		do {
			try {
				await getUserList(pageNo)
			} catch (e) {
				console.log(e)
				setResolved("Error. Try again.")
				break
			}
		} while (userNo < totalUsers)
		setSelected([])
		setUserList(listOfUsers)
		setIDList(listOfIDs)
		setResolved("")
	},)

	const deleteUser = async (id = undefined) => new Promise((res, rej) => {
		let search = id || searchID[0]
		fetch(`https://optimizer.us.auth0.com/api/v2/users/${search}`, {
			method: 'DELETE',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		})
			.then((response) => {
				if (!id)
					setResolved('User Deleted')
			})
			.then(res)
			.catch(rej)
	})

	const massRemove = async () => new Promise((res, rej) => {
		setResolved('Loading')
		Promise.all(filteredIDs.map(x => deleteUser(x)))
			.then(() => setFormName(""))
			.then(listUsers)
			.then(() => setResolved('All Users Removed'))
			.then(res)
			.catch(e => console.log(e))
	})

	const addRole = async () => new Promise((res, rej) => {
		fetch(`https://optimizer.us.auth0.com/api/v2/users/${searchID[0]}/roles`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				"roles": [
					...roles[product].roles
				]
			})
		})
			.then(() => {
				setResolved('Permissions Added')
			})
			.then(res)
			.catch(rej)
	})

	const addMetadata = async () => new Promise((res, rej) => {
		fetch(`https://optimizer.us.auth0.com/api/v2/users/${searchID[0]}`, {
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				"app_metadata": {
					...roles[product].metadata
				}
			})
		})
			.then(res)
			.catch(e => setTimeout(() => rej(e), 500))
	})

	const massAddPermissions = async (id) => new Promise((res, rej) => {
		fetch(`https://optimizer.us.auth0.com/api/v2/users/${id}`, {
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				"app_metadata": {
					...roles[product].metadata
				}
			})
		})
			.then(() => fetch(`https://optimizer.us.auth0.com/api/v2/users/${id}/roles`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					"roles": [
						...roles[product].roles
					]
				})
			}))
			.then(res)
	})

	const massRemovePermissions = async (id) => new Promise((res, rej) => {
		let accessRemove = {}
		for (const [key] of Object.entries(roles[product].metadata)) {
			accessRemove[key] = false
		}
		fetch(`https://optimizer.us.auth0.com/api/v2/users/${id}`, {
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				"app_metadata": {
					...accessRemove
				}
			})
		})
			.then(() => fetch(`https://optimizer.us.auth0.com/api/v2/users/${id}/roles`, {
				method: 'DELETE',
				headers: {
					'content-type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					"roles": [
						...roles[product].roles
					]
				})
			}))
			.then(res)
	})

	const massPermissions = async (type) => {
		setResolved('Loading')
		let massFunc
		switch (type) {
			case "Add":
				massFunc = massAddPermissions
				break
			case "Remove":
				massFunc = massRemovePermissions
				break
			default:
				massFunc = x => console.log(x)
		}
		Promise.all(filteredIDs.map(x => massFunc(x)))
			.then(listUsers)
			.then(() => setResolved('All Permissions ' + type + "ed"))
	}

	const removeRole = async () => new Promise((res, rej) => {
		fetch(`https://optimizer.us.auth0.com/api/v2/users/${searchID[0]}/roles`, {
			method: 'DELETE',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				"roles": [
					...roles[product].roles
				]
			})
		})
			.then(() => {
				setResolved('Permissions Removed')
			})
			.then(res)
	})

	const removeMetadata = async () => new Promise((res, rej) => {
		let accessRemove = {}
		for (const [key] of Object.entries(roles[product].metadata)) {
			accessRemove[key] = false
		}
		fetch(`https://optimizer.us.auth0.com/api/v2/users/${searchID[0]}`, {
			method: 'PATCH',
			headers: {
				'content-type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				"app_metadata": {
					...accessRemove
				}
			})
		})
			.then(res)
	})


	const sendNewUserRequest = async (user) => {
		setResolved('')
		return new Promise((resolve, reject) => {
			fetch('https://optimizer.us.auth0.com/api/v2/users', {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(userObject)
			})
				.then(async res => {
					const jsonRes = await res.json()
					if (jsonRes.error)
						throw jsonRes
					setResolved('User Created')
					setSearchID(["auth0%7C" + jsonRes.identities[0].user_id, "addPermissions"])
				})
				.then(resolve)
				.catch(e => {
					setResolved(e.error)
					setErrMsg(e.message)
				})
		})
	}

	const findAndDeleteUser = async () => {
		setResolved('')
		await findUser("deleteUser")
			.catch(() => {
				setResolved('User not found')
				setSearchID(['', ''])
			})
	}

	const findAndAddPermissions = async () => {
		setResolved('')
		await findUser("addPermissions")
			.catch((e) => {
				console.log(e)
				setResolved('User not found')
				setSearchID(['', ''])
			})
	}

	const findAndRemovePermissions = async () => {
		setResolved('')
		await findUser("removePermissions")
			.catch(() => {
				setResolved('User not found')
				setSearchID(['', ''])
			})
	}

	useEffect(() => {
		switch (searchID[1]) {
			case "addPermissions":
				addMetadata()
					.then(addRole)
					.then(listUsers)
					.catch((e) => {
						setResolved('User not found')
						setSearchID(['', ''])
					})
				break
			case "removePermissions":
				removeMetadata()
					.then(listUsers)
					.catch(() => {
						setResolved('User not found')
						setSearchID(['', ''])
					})
				break
			case "deleteUser":
				deleteUser()
					.then(listUsers)
					.catch(() => {
						setResolved('User not found')
						setSearchID(['', ''])
					})
				break
			default:
		}
	}, [searchID])

	const ProductSelect = <FormControl style={{minWidth: '200px'}}>
		<InputLabel>Permission Group</InputLabel>
		<Select
			value={product}
			onChange={x => setProduct(x.target.value)}
		>
			{permissions.map(x => <MenuItem value={x}>{x}</MenuItem>)}
		</Select>
	</FormControl>

	const newUserButton = (
		<Button variant={!inDomain || !formEmail || !formName || !formPass ? "outlined" : "contained"}
		        color={!inDomain || !formEmail || !formName || !formPass ? "primary" : "secondary"}
		        id="newUserSubmitButton"
		        style={{
			        marginTop: '20px',
			        pointerEvents: !inDomain || !formEmail || !formName || !formPass ? 'none' : ''
		        }}
		        onClick={!inDomain || !formEmail || !formName || !formPass ? '' : sendNewUserRequest}>
			{
				!formEmail || inDomain ?
					!responseResolved ?
						!formName || !formPass ?
							'Form Incomplete' : 'Submit' :
						responseResolved /*'User Created'*/ :
					'User not in your company'
			}
		</Button>)

	const newUserForm = <Grid item>
		<FormControl>
			{ProductSelect}
			<TextField id="newUserNameInput" label="name" variant="filled" value={formName}
			           onChange={x => setFormName(x.target.value)}/>
			<TextField id="newUserEmailInput" label="email" variant="filled" value={formEmail}
			           onChange={x => setFormEmail(x.target.value)}/>
			<TextField id="newUserPassInput" label="password" variant="filled" type="password" value={formPass}
			           onChange={x => setFormPass(x.target.value)}/>
			<Tooltip title={errMsg} open={!!errMsg}>
				{newUserButton}
			</Tooltip>
		</FormControl>
	</Grid>

	const listUsersForm = <Grid item>
		<FormControl>
			{!mobile ? <TextField id="filterNameInput" label="name" variant="filled" value={formName}
			                      onChange={x => setFormName(x.target.value)}/> : null}
			<Button onClick={listUsers}>
				{responseResolved || "Refresh List"}
			</Button>
			<Button variant={responseResolved !== "List Ready" ? "outlined" : "contained"}
			        color={responseResolved !== "List Ready" ? "primary" : "secondary"}
			        style={{
				        marginTop: '20px',
				        opacity: !userList.length || (responseResolved === "Loading") ? '0' : '',
				        pointerEvents: !userList.length || (responseResolved === "Loading") ? 'none' : ''
			        }}
			        onClick={
				        () => {
					        let heads = ['name', 'email']
					        let filterList = userList.filter(({app_metadata}) => !!app_metadata)
					        filterList.map(({app_metadata}) => Object.keys(app_metadata).map(x => heads.includes(x) ? '' : heads.push(x)))
					        const list = userList.map(x => Object?.fromEntries(...[heads.map(y => {
						        if (!x?.app_metadata) {
							        if (!x[y])
								        return [y, "False"]
							        return [y, x[y]?.toString()]
						        }
						        return [y, x?.app_metadata[y]?.toString() || x[y]?.toString() || "False"]
					        })]))
					        saveAs(new Blob([jsonListToCSV(list)],
							        {type: "text/plain;charset=utf-8"}),
						        "userList.csv")
				        }
			        }>
				Download List
			</Button>
			{mobile ? <TextField id="filterNameInput" label="name" variant="filled" value={formName}
			                     onChange={x => setFormName(x.target.value)}/> : null}
		</FormControl>
	</Grid>

	const deleteUserForm = <Grid item>
		<FormControl>
			<TextField id="deleteEmailInput" label="email" variant="filled" value={formEmail}
			           onChange={x => setFormEmail(x.target.value)}/>
			<Tooltip title={errMsg} open={!!errMsg}>
				{confirmDelete ?
					<Button variant={!filteredUserList.length && !formEmail ? "outlined" : "contained"}
					        color="error"
					        style={{marginTop: '20px'}}
					        onClick={() => {
						        findAndDeleteUser()
							        .then(() => {
								        setConfirm(false)
							        })
					        }}>
						Are you sure?
					</Button> :
					<Button variant={!filteredUserList.length || !formEmail ? "outlined" : "contained"}
					        color={!filteredUserList.length || !formEmail ? "primary" : "secondary"}
					        style={{
						        marginTop: '20px',
						        pointerEvents: !filteredUserList.length || !formEmail ? 'none' : ''
					        }}
					        onClick={() => !filteredUserList.length || !formEmail ? '' : setConfirm(true)}>
						{
							!!formEmail ?
								!!filteredUserList.length ?
									!responseResolved ?
										'Submit' : responseResolved /*'User Deleted'*/ :
									'No users found' :
								"Incomplete Form"
						}
					</Button>
				}
			</Tooltip>
		</FormControl>
	</Grid>

	const PermissionsForm = <Grid item>
		<FormControl>
			{ProductSelect}
			<RadioGroup value={permType} onChange={e => setPermType(e.target.value)} row>
				<FormControlLabel value={"Add"}
				                  control={<Radio color="nthTheme" size="small"/>}
				                  labelPlacement="top"
				                  label="Add"/>
				<FormControlLabel value={"Remove"}
				                  control={<Radio color="nthTheme" size="small"/>}
				                  labelPlacement="top"
				                  label="Remove"/>
			</RadioGroup>
			<TextField id="permissionsEmailInput" label="email" variant="filled" value={formEmail}
			           onChange={x => setFormEmail(x.target.value)}/>
			<Tooltip title={errMsg} open={!!errMsg}>
				<Button variant={!filteredUserList.length || !formEmail ? "outlined" : "contained"}
				        color={!filteredUserList.length || !formEmail ? "primary" : "secondary"}
				        style={{
					        marginTop: '20px',
					        pointerEvents: !filteredUserList.length || !formEmail ? 'none' : ''
				        }}
				        onClick={!inDomain || !formEmail ? '' : permType === "Add" ? findAndAddPermissions : findAndRemovePermissions}>
					{
						!!formEmail ?
							filteredUserList.length ?
								!responseResolved ?
									'Submit' : responseResolved /*'Permissions Removed or Added'*/ :
								'User does not exist' :
							"Incomplete Form"
					}
				</Button>
			</Tooltip>
		</FormControl>
	</Grid>

	const Forms = [newUserForm, PermissionsForm, deleteUserForm, listUsersForm]

	const renderAdminForm = <>
		<Grid item container marginTop={mobile ? "20px" : "0"} justifyContent='center' alignContent='center'>
			<FormControl style={{minWidth: '200px', display: mobile ? '' : 'none'}}>
				<Select
					value={credType}
					onChange={x => {
						setResolved('')
						setConfirm(false)
						setCred(x.target.value)
					}}
				>
					<MenuItem value={0}>New User</MenuItem>
					<MenuItem value={1}>Edit Permissions</MenuItem>
					<MenuItem value={2}>Remove User</MenuItem>
					<MenuItem value={3}>Filter Users</MenuItem>
				</Select>
			</FormControl>
			<Tabs value={credType}
			      style={{display: mobile ? 'none' : ''}}
			      onChange={(x, i) => {
				      setResolved('')
				      setConfirm(false)
				      setCred(i)
			      }}>
				<Tab style={{display: "none"}} label="New User">New User</Tab>
				<Tab style={{display: "none"}} label="Edit Permissions"/>
				<Tab style={{display: "none"}} label="Remove User"/>
				<Tab label="Filter Users"/>
			</Tabs>
		</Grid>
		<Grid item container marginTop="20px" justifyContent='center' alignContent='center'>
			{Forms[credType]}
		</Grid>
	</>

	useEffect(() => {
		setProduct(permissions[0])
	}, [permissions])


	useEffect(() => {
		const getToken = async () => await fetch(`https://optimizer.us.auth0.com/oauth/token`, {
			method: 'POST',
			headers: {'content-type': 'application/json'},
			body: '{"client_id":"JnwYSnNlFtTsMgvl7nQp3eLJbM8su4AM","client_secret":"_5VcD-yVM2D4dRP0zbn5uw8N-jA40mIeacGQgVZsQgDbF9eYKS-WZJ-YjGh9_T8D","audience":"https://optimizer.us.auth0.com/api/v2/","grant_type":"client_credentials"}'
		})
		if (isAuthenticated) {
			getToken()
				.then(async res => (await res.json()))
				.then(res => setToken(res.access_token))
		}
	}, [isAuthenticated])

	useEffect(async () => {
		if (accessToken)
			try {
				await listUsers()
			} catch (e) {
				console.log('failed to fetch users from auth0: ', e)
			}
	}, [accessToken])

	return <>
		<NthToolbar name={"User Admin Page"}/>
		{!isAuthenticated || mobile ?
			<Grid
				container
				component={Widget}
				direction="column"
				justifyContent="center"
				alignContent="center"
				paperProps={{padding: "0 20px 20px"}}
				paperStyle={!mobile ? {width: "50%", minWidth: "600px"} : ''}
			>
				<Grid item container justifyContent='center' alignContent='center'>
					<Typography variant='h5' color='secondary'>
						{userCompany ? userCompany[0]?.toUpperCase() + userCompany?.slice(1) : ''}
					</Typography>
				</Grid>
				{isAuthenticated ? canEditUsers ? renderAdminForm :
						<Grid item container justifyContent='center' alignContent='center'>
							<Button variant="outlined" onClick={() => logout({returnTo: window.location.origin})}>
								You do not have access to this page. Click to log out
							</Button>
						</Grid> :
					<Grid item container justifyContent='center' alignContent='center'>
						<Button variant="outlined" onClick={loginWithPopup}>
							Click to log in
						</Button>
					</Grid>
				}
			</Grid> : null}

		{isAuthenticated ? canEditUsers ? <>
				<Grid container
				      component={Widget}
				      direction="column"
				      paperProps={{padding: "0 20px 20px"}}
				      paperStyle={!mobile ? {minWidth: "600px"} : ''}
				      gridProps={{display: !userList.length && (responseResolved === 'Loading') ? 'none' : ''}}
				>
					<Grid item xs={12} style={{
						zIndex: 999,
						position: 'absolute',
						display: responseResolved === 'Loading' ? "" : "none",
						width: "100%"
					}}>
						<LinearProgress color="nthTheme"/>
					</Grid>
					<Grid item xs={12}>
						<UserListTable
							listTitle={mobile ? "Users" : <Typography variant='h5' color='secondary'>
								{userCompany ? userCompany[0]?.toUpperCase() + userCompany?.slice(1) : ''}
							</Typography>}
							userList={userList}
							filteredList={filteredUserList}
							headers={metadata}
							checkFunction={(row = {}, x = '', e) => {
								e.stopPropagation()
								const userID = IDList.find(x => x.email === row.email).user_id
								setProduct(metadataToRole[x])
								setSearchID([userID, row[x] ? "removePermissions" : "addPermissions"])
							}}
							deleteFunction={massRemove}
							newNameFunc={x => setFormName(x.target.value)}
							newEmailFunc={x => setFormEmail(x.target.value)}
							newPassFunc={x => setFormPass(x.target.value)}
							{...{selected, setSelected, ProductSelect, newUserButton}}
						/>
					</Grid>
					<Grid item xs={12} style={{
						opacity: responseResolved === 'Loading' ? "" : 0,
						width: "100%"
					}}>
						<LinearProgress color="nthTheme"/>
					</Grid>
				</Grid>
				<Grid
					container
					component={Widget}
					direction="column"
					justifyContent="center"
					alignContent="center"
					paperProps={{padding: "0 20px 20px"}}
					paperStyle={!mobile ? {width: "50%", minWidth: "600px"} : ''}
					gridProps={{display: (!userList.length && (responseResolved === 'Loading')) || mobile ? 'none' : ''}}
				>
					{renderAdminForm}
				</Grid>
			</> :
			<Grid item container justifyContent='center' alignContent='center'>
				<Button variant="outlined" onClick={() => logout({returnTo: window.location.origin})}>
					You do not have access to this page. Click to log out.
				</Button>
			</Grid> : null
		}
	</>
}

export default UserAdmin