import React from "react";
import {useHistory} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";

/**
 * Auth0 Provider aware of browser's history and previous authentication
 * @param {Object} props
 * @param props.children
 * @returns {JSX.Element}
 * @constructor
 */
const Auth0ProviderWithHistory = ({children}) => {
    const host = window.location.host;
    const domain = host.includes("dev")
    || host.includes("localhost")
    || host.includes("qa")
        ? "optimizer-stage.us.auth0.com" : "optimizer.us.auth0.com"
    const clientId = host.includes("dev")
    || host.includes("localhost")
    || host.includes("qa")
        ? "jSxXcV4rfKr6o7TUxX05czdbEhuRYIM1" : "JeqDmTKkO9C67WzHibSrk6apHdboBfJA"
    console.log("process.env.DEPLOYMENT_STAGE", process.env)

    const history = useHistory();

    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
