import crr1 from "../images/RRFirstActive.png";
import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';

const CellRejectReasons = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Cell View - Reject Reasons" height={15} width={10} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <p>The Reject Reasons Chart sits to the right of the Downtime Events chart.</p>
                            <p>Left-clicking on a Reject Reason bar will highlight the bar within the chart area.
                                Just like with the Downtime Events chart, you can scroll through the Reject Reasons chart
                                using either the mouse wheel or the scroll bar on the right side of the chart.
                            </p>
                            <br/>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={crr1}  height={"25%"} width={"50%"} resizeMode={'center'}
                                     alt="Image of Optimizer"
                                ></img>
                            </Grid>
                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {CellRejectReasons}