import sde1 from "../images/SystemDowntimeDuration.png";
import sde2 from "../images/SystemDowntimeCount.png"
import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';


const SystemDowntimeEvents = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="System View - Downtime Events" height={15} width={15} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <p>System View provides a Downtime Events chart that displays all the reasons for downtime for the time period
                            and selections.</p>

                                <br/>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img alt="Image of Optimizer" src={sde1}  height={"25%"} resizeMode={'center'}></img>
                                </Grid>
                                <br/>
                                <p>Similar to the Downtime Events chart for Cell View, the Downtime Events chart here can be sorted
                                by either overall duration of the event, or the count of how many times the event occurred.</p>
                                <br/>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img alt="Image of Optimizer" src={sde2}  height={"25%"} resizeMode={'center'}></img>
                                </Grid>
                                <br/>
                                <p>This Downtime Events chart can also be scrolled with a mouse wheel, or using the scroll bar.
                                </p>

                        </Typography>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {SystemDowntimeEvents}