import {PowerBIEmbed} from "powerbi-client-react";
import React, {useEffect, useMemo, useReducer} from "react";
import {useJokeContext} from "../Contexts/JokeProvider";
import {createMirrorFilter} from "../utils/PBI Utils/createMirrorFilter";
import timeRangeISOStringGeneratorSwitch from "../utils/timeRangeISOStringGeneratorSwitch";
import {reactPlugin} from "../AppInsights";
import {useAppInsightsContext, useTrackEvent, withAITracking} from "@microsoft/applicationinsights-react-js";
import LoadingCircle from "./LoadingCircle";
import {createEmbedConfig} from "../utils/PBI Utils/CreateEmbedConfig";
import {useAuth0} from "@auth0/auth0-react";

/**
 * @returns {React.ComponentType}
 * @constructor
 */
const EmbeddedReport = (props) => {
    const {
        loading,
        reportToken,
        refresh,
        currentReport,
        currentDataset,
        loadingCircleRef,
        devMode,
        reportIDs,
        datasetIDs,
        layoutType,
        setIsLoaded,
        setIsRendered,
        setReport,
        setRenderCount,
        filters,
        params,
        loadingCircleStyle
    } = props
    const {currentJoke} = useJokeContext()
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0()

    /*** States and Memos ***/

    /*** App Insights Context/ Trackers ***/
    const initialLoadState = {startTime: null, loadUsed: false, bindUsed: false}
    const initialRenderState = {startTime: null, used: false}
    const [loadTimeState, loadTimeDispatch] = useReducer(loadTimeReducer, initialLoadState)
    const [renderTimeState, renderTimeDispatch] = useReducer(renderTimeReducer, initialRenderState)
    const appInsights = useAppInsightsContext();
    const skipFirstRun = true
    const trackLoadTimes = useTrackEvent(appInsights, "Load Duration", loadTimeState)
    const trackRenderTimes = useTrackEvent(appInsights, "Render Duration", renderTimeState)
    const trackBindTimes = useTrackEvent(appInsights, "Bind Duration", loadTimeState)
    const trackTimeout = useTrackEvent(appInsights, "Page Load Time Exceeded Acceptable Duration", loadTimeState)

    const startingDateFilter = useMemo(() => {
        let paramDates = params?.load("Time", undefined)
            ?.split(",")
        if (paramDates) {
            return createMirrorFilter(paramDates[0], paramDates[1])
        }
        let [yesterday, today] = timeRangeISOStringGeneratorSwitch("Yesterday")
        return createMirrorFilter(yesterday, today)
    }, [])

    const embedConfig = useMemo(() => createEmbedConfig(devMode, currentReport, reportIDs, reportToken, filters, startingDateFilter, layoutType), [currentDataset, currentReport, devMode, filters, layoutType, reportIDs, reportToken, startingDateFilter])

    const eventHandlers = useMemo(() => new Map([['loaded', (e) => {
        e.target.style["opacity"] = 0
        if (loadingCircleRef?.current) loadingCircleRef.current.style["opacity"] = 1
        setIsLoaded(true)
        const UserName = user?.name;
        const NickName = user?.nickname;
        const Email = user?.email

        loadTimeDispatch({
            type: "SET_LOAD_USED_AND_TRACK",
            currentReport: currentReport,
            currentDataset: currentDataset,
            tracker: trackLoadTimes,
            UserName: UserName,
            NickName: NickName,
            Email: Email,
            User: user
        })
        renderTimeDispatch({
            type: "SET_START_TIME",
        })
    }], ['rendered', (e) => {
        e.target.style["opacity"] = 1
        if (loadingCircleRef?.current) loadingCircleRef.current.style["opacity"] = 0
        setIsRendered(true)
        setRenderCount(x => x + 1)

        const UserName = user?.name;
        const NickName = user?.nickname;
        const Email = user?.email
        loadTimeDispatch({
            type: "CLEAR_TIMEOUT",
        })
        renderTimeDispatch({
            type: "SET_USED_AND_TRACK",
            currentReport: currentReport,
            currentDataset: currentDataset,
            tracker: trackRenderTimes,
            UserName: UserName,
            NickName: NickName,
            Email: Email,
            User: user
        })
        loadTimeDispatch({
            type: "SET_BIND_USED_AND_TRACK",
            currentReport: currentReport,
            currentDataset: currentDataset,
            tracker: trackBindTimes,
            UserName: UserName,
            NickName: NickName,
            Email: Email,
            User: user
        })
    }],]), [loadingCircleRef, setIsLoaded, setIsRendered, setRenderCount, user])


    /*** UseEffects ***/

    useEffect(() => {
        if (loadingCircleRef?.current) loadingCircleRef.current.style["opacity"] = 1
        const UserName = user?.name;
        const NickName = user?.nickname;
        const Email = user?.email
        loadTimeDispatch({
            type: "SET_START_TIME",
        })
        loadTimeDispatch({
            type: "SET_TIMEOUT",
            currentReport: currentReport,
            currentDataset: currentDataset,
            tracker: trackTimeout,
            UserName: UserName,
            NickName: NickName,
            Email: Email,
            User: user
        })
        return () => {
            // Cleanup on component unmount or if the dependency changes
            loadTimeDispatch({
                type: "CLEAR_TIMEOUT"
            });
        };
    }, [currentDataset, user])

    /*** Components ***/

    const RenderEmbed = useMemo(() => <>
        <LoadingCircle ref={loadingCircleRef} loadingText="Binding Dataset" currentJoke={currentJoke}
                       style={loadingCircleStyle}/>
        <PowerBIEmbed
            cssClassName="reportContainer"
            embedConfig={embedConfig}
            eventHandlers={eventHandlers}
            getEmbeddedComponent={(embedObject) => {
                setReport(embedObject);
            }}/>
    </>, [reportToken, currentReport, currentDataset, layoutType, refresh])

    const loadReport = useMemo(() => (!loading && reportToken && refresh && currentReport && currentDataset && (devMode || datasetIDs[currentDataset])), [loading, reportToken, refresh, currentReport, currentDataset, devMode, datasetIDs])

    /*** Render ***/
    return loadReport ? RenderEmbed :
        <LoadingCircle ref={loadingCircleRef} loadingText="Loading Report" currentJoke={currentJoke}
                       style={loadingCircleStyle}/>
}

export default withAITracking(reactPlugin, EmbeddedReport, "PBIReport")


// TODO: Implement another function that will structure the data better before sending to apps insight
// async function getAllFiltersAndSlicerStatesEfficiently(report) {
//     const results = {
//         reportFilters: [],
//         pageFilters: [],
//         slicersStates: [],
//         visualFilters: []
//     };
//
//     const [reportFilters, pages] = await Promise.allSettled([
//         report.getFilters(),
//         report.getPages()
//     ]);
//
//     if (reportFilters.status === "fulfilled") results.reportFilters = reportFilters.value;
//
//     let activePage;
//     if (pages.status === "fulfilled") {
//         activePage = pages.value.filter(page => page.isActive)[0];
//     }
//
//     if (activePage) {
//         const activePageFilters = await Promise.allSettled([activePage.getFilters()]);
//
//         if (activePageFilters[0].status === "fulfilled") results.pageFilters = activePageFilters[0].value;
//
//         const visualsSettled = await Promise.allSettled([activePage.getVisuals()]);
//
//         if (visualsSettled[0].status === "fulfilled") {
//             const visualsDataPromises = visualsSettled[0].value.map(async visual => {
//                 if (visual.type === "slicer") {
//                     const slicerState = await Promise.allSettled([visual.getSlicerState()]);
//                     if (slicerState[0].status === "fulfilled") {
//                         results.slicersStates.push({
//                             name: visual.name,
//                             state: slicerState[0].value
//                         });
//                     }
//                 } else {
//                     const visualFilter = await Promise.allSettled([visual.getFilters()]);
//                     if (visualFilter[0].status === "fulfilled" && visualFilter[0].value.length > 0) {
//                         results.visualFilters.push({
//                             visualName: visual.name,
//                             filters: visualFilter[0].value
//                         });
//                     }
//                 }
//             });
//
//             // Wait for all visuals data promises to complete using allSettled
//             await Promise.allSettled(visualsDataPromises);
//         }
//     }
//
//     return results;
// }


const loadTimeReducer = (state, action) => {
    switch (action.type) {
        case "SET_START_TIME": {
            let now = new Date()
            return {...state, startTime: now, loadUsed: false, bindUsed: false}
        }
        case "SET_LOAD_USED_AND_TRACK": {
            if (!state.loadUsed) {
                let endTime = new Date()
                let duration = endTime - state.startTime

                action.tracker({
                    StartTime: state.startTime.toISOString(),
                    EndTime: endTime.toISOString(),
                    "Duration(ms)": duration,
                    Report: action.currentReport,
                    Dataset: action.currentDataset,
                    UserName: action.UserName,
                    NickName: action.NickName,
                    Email: action.Email,
                    User: action.User
                    // Filters: JSON.stringify(allFilters)
                })
            }
            return {...state, loadUsed: true}
        }
        case "SET_BIND_USED_AND_TRACK": {
            if (!state.bindUsed) {
                let endTime = new Date()
                let duration = endTime - state.startTime

                action.tracker({
                    StartTime: state.startTime.toISOString(),
                    EndTime: endTime.toISOString(),
                    "Duration(ms)": duration,
                    Report: action.currentReport,
                    Dataset: action.currentDataset,
                    UserName: action.UserName,
                    NickName: action.NickName,
                    Email: action.Email,
                    User: action.User
                    // Filters: JSON.stringify(allFilters)
                })
            }
            return {...state, bindUsed: true}
        }
        case "SET_TIMEOUT":
            // Set the timeout within the reducer and return the updated state
            const timeoutId = setTimeout(() => {
                action.tracker({
                    StartTime: state.startTime.toISOString(),
                    TimeoutAlert: true,
                    Report: action.currentReport,
                    Dataset: action.currentDataset,
                    UserName: action.UserName,
                    NickName: action.NickName,
                    Email: action.Email,
                    User: action.User
                    // Filters: JSON.stringify(allFilters)
                });
                state.didTimeout = true; // Mark as timed out
            }, 20000); //Acceptable load time we will say is 20 seconds
            return {...state, timeoutId: timeoutId};
        case "CLEAR_TIMEOUT":
            if (state.timeoutId) {
                clearTimeout(state.timeoutId);
            }
            return {...state, timeoutId: null};
        default: {
            return state
        }
    }
}

const renderTimeReducer = (state, action) => {
    switch (action.type) {
        case "SET_START_TIME": {
            let now = new Date()
            return {...state, startTime: now, used: false}
        }
        case "SET_USED_AND_TRACK": {
            if (!state.used) {
                let endTime = new Date()
                let duration = endTime - state.startTime

                action.tracker({
                    StartTime: state.startTime.toISOString(),
                    EndTime: endTime.toISOString(),
                    "Duration(ms)": duration,
                    Report: action.currentReport,
                    Dataset: action.currentDataset,
                    UserName: action.UserName,
                    NickName: action.NickName,
                    Email: action.Email,
                    // Filters: JSON.stringify(allFilters)
                })
            }


            return {...state, used: true}
        }
        default: {
            return state
        }
    }
}