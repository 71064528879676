import {setAndGetFilter, setSlicerFromURLParams} from "./FilterUtils";
import {
    applyDynamicBinningAndLastRefreshCheckAfterVisualRenderedEvent,
    applyEventHandlerOnPageChangedEvent,
    applyEventHandlersForButtons,
    applyEventHandlerToSyncTimeSlicers,
    applyRefreshHandler,
    getCommonLabelSlicerName,
    getCustomRangeSlicerName,
    getSlicerByName,
    QslicerTemplate
} from "./ApplyEventHandlers";
import {applyTimeRangeFilterToSlicer} from "./createMirrorFilter";
import {setCustomRangeToInitialValue} from "./timeUtils";

export function LoadedHandler(isLoaded, firstLoad, filteredParams, params, filters, lastRefreshDateState, setLastRefreshDateState, timeFilterInput, setFirstLoad, report) {
    if (isLoaded && firstLoad && Object.keys(filteredParams.url).length > 0) {
        setReportPage(params.load("page", params.load("view", undefined)), report)
            .finally(async () => await startingFilterSetup(firstLoad, params, report, filters, lastRefreshDateState, setLastRefreshDateState, timeFilterInput))
            // .finally(async () => await setChartFromURLParams(report, params))
            .finally(() => setFirstLoad(false))
            .finally(async () => setTimeout(async () => {
                await setSlicerFromURLParams(report, params)
            }, 1000))
    } else if (isLoaded) {
        startingFilterSetup(firstLoad, params, report, filters, lastRefreshDateState, setLastRefreshDateState, timeFilterInput)
            .catch(e => console.log(e))
            .finally(() => setFirstLoad(false))
    }
}

export function RenderedHandler(isRendered, report, lastRefreshDateState, setLastRefreshDateState, setDatepickerOpen, setDates, useLastRefreshDate, newJoke) {
    if (!isRendered) return
    applyEventHandlerToSyncTimeSlicers(report, lastRefreshDateState, setLastRefreshDateState)
        .catch(e => console.log(e))
    applyEventHandlersForButtons(report, setDatepickerOpen, setDates)
    applyEventHandlerOnPageChangedEvent(report, useLastRefreshDate, setDatepickerOpen, setDates)
    newJoke()
}

export async function startingFilterSetup(firstLoad, params, report, filters, lastRefreshDateState, setLastRefreshDateState, timeFilterInput) {
    let timeRange = firstLoad ? params.load("DateSelect", "Today") : "Today"
    let timeSlice = firstLoad ? params.load("Time", undefined)
        ?.split(",") : ""
    await setAndGetFilter(report, filters)
        .catch(e => console.log(e))
    if (timeSlice) {
        await applyTimeRangeFilterToSlicer(timeSlice[0], timeSlice[1], report)
            .catch(e => console.log(e))
        let QSlicerName = await getCommonLabelSlicerName(report)
        let QSlicer = await getSlicerByName(report, QSlicerName)
        await QSlicer.setSlicerState(QslicerTemplate([timeRange]))
            .catch(e => console.log(e))
        let customRangeSlicerName = await getCustomRangeSlicerName(report)
        await applyDynamicBinningAndLastRefreshCheckAfterVisualRenderedEvent(report,
            customRangeSlicerName,
            lastRefreshDateState,
            setLastRefreshDateState)
            .catch(e => console.log(e))
    } else if (timeRange !== "Today") {
        await setCustomRangeToInitialValue(report, timeRange, timeFilterInput)
    }
}

export async function setReportPage(view, report) {
    if (!view) {
        return
    }
    let pages = await report.getPages()
    let pageNames = pages.map(x => x.displayName)
    if (!pageNames.includes(view)) {
        await report.bookmarksManager.getBookmarks()
            .then(bookmark => bookmark.find(x => x.displayName === "Dashboards")
                .children
                .find(x => x.displayName === view))
            .then(async bookmark => await report.bookmarksManager.apply(bookmark.name))
        return
    }
    let newPage
    try {
        newPage = pages.find(page => page.displayName === view)
    } catch {
        return
    }
    await report.setPage(newPage.name)
}

export async function refreshReport(report, realRefresh, isRendered, setRealRefresh, setIsRendered) {
    if (!!report && !realRefresh && isRendered) {
        setTimeout(() => {
        }, 200)
        try {
            setRealRefresh(true)
            setIsRendered(false)
            await applyRefreshHandler(report, 'Refresh Data Button')
                .catch(() => setIsRendered(true))
                .finally(() => setRealRefresh(false))
        } catch (e) {
        }
    }
}