import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import PageNotFound from "./404";
import App from "./App";
import Auth0History from './Contexts/Auth0History';
import Monolith from "./components/Monolith";
import {ThemeContextProvider} from "./Contexts/ThemeProvider";
import './index.css';
import LogoutHandler from "./layout/LogoutHandler";
import reportWebVitals from './reportWebVitals';
import AccessAdmin from "./userAdmin/AccessAdmin";
import UserActivityLayout from "./userAdmin/UserActivityLayout";
import UserAdmin from "./userAdmin/UserAdmin";
import OBIWiki from './Wiki/Wiki';
import {AppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "./AppInsights";

ReactDOM.render(
    <AppInsightsContext.Provider value={reactPlugin}>
        <React.StrictMode>
            <BrowserRouter>
                <Auth0History>
                    <ThemeContextProvider>
                        <Switch>
                            <Route exact path="/"
                                   component={({location}) => (
                                       <Redirect to={{
                                           ...location,
                                           pathname: location.pathname.replace('/', '/OBI')
                                       }}
                                       />
                                   )}
                            >
                            </Route>
                            <Route exact path="/wiki"
                                   component={({location}) => (
                                       <Redirect to={{
                                           ...location,
                                           pathname: location.pathname.replace('/wiki', '/OBI/wiki')
                                       }}
                                       />
                                   )}
                            >
                            </Route>
                            <Route exact path="/userAdmin" component={UserAdmin}/>
                            <Route exact path="/userActivity" component={UserActivityLayout}/>
                            <Route path="/OBI/wiki" component={OBIWiki}/>
                            <Route path="/OBI/" component={App}/>
                            <Route exact path="/callback" component={LogoutHandler}/>
                            <Route path="/🕋" component={Monolith}/>
                            <Route path="/accessAdmin" component={AccessAdmin}/>
                            <Route path="*" component={PageNotFound}/>
                        </Switch>
                    </ThemeContextProvider>
                </Auth0History>
            </BrowserRouter>
        </React.StrictMode>
    </AppInsightsContext.Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// tiny change
reportWebVitals();