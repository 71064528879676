import {models} from "powerbi-client";
import {filterTemplate} from "./FilterUtils";

/**
 * creates filterObject from ISO time strings
 * @param {string} startDateISOString
 * @param {string} endDateISOString
 * @returns {{$schema: string, logicalOperator: string, filterType: Advanced, conditions: [{value, operator: string},{value, operator: string}], target: {column: string, table: string}}}
 */
const createMirrorFilter = (startDateISOString, endDateISOString) => {
    return {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
            table: "TimeZoneMapping",
            column: "AdjustedTime"
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "And",
        conditions: [
            {
                operator: "GreaterThanOrEqual",
                value: startDateISOString
            },
            {
                operator: "LessThan",
                value: endDateISOString
            }
        ]
    }
}

const makeBinSizeSlicer = (timeRange) => {
    let slicerFilter = ["1 week"]
    if (timeRange < 12 /*months*/ * 4 /*weeks*/ * 7 /*days*/ * 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000) {slicerFilter.push("1 day")}
    if (timeRange < 31 /*days*/ * 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000) {slicerFilter.push("1 hour")}
    if (timeRange < 7 /*days*/ * 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000) {slicerFilter.push("10 minute")}
    return slicerFilter
}

/**
 * try-block wrapped attempt to update the custom range time filter
 * @param {string}startDateISOString
 * @param {string}endDateISOString
 * @param {models.Report}report
 * @param skipSettingBinSize
 * @returns {Promise<void>}
 */
const applyTimeRangeFilterToSlicer = async (startDateISOString, endDateISOString, report, skipSettingBinSize = false) => {
    let timeRangeFilter = createMirrorFilter(startDateISOString, endDateISOString)
    try {
        const timeRange = Date.parse(endDateISOString) - Date.parse(startDateISOString)
        // if timeRange between 1 and 2 days then use "10 minute"
        // if timeRange between 2 and 7 days then use "1 hour"
        // if timeRange between 7 days and 1 year then use "1 day"
        // if timeRange < 1 year then use "1 week"
        const binSize =
            timeRange < 2 /*days*/ * 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000
                ? "Minutes"
                : timeRange < 7 /*days*/ * 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000
                    ? "Hour"
                    : timeRange < 7 /*months*/ * 4 /*weeks*/ * 7 /*days*/ * 24 /*hours*/ * 60 /*minutes*/ * 60 /*seconds*/ * 1000
                        ? "Day" : "Week"
        const binSizeSlicer = makeBinSizeSlicer(timeRange)
        const binSizeFilter = filterTemplate("BinningSizeDisplayName", [binSize], "TimeZoneMapping", true, true)
        const binSizeSlicerFilter = filterTemplate("Binningsize", binSizeSlicer, "TimeZoneMapping", false, true)
        const pages = await report.getPages()
        let pageWithSlicer = pages.filter(page => page.isActive)[0]
        const visuals = await pageWithSlicer.getVisuals()
        let binSizeSlicerVisual = visuals.filter(visual => visual.type === 'ChicletSlicer1448559807354' && ["Binning Size"].includes(visual.title))[0]
        await binSizeSlicerVisual.updateFilters(models.FiltersOperations.Replace, [binSizeSlicerFilter]).catch(e => console.log(e))
        if (!skipSettingBinSize){
            await binSizeSlicerVisual.setSlicerState({filters: [binSizeFilter]}).catch(e => console.log(e))
        }
        let slicer = visuals.filter(visual => visual.type === "slicer" && ["Custom Time Range", "Time Slicer"].includes(visual.title))[0]
        await slicer.setSlicerState({filters: [timeRangeFilter]}).catch(e => console.log(e))
    } catch (e) {
        console.log(e)
    }
}

export {applyTimeRangeFilterToSlicer, createMirrorFilter}