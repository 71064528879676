import {useAuth0} from "@auth0/auth0-react";
import {KeyboardDoubleArrowRight, RadioButtonChecked} from "@mui/icons-material";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Radio,
    styled,
    SwipeableDrawer,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import {grey, orange} from "@mui/material/colors";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Link} from "react-router-dom";
import Auth from "./Auth";
import {FAIcon} from "./fa-icon";
import NewFeaturesModal from "./NewFeaturesModal";
import Profile from "./Profile";
import "./styles/NthDrawer.css"
import {useToken} from "../Contexts/TokenProvider";
import {reactPlugin} from "../AppInsights";
import {withAITracking} from "@microsoft/applicationinsights-react-js";


const Puller = styled(Box)(props => {
    const {
        theme,
        width = 32
    } = props
    return ({
        width: width,
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '0 1px 1px 0',
        position: 'absolute',
        right: -width,
        visibility: 'visible'
    })
})

const DrawerBox = styled(Grid)(({theme}) => ({
    width: 200,
    height: '100%',
    backgroundColor: theme.palette.primary.main
}))

const NthDivider = props => <Divider color={orange["A400"]} {...props}/>


// const UnavailableListItem = props =>
//     <Tooltip enterDelay={500}
//              componentsProps={{
//                  tooltip: {
//                      style: {
//                          background   : "none",
//                          pointerEvents: 'none'
//                      }
//                  }
//              }}
//              title={
//                  <Paper
//                      sx={{
//                          width : '100%',
//                          height: '100%'
//                      }}
//                      {{...props.paperProps}}
//                  >
//                      <Typography padding={1}>{props.tipText}</Typography>
//                  </Paper>
//              }
//              {{...props}}
//     >
//         <ListItemButton disabled style={{pointerEvents: "all"}}>
//             <ListItemText primary={props.title}/>
//             {/*<ListItemIcon>*/}
//             {/*	<FAIcon icon="" style={{color: grey[800]}}/>*/}
//             {/*</ListItemIcon>*/}
//         </ListItemButton>
//     </Tooltip>

// const UpgradeListItem = props => <Tooltip enterDelay={500} componentsProps={{
//     tooltip: {
//         style: {
//             background   : "none",
//             pointerEvents: 'none'
//         }
//     }
// }} title={
//     <Paper sx={{
//         width      : '100%',
//         height     : '100%',
//         border     : 2,
//         borderColor: orange[400]
//     }}
//     >
//         <Typography padding={1}>Upgrade Plan for Access</Typography>
//     </Paper>
// }
// >
//     <ListItemButton disabled
//                     style={{
//                         pointerEvents: "all",
//                         opacity      : 1,
//                         color        : orange[400]
//                     }}
//     >
//         <ListItemText primary={props.title} color={orange[400]}/>
//         <ListItemIcon>
//             <FAIcon icon={<Star sx={{fill: orange[400] + " !important"}}/>}/>
//         </ListItemIcon>
//     </ListItemButton>
// </Tooltip>

const StyledListItem = props =>
    <ListItemButton component={props?.outer ? "a" : Link}
                    onClick={() => props?.outer
                        ? window.open(props?.link, "_blank")
                        : null}
                    to={props?.outer
                        ? null
                        : props?.link}
                    style={{
                        textDecoration: 'none',
                        "color": 'white'
                    }}
                    sx={{backgroundColor: props.highlight ? "#FFFFFF33" : ""}}
    >
        <ListItemText primary={props.title}/>
        {props.icon ?
            <ListItemIcon>
                <FAIcon icon={props.icon}/>
            </ListItemIcon>
            : null}
    </ListItemButton>

const DrawerList = props => {
    const {
        list = [],
        listTitle = 'List',
        changeListItem = _ => _,
        selected
    } = props
    return <List id="productList">
        <ListItem>
            <ListItemText primary={listTitle}/>
        </ListItem>
        {list.map(item => <ListItemButton dense id={"NthDrawerProduct" + item}
            // sx={{backgroundColor: grey[800], ":hover": {backgroundColor: grey[900]}}}
                                          onClick={() => {
                                              window.history.pushState({}, null, `?`)
                                              changeListItem(item)
                                          }}
                                          key={item.toString() + 'DrawerListItem'}
        >
            <Radio checkedIcon={<RadioButtonChecked sx={{fill: grey[900] + " !important"}}/>}
                   checked={selected === item} size="small"
            />
            <ListItemText primary={item}/>
        </ListItemButton>)}
    </List>
}

export default withAITracking(reactPlugin, function NthDrawer(props) {
    const {
        user
    } = useAuth0()

    const {
        userRoles
    } = useToken()

    // current endpoint
    let currentEndpoint = useMemo(() => window.location.pathname.split("/")
        ?.at(-1), [window.location])

    const
        demo = useMemo(() => user?.email === "demouser@yourcompany.com", [user])
    const theme = useTheme()
    const {
        pullerProps,
        changeProduct,
        selectedProduct,
        product
    } = props
    const products = useMemo(() => props.products?.map(x => x.toUpperCase()), [demo, props.products])
    const [open, setOpen] = useState(false)
    const chevronRef = useRef()

    useEffect(() => {
        if (open && chevronRef.current) {
            chevronRef.current.style.transform = "scaleX(-1) scale(1.2)"
        } else if (chevronRef.current) {
            chevronRef.current.style.transform = "scale(1.2)"
        }
    }, [open])

    useEffect(() => {
        setOpen(false)
    }, [product])

    const [featuresModalOpen, setFeaturesModal] = useState(!(localStorage.NoStartModal
        ? JSON.parse(localStorage.NoStartModal)
        : false))

    const handleOpen = () => setFeaturesModal(true)
    const handleClose = () => setFeaturesModal(false)

    return <>
        {!!user?.email && user?.email !== "oeeo@automationnth.com" ?
            <NewFeaturesModal open={featuresModalOpen} onClose={handleClose}/>
            : null}
        <SwipeableDrawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            ModalProps={{keepMounted: true}}
            sx={{overflow: 'visible'}}
            PaperProps={{
                style: {
                    overflow: 'visible'
                }
            }}
        >
            <DrawerBox container direction="column" justifyContent="space-between">
                <Grid item>
                    {/*Navigation Icons*/}
                    <ListItem>
                        <Link to="/OBI" style={{textDecoration: 'none'}}>
                            <IconButton title="Home" size="small">
                                <FAIcon icon="" size="small" style={{color: theme.palette.background.default}}/>
                            </IconButton>
                        </Link>
                        <Tooltip componentsProps={{
                            popper: {sx: {background: 'none'}},
                            tooltip: {sx: {backgroundColor: grey[900]}}
                        }} title={<Profile/>} enterDelay={500} color={orange[800]}
                        >
                            <div>
                                <Auth/>
                            </div>
                        </Tooltip>
                        <IconButton
                            title="Feedback"
                            href={
                                `mailto:oeeo@automationnth.com
                                        ?subject=${encodeURIComponent("OEE Optimizer Bug Report")}
                                        &body=${encodeURIComponent(window.location + '\n' +
                                    navigator.userAgent + '\n\n' +
                                    'Please submit your feedback below:\n')}`}
                            size="small"
                        >
                            <FAIcon icon={"\uf4ad".toString()} size="small"/>
                        </IconButton>
                        <IconButton size="small" title="Wiki"
                                    onClick={handleOpen}
                        >
                            <FAIcon
                                icon={"\uf059".toString()} size="small"
                            />
                        </IconButton>
                    </ListItem>
                    <NthDivider/>
                    {/*Report Selection List*/}
                    {products?.length ?
                        <>
                            <DrawerList listTitle="Products" list={products} selected={selectedProduct}
                                        changeListItem={changeProduct}
                            />
                            <NthDivider/>
                        </> :
                        null}
                    {/*Other Products*/}
                    <List>
                        <StyledListItem title="OEE Optimizer"
                                        key="homelink"
                                        link={currentEndpoint === "obi" ? "" : "/obi"}
                                        highlight={currentEndpoint?.toLowerCase() === "obi"}
                        />
                        <StyledListItem title="PLC Code Repository"
                                        key="copialink"
                                        link="https://app.copia.io/org/AutomationNTH/repositories"
                                        outer
                        />
                        <StyledListItem title="Knowledge Base"
                                        key="supportlink"
                                        link="https://support.automationnth.com/"
                                        outer
                        />
                        <StyledListItem title="Submit a Ticket"
                                        key="ticketlink"
                                        link="https://automationnth.atlassian.net/servicedesk/customer/portal/3/group/-1"
                                        outer
                        />
                        {
                            userRoles.includes("SuperUser") ?
                                <StyledListItem title="User Activity"
                                                key="useractivitylink"
                                                link="/userActivity"
                                                highlight={currentEndpoint?.toLowerCase() === "useractivity"}
                                /> :
                                // <UnavailableListItem title="User Activity" tipText="Coming Soon"/> :
                                null
                        }
                        {
                            userRoles.includes("NTH Admin")
                                ? <>
                                    <StyledListItem title="Access Admin"
                                                    key="accessadminlink"
                                                    link="/AccessAdmin"
                                                    highlight={currentEndpoint?.toLowerCase() === "accessadmin"}
                                    />
                                </>
                                : null
                        }
                        {/*<UnavailableListItem title="Predictive Analytics" tipText="Upgrade Plan for Access"/>*/}
                    </List>
                </Grid>
                <Grid item width="Calc(100%)" paddingLeft={3} height="fit-content">
                    <img id="nthDrawerLogo" style={{alignSelf: "flex-end"}} alt="Automation nth logo"
                         src="/nthLite2.png" width="2928"
                    />
                </Grid>
            </DrawerBox>
            <Puller {...pullerProps}>
                <Grid container direction="row" alignContent="flex-start" justifyContent="center"
                      style={{
                          width: '100%',
                          height: '100%',
                          pointerEvents: 'all',
                          paddingTop: 10,
                          cursor: "pointer"
                      }}
                      onClick={() => setOpen(x => !x)}
                >
                    <Grid item><KeyboardDoubleArrowRight ref={chevronRef} id="doublechevron"/></Grid>
                    <Grid item width="100%"><Typography fontSize={14} fontFamily="Arial" fontWeight="bold"
                                                        color="#FFFFFF"
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            paddingLeft: 1
                                                        }}
                    >
                        Products
                    </Typography></Grid>
                </Grid>
            </Puller>
        </SwipeableDrawer>
    </>;
}, "NTHDrawer")