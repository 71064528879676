import * as React from 'react'
import {useEffect, useMemo} from 'react'
import {
	alpha,
	Box,
	Button,
	Checkbox,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Toolbar,
	Tooltip,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import {visuallyHidden} from "@mui/utils"
import {FAIcon} from "../components/fa-icon";

const UserListTable = props => {
	const {
		headers,
		listTitle = "List",
		filteredList: userList,
		userList: totalList,
		checkFunction,
		selected,
		setSelected,
		deleteFunction,
		ProductSelect,
		newNameFunc,
		newEmailFunc,
		newPassFunc,
		newUserButton
	} = props
	const rows = useMemo(() => userList.map(x => ({
		name: x.name,
		email: x.email,
		...x.app_metadata
	})), [userList])
	const headCells = useMemo(() => {
		let hc = [
			{
				id: "name",
				label: "Name",
			},
			{
				id: 'email',
				label: 'Email'
			}
		]
		let ids = ['name', 'email']
		for (let key of headers)
			if (!ids.includes(key.toUpperCase())) {
				ids.push(key.toUpperCase())
				if (key.toUpperCase().includes('admin'))
					if (key.toUpperCase().includes('super'))
						hc.push({id: key, label: key.toUpperCase().replace('superadmin', ' Super')})
					else hc.push({id: key, label: key.toUpperCase().replace('admin', ' Admin')})
				else hc.push({id: key, label: key.toUpperCase().split('access')[0]})
			}
		return hc
	}, [totalList])
	const headList = useMemo(() => headCells.map(x => x.id), [headCells])

	/** Example States **/
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(true);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [confirmDelete, setConfirm] = React.useState(false);
	const [addUserExpand, setAddUserExpand] = React.useState(false)

	useEffect(() => {
		setConfirm(false)
	}, [selected])

	/* From Example */


	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	function EnhancedTableHead(props) {
		const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
			props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};

		return (
			<TableHead>
				<TableRow id="UserListHeader">
					<TableCell padding="checkbox">
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								'aria-label': 'select all desserts',
							}}
						/>
					</TableCell>
					{headCells.map((headCell) => (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
							width={['email', 'name'].includes(headCell.id) ? '' : '5%'}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	}

	EnhancedTableHead.propTypes = {
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(['asc', 'desc']).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	const EnhancedTableToolbar = (props) => {
		const {numSelected} = props;

		return (
            <Toolbar
				sx={{
					pl: {sm: 2},
					pr: {xs: 1, sm: 1},
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography
						sx={{flex: '1 1 100%'}}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						{numSelected} selected
					</Typography>
				) : (
					<Typography
						sx={{flex: '1 1 100%'}}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						{listTitle}
					</Typography>
				)}

				{numSelected > 0 ? (
					<Tooltip placement={confirmDelete ? "top" : "bottom"} title={confirmDelete ?
						<Typography color="nth">Are you sure?</Typography> :
						"Delete"}>
						<IconButton
                            color={confirmDelete ? "error" : "secondary"}
                            onClick={confirmDelete ? (e) => {
                                deleteFunction(e);
                                setConfirm(false)
                            } : () => setConfirm(true)}
                            size="large">
							<FAIcon icon=""/>
						</IconButton>
					</Tooltip>
				) : ''}
			</Toolbar>
        );
	};

	EnhancedTableToolbar.propTypes = {
		numSelected: PropTypes.number.isRequired,
	};

	function EnhancedTable() {

		const handleRequestSort = (event, property) => {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		};

		const handleSelectAllClick = (event) => {
			if (event.target.checked) {
				const newSelecteds = rows.map((n) => n.email);
				setSelected(newSelecteds);
				return;
			}
			setSelected([]);
		};

		const handleClick = (event, name) => {
			const selectedIndex = selected.indexOf(name);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, name);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				);
			}

			setSelected(newSelected);
		};

		const handleChangePage = (event, newPage) => {
			setPage(newPage);
		};

		const handleChangeRowsPerPage = (event) => {
			setRowsPerPage(parseInt(event.target.value, 10));
			setPage(0);
		};

		const isSelected = (name) => selected.indexOf(name) !== -1;

		// Avoid a layout jump when reaching the last page with empty rows.
		const emptyRows =
			page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

		return (
			<Box sx={{width: '100%'}}>
				<EnhancedTableToolbar numSelected={selected.length}/>
				<TableContainer>
					<Table
						sx={{minWidth: 750}}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
							{stableSort(rows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.email);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											className="UserRow"
											hover
											onClick={(event) => handleClick(event, row.email)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.email}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell
												component="th"
												id={labelId}
												className="UserNameCell"
												scope="row"
												padding="none"
											>
												{row.name}
											</TableCell>
											{headList.map((x) => {
												if (!row[x] || row[x] === true)
													return <TableCell id={x+row.name.replace(" ","")} className={x} align="left"><Checkbox
														onClick={e => checkFunction(row, x, e)}
														checked={row[x] === true}/></TableCell>
												if (x !== 'name')
													return <TableCell align="left">{row[x]}</TableCell>
											})}
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6}/>
								</TableRow>
							)}
							{addUserExpand ?
								<TableRow
									hover
									role="checkbox"
									aria-checked={false}
									tabIndex={-1}
									key={"newUserRow"}
									selected={false}
								>
									<TableCell colSpan={1} padding="checkbox"><Checkbox disabled/></TableCell>
									<TableCell
										colSpan={1}
										component="th"
										id={"NewUserId"}
										scope="row"
										padding="none"
										componentProps
									>
										<TextField id="newUserNameInput" label="Name" onChange={newNameFunc}/>
									</TableCell>
									<TableCell
										component="th"
										id={"NewUserEmail"}
										scope="row"
										padding="none"
									>
										<TextField id="newUserEmailInput" label="Email" onChange={newEmailFunc}/>
									</TableCell>
									<TableCell
										colSpan={2}
										component="th"
										id={"NewUserPass"}
										scope="row"
										padding="none"
									>
										<TextField id="newUserPassInput" label="Password" type="password" onChange={newPassFunc}/>
									</TableCell>
									<TableCell
										colSpan={3}
									>
										{ProductSelect}
									</TableCell>
									{/*{headList.map((x) => {*/}
									{/*	if (!['name', 'email'].includes(x))*/}
									{/*		return <TableCell align="left"><Checkbox/></TableCell>*/}
									{/*})}*/}
								</TableRow> : null}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell colSpan={3}>
									<Button size="small"
									        variant="contained"
									        style={{marginTop: "20px"}}
									        id="NewUserPopButton"
									        onClick={() => setAddUserExpand(x => !x)}>
										{addUserExpand ? "Close" : "New User"}
									</Button>
									{addUserExpand ?
										newUserButton : null
									}
								</TableCell>
								<TableCell colSpan={headList.length}>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={rows.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</TableCell>

							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</Box>
		);
	}

	/* End Example */
	return <>
		{EnhancedTable()}
	</>
}
export default UserListTable