import _, {debounce}                     from "lodash";
import {models}                          from "powerbi-client";
import {ISlicerState}                    from "powerbi-models";
import binningStringSwitchGenerator      from "../binningStringSwitchGenerator";
import {applyTimeRangeFilterToSlicer}    from "./createMirrorFilter";
import targetSwitch                      from "../TargetSwitch";
import timeRangeISOStringGeneratorSwitch from "../timeRangeISOStringGeneratorSwitch";


/**
 * Gets Quick-Select Visual
 * @param {Report} report
 * @returns {Promise<VisualDescriptor>} Quick Select Visual
 */
const getCommonLabelSlicer = async (report) => {
    if (report !== undefined) {
        try {
            const pages   = await report.getPages()
            let page      = pages.filter(page => page.isActive)[0]
            const visuals = await page.getVisuals()
            return visuals.filter(visual => (visual.type === "slicer") && (visual.title === "Quick Select"))[0]
        }
        catch (e) {
            console.log(e)
        }
    }
}


/**
 * gets the name for the Common Label Visual. Required since dataSelected event doesn't provide title
 * @param {Report, undefined}report
 * @returns {Promise<string>} - Name of Common Label Visual
 */
const getCommonLabelSlicerName = async (report) => {
    if (report !== undefined) {
        try {
            return await getCommonLabelSlicer(report)
                .then(x => x?.name)
        }
        catch (e) {
            console.log(e)
        }
    }
}

/**
 * gets the name for the Custom Range Visual.
 * @param report
 * @returns {Promise<string>} - Name of Custom Range Slicer
 */
const getCustomRangeSlicerName = async report => {
    if (report !== undefined) {
        try {
            const pages           = await report.getPages()
            let page              = pages.filter(page => page.isActive)[0]
            const visuals         = await page.getVisuals()
            let customRangeSlicer = visuals.filter(visual => (visual.type === "slicer")
                                                             && (visual.title === "Time Slicer"))[0]
            return customRangeSlicer?.name
        }
        catch (e) {
            console.log(e)
        }
    }
}

const getStartEndDateVisualName = async report => {
    if (report !== undefined) {
        try {
            const pages            = await report.getPages()
            let page               = pages.filter(page => page.isActive)[0]
            const visuals          = await page.getVisuals()
            let startEndDateVisual = visuals.filter(visual => visual.title.split(':')[0] === "StartDate")[0]
            return startEndDateVisual?.name
        }
        catch (e) {
            console.log(e)
        }
    }
}

const getSlicerByName = async (report, slicerName) => {
    const pages  = await report.getPages()
    let page     = pages.filter(page => page.isActive)[0]
    const visual = await page.getVisuals()

    return visual.filter(visual => visual.type === "slicer" && visual.name === slicerName)[0]
}

const getLastRefreshDate = async report => {
    if (report !== undefined) {
        try {
            const pages               = await report.getPages()
            let page                  = pages.filter(page => page.isActive)[0]
            const visuals             = await page.getVisuals()
            let lastRefreshDateVisual = visuals.filter(visual => visual.title.split('-')[0] === "LastDateRefresh")[0]
            return new Date(lastRefreshDateVisual.title.split('-')[1])
        }
        catch (e) {
            console.log(e)
        }
    }
}

/**
 * @param {Report}report
 * @param {string}slicerName
 * @param returnState
 * @returns {Promise<*>} - Current Slicer Filter State
 */
const getCustomRangeSlicerState = async (report, slicerName, returnState = false) => {
    const pages  = await report.getPages()
    let page     = pages.filter(page => page.isActive)[0]
    const visual = await page.getVisuals()

    let slicer = visual.filter(visual => visual.type === "slicer" && visual.name === slicerName)[0]

    const state = await slicer.getSlicerState()
    if (returnState) {
        return state
    }
    return state?.filters?.[0]?.conditions.map(values => new Date(values.value))
}

/**
 * @param {Report, undefined}report
 * @param {string}bookmarkString
 * @returns {Promise<void>}
 */
const applyDynamicBinningBookmark = async (report, bookmarkString) => {
    try {
        if (report !== undefined) {
            report.bookmarksManager.apply(bookmarkString)
                  .catch(e => console.log(e))
        }
    }
    catch (e) {
        console.log(e)
    }
}

/**
 *
 * @param {Report}report
 * @param {string}bookmarkDisplayName
 * @returns {Promise<string>} - Name of the bookmark
 */
const getBookmarkName = async (report, bookmarkDisplayName) => {
    const pages        = await report.getPages()
    let currentPage    = pages.filter(page => page.isActive)[0]
    let pageName       = currentPage.displayName
    const bookmarks    = await report.bookmarksManager.getBookmarks();
    let pageBookmarks  = bookmarks.filter(bookmark => bookmark.displayName === pageName)[0]
    let bookmarkObject = pageBookmarks.children.filter(bookmark => bookmark.displayName
                                                                   === pageName
                                                                   + ": "
                                                                   + bookmarkDisplayName)[0]
    return bookmarkObject.name
}

/**
 * @param {Report}report
 * @param {Date[]}startAndEndDate
 * @param lastBinningString
 * @param setLastBinningString
 * @returns {Promise<void>}
 */
const updateDynamicBinningBookmark = async (report, startAndEndDate, lastBinningString, setLastBinningString) => {
    // const startAndEndDate = await getStartAndEndDateSelected().catch(e => console.log(e))
    const bookmarkString = binningStringSwitchGenerator(startAndEndDate)
    if (bookmarkString !== lastBinningString) {
        const bookmarkName = await getBookmarkName(report, bookmarkString)
        applyDynamicBinningBookmark(report, bookmarkName)
            .catch(e => console.log(e))
        setLastBinningString(bookmarkString)
    }
}

/**
 * Empties selections for chiclet slicer
 * @param {Report}report
 * @param {string}targetSlicer
 * @returns {Promise<void>}
 */
const setSlicerToEmpty = async (report, targetSlicer) => {
    try {
        const pages = await report.getPages();

        // Retrieve the active page.
        let page = pages.filter(function (page) {
            return page.isActive;
        })[0];

        const visuals = await page.getVisuals();

        // Retrieve the target visual.
        let slicer = visuals.filter(function (visual) {
            return visual.title === targetSlicer && visual.type.toLowerCase()
                                                          .includes("slicer");
        })[0];

        // Set the slicer state which contains the slicer filters.
        slicer.setSlicerState({filters: []})
              .catch(e => console.log(e));
    }
    catch (errors) {
        console.log(errors);
    }
}

/**
 * @param {Report}report
 * @param {string}slicerTarget
 * @param {Array}values - if no values are provided, selects all
 * @returns {Promise<void>}
 */
const setSlicerToValues = async (report, slicerTarget, values = undefined) => {
    try {
        const pages = await report.getPages();

        // Retrieve the active page.
        let page = pages.filter(function (page) {
            return page.isActive;
        })[0];

        const visuals = await page.getVisuals();

        // Retrieve the target visual.
        let slicers     = visuals.filter(function (visual) {
            return visual.title
                   === (slicerTarget === "Islandgroup" ? "Islands" : slicerTarget === "Number"
                                                                     ? "Line"
                                                                     : slicerTarget)
                   && visual.type.toLowerCase()
                            .includes("slicer");
        })
        let slicer      = slicers[0];
        let slicerState = await slicer.getSlicerState()

        let filterAndTarget = await createSlicerChoicesFilterAndTarget(report,
                                                                       slicerState?.targets[0] || slicerTarget,
                                                                       values,
                                                                       !!slicerState?.targets[0] || true)
        let newState        = {}
        do {
            await slicer.setSlicerState(filterAndTarget)
                        .catch(e => console.log(e));
            newState = await slicer.getSlicerState()
                                   .then(state => state?.filters[0]?.values)
            console.log(values, newState)
        }
        while (!_.isEqual(newState, values))
    }
    catch (e) {
        console.log(e)
    }
}

/**
 * @param {Report}report
 * @param {string}slicerTarget
 * @param {array}values
 * @param {boolean}realTarget
 * @returns {Promise<ISlicerState>}
 */
const createSlicerChoicesFilterAndTarget = async (report, slicerTarget, values = undefined, realTarget = false) => {
    let slicerChoices = values || (await getSlicerChoices(report, slicerTarget))
    let target        = realTarget ? slicerTarget : targetSwitch(slicerTarget)
    return {
        filters: [
            {
                $schema   : 'http://powerbi.com/product/schema#basic',
                target    : target,
                filterType: models.FilterType.Basic,
                operator  : "In",
                values    : slicerChoices
            }
        ],
        target : target
    }
}

const QslicerTemplate = values => ({
    "filters": [
        {
            "$schema"               : "http://powerbi.com/product/schema#basic",
            "target"                : {
                "table" : "LabelOrder",
                "column": "LabelForVisual"
            },
            "filterType"            : 1,
            "operator"              : "In",
            "values"                : values,
            "requireSingleSelection": false
        }
    ],
    "targets": [
        {
            "table" : "LabelOrder",
            "column": "LabelForVisual"
        }
    ]
})

/**
 * @param {Report}report
 * @param {string}slicerTarget
 * @returns {Promise<undefined|*>}
 */
const getSlicerChoices = async (report, slicerTarget) => {
    try {
        const pages = await report.getPages();

        // Retrieve the active page.
        let page = pages.filter(function (page) {
            return page.isActive;
        })[0];

        const visuals = await page.getVisuals();

        // Retrieve the target visual.
        let slicer = visuals.filter(function (visual) {
            let slicerSubject = visual?.title.split(":")?.[0]
            return visual.type === "card" && slicerTarget.includes(slicerSubject);
        })[0];

        return slicer?.title.split(":")?.[1].split(",");
    }
    catch (errors) {
        console.log(errors);
        return undefined
    }
}

/**
 * Applies all event handlers necessary for page event change
 * @param {Report}report
 * @param useLastRefreshDate
 * @param setDatepickerOpen
 * @param setDates
 */
const applyEventHandlerOnPageChangedEvent = (report, useLastRefreshDate, setDatepickerOpen, setDates) => {
    if (report !== undefined) {
        report.off('pageChanged')
        report.on('pageChanged', async e => {
            try {
                applyEventHandlerToSyncTimeSlicers(report, useLastRefreshDate, null)
                    .catch(e => console.log(e))
                applyEventHandlersForButtons(report, setDatepickerOpen, setDates)
            }
            catch (e) {
                console.log(e)
            }
            dispatchEvent(new Event(e.detail.newPage.displayName.toLowerCase()))
            window.history.pushState({}, null, `?`)
        })
    }
}

/**
 * applies event handler for a dataSelected event
 * @param {Report, undefined}report
 * @param lastRefreshDateState
 * @param setLastRefreshDateState
 * @param setDatepickerOpen
 * @returns {Promise<void>}
 */
const applyEventHandlerToSyncTimeSlicers = async (report,
                                                  lastRefreshDateState,
                                                  setLastRefreshDateState = _ => _,) => {
    if (report !== undefined) {
        // name can vary, between report, but cannot be read until rendered (this call is why this blocked is in isRendered
        let commonLabelSlicerName = await getCommonLabelSlicerName(report)
        let customRangeSlicerName = await getCustomRangeSlicerName(report)
        // await updateDynamicBinningBookmark(report, startAndEndDate, lastBinningString, setLastBinningString).catch(e => console.log(e))

        report.off('dataSelected')
        report.on('dataSelected', async slicerUpdated => {
            // debouncedRefresh(report)
            let data       = slicerUpdated.detail
            let slicerHash = data?.visual?.name
            // act only if the dataSelected was for the commonLabelSlicer
            if (slicerHash === commonLabelSlicerName) {
                let labelSelected = data?.dataPoints[0]?.identity[0]?.equals
                if (labelSelected === "Custom Range") {
                    return
                }

                const [startDateISOString, endDateISOString] = timeRangeISOStringGeneratorSwitch(labelSelected)

                applyTimeRangeFilterToSlicer(startDateISOString, endDateISOString, report)
                    .catch(e => console.log(e))
            }
            // let startAndEndDate = getCustomRangeSlicerState(report, customRangeSlicerName)
            // await updateDynamicBinningBookmark(report, startAndEndDate, lastBinningString, setLastBinningString).catch(e => console.log(e))
        })

        applyDynamicBinningAndLastRefreshCheckAfterVisualRenderedEvent(report,
                                                                       customRangeSlicerName,
                                                                       lastRefreshDateState,
                                                                       setLastRefreshDateState)
            .catch(e => console.log(e))
    }
}

/**
 * applies event handler for select all and clear events
 * @param {Report, undefined}report
 */
const applyEventHandlersForButtons = (report, setDatepickerOpen, setDates) => {
    if (report !== undefined) {
        report.off('buttonClicked')
        report.on('buttonClicked', async (event) => {
            window.history.pushState({}, null, `?`)
            let buttonClickData    = event.detail;
            let buttonTitle        = buttonClickData?.title;
            let slicerTargetString = buttonTitle.split(': ')?.[0]
            let slicerActionString = buttonTitle.split(': ')?.[1]
            applyClearHandler(report, slicerActionString, slicerTargetString)
                .catch(e => console.log(e))
            applySelectAllHandler(report, slicerActionString, slicerTargetString)
                .catch(e => console.log(e))
            applyRefreshHandler(report, buttonTitle)
                .catch(e => console.log(e))
            applyDatePickerHandler(report,
                                   buttonTitle,
                                   slicerTargetString,
                                   slicerActionString,
                                   setDatepickerOpen,
                                   setDates)
                .catch(e => console.log(e))
        })
        report.off('bookmarkApplied')
        report.on('bookmarkApplied', async (event) => {
            // get applied bookmark display name
            report.bookmarksManager.getBookmarks()
                  .then(bookmarks => {
                      // get the bookmark that was applied
                      let bookmarkName  = ""
                      let bookmarkGroup = ""
                      bookmarks.forEach(bookmark => {
                          if (bookmark?.children?.length > 0) {
                              const filtered = bookmark.children.filter(children => children.name
                                                                                    === event.detail.bookmarkName)
                              if (filtered.length > 0) {
                                  bookmarkName  = filtered[0].displayName
                                  bookmarkGroup = bookmark.displayName
                              }
                          }
                          else if (bookmark.name === event.detail.bookmarkName) {
                              bookmarkName = bookmark.displayName
                          }
                      })
                      // if the bookmark is a view bookmark, dispatch an event to the view
                      const reportViews = bookmarks.filter(bookmark => bookmark.displayName === "Dashboards")[0]
                          ?.children.map(bookmark => bookmark.displayName)
                      if (reportViews?.includes(bookmarkName)) {
                          dispatchEvent(new Event(bookmarkName.toLowerCase()))
                          return
                      }
                      // if the bookmark is a chart type, dispatch an event to the chart with the parent name attached
                      const bookmarkType = bookmarks.filter(bookmark => bookmark.displayName === bookmarkGroup)[0]
                      dispatchEvent(new Event(bookmarkType.displayName.toLowerCase() + bookmarkName.toLowerCase()))
                  })
        })
    }
}

const applyClearHandler = async (report, slicerActionString, slicerTargetString) => {
    if (slicerActionString === 'Clear') {
        setSlicerToEmpty(report, slicerTargetString)
            .catch(e => console.log(e))
    }
}

const applySelectAllHandler = async (report, slicerActionString, slicerTargetString) => {
    if (slicerActionString === 'Select All') {
        let allSlicerChoices = await getSlicerChoices(report, slicerTargetString)
        setSlicerToValues(report, slicerTargetString, allSlicerChoices)
            .catch(e => console.log(e))
    }
}

const applyRefreshHandler = async (report, buttonTitle) => {
    if (buttonTitle === 'Refresh Data Button') {
        await report.refresh()
            .catch(e => console.log(e))
        let dateState = await getCommonLabelSlicer(report)
            .then(async quickSelect => {
                let slicerState = await quickSelect.getSlicerState()
                return slicerState?.filters[0]?.values[0]?.toString() || ""
            })
        if (dateState !== "Custom Range") {
            const [startDateISOString, endDateISOString] = timeRangeISOStringGeneratorSwitch(dateState)
            applyTimeRangeFilterToSlicer(startDateISOString, endDateISOString, report, true)
                .catch(e => console.log(e))
        }
    }
}

const applyDatePickerHandler = async (report,
                                      buttonTitle,
                                      slicerTargetString,
                                      slicerActionString,
                                      setDatepickerOpen,
                                      setDates) => {
    if (buttonTitle.includes("Date Picker Button")) {
        let customRangeSlicerName = await getCustomRangeSlicerName(report)
        let startAndEndDate       = await getCustomRangeSlicerState(report, customRangeSlicerName)
        setDates(startAndEndDate[0], startAndEndDate[1])
        setDatepickerOpen(buttonTitle.split(" ")[0].toLowerCase())
    }
}

/**
 *  debounce report refresh
 * @type {DebouncedFuncLeading<(function(*): Promise<void>)|*>}
 * @param report {Report}
 */
const debouncedRefresh = debounce(async (report) => {
                                      report.refresh()
                                            .catch(e => console.log(e))
                                  },
                                  10000,
                                  {
                                      leading : true,
                                      trailing: false
                                  })

const applyDynamicBinningAndLastRefreshCheckAfterVisualRenderedEvent = async (report,
                                                                              customRangeSlicerName,
                                                                              lastRefreshDateState    = () => {
                                                                              },
                                                                              setLastRefreshDateState = () => {
                                                                              }) => {
    // let startEndDateVisualName = await getStartEndDateVisualName(report)
    // report.off('visualRendered')
    // report.on('visualRendered', async event => {
    // 	let visualName = event?.detail?.name
    // 	if (visualName === startEndDateVisualName) {
    // 		let startAndEndDate = await getCustomRangeSlicerState(report, customRangeSlicerName)
    // 		// await updateDynamicBinningBookmark(report, startAndEndDate).catch(e => console.log(e))
    // 	}
    //
    // 	let lastRefreshDate = await getLastRefreshDate(report)
    // 	if (lastRefreshDate.getTime() > lastRefreshDateState.getTime() + 7200000) {
    // 		console.log(lastRefreshDate.getTimezoneOffset())
    // 		console.log(lastRefreshDateState.getTimezoneOffset())
    // 		setLastRefreshDateState(lastRefreshDate)
    // 		console.log('lastRefreshDateState', lastRefreshDateState)
    // 		console.log('lastRefreshDate', lastRefreshDate)
    // 		debouncedRefresh(report)
    // 	}
    // })
}

export {
    applyEventHandlerToSyncTimeSlicers,
    applyEventHandlersForButtons,
    applyEventHandlerOnPageChangedEvent,
    applyRefreshHandler,
    getSlicerChoices,
    setSlicerToValues,
    applyDynamicBinningAndLastRefreshCheckAfterVisualRenderedEvent,
    getCustomRangeSlicerName,
    getCommonLabelSlicerName,
    getCustomRangeSlicerState,
    QslicerTemplate,
    getSlicerByName
}