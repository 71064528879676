import {Button, Checkbox, Dialog, FormControlLabel, Grid, Typography} from "@mui/material";
import * as PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

function NewFeaturesModal(props) {
	const [checked, setChecked] = useState(!!JSON.parse(localStorage.NoStartModal ? localStorage.NoStartModal : false))
	useEffect(() => {
		localStorage.NoStartModal = checked
	}, [checked])
	return (<Dialog id="NewFeaturesModal" open={props.open} onClose={props.onClose}
	                PaperProps={{
		                component: Grid, item: true, spacing: 1, padding: 1, container: true, sm: 10, md: 6, sx: {
			                justifyContent: "center", alignContent: "center"
		                }
	                }}
	>
		<Typography id="ThankYouMessage" component={Grid} item xs={12} variant="h6" textAlign="center"
		            color="secondary"
		            width="auto">
			Thank you for using the OEE Optimizer
		</Typography>
		<Typography id="ReleaseVersionMessage" component={Grid} item xs={12} textAlign="center" color="secondary"
		            width="auto">
			We have now released version 4.1
			<br/>A full list of new features and changes can be found in the Release Notes below.
		</Typography>
		<Grid id="ReleaseNotesLinkGrid" item md={7} container justifyContent="center">
			<Button id="ReleaseNotesLinkButton" textAlign="center" target="_blank" fullWidth
			        href="/OEE_Optimizer_V4-0-0_Release_Notes.pdf"
			        color="secondary"
			        sx={{backgroundColor: "#646464"}}
			>
				Click here to see release notes
			</Button>
		</Grid>
		<Grid id="TutorialVideosLinkGrid" item md={7} container justifyContent="center">
			<Button id="TutorialVideosLinkButton" textAlign="center" target="_blank" fullWidth
			        href="https://www.youtube.com/watch?v=GXSMpP3Vbi4&list=PLRXJY79BeIbdxqJwZenADLSvbjWYo6E4T"
			        color="secondary"
			        sx={{backgroundColor: "#646464"}}
			>
				Click here to see tutorial videos
			</Button>
		</Grid>
		<Grid id="WikiLinkGrid" item md={7} container justifyContent="center">
			<Button id="WikiLinkButton" size="small" textAlign="center" title="Wiki" href="/OBI/wiki"
			        target="_blank"
			        rel="noopener noreferrer" fullWidth color="secondary"
			        sx={{backgroundColor: "#646464"}}>
				Click here to go to the wiki
			</Button>
		</Grid>
		<Grid id="NewFeaturesModalCheckboxGrid" item xs={12} textAlign="center">
			<FormControlLabel id="NewFeaturesModalCheckboxLabel"
			                  control={<Checkbox id="NewFeaturesModalCheckbox" checked={checked}
			                                     onChange={value => {
				                                     setChecked(value.target.checked)
			                                     }}/>}
			                  label="Don't show this on startup"/>
		</Grid>
	</Dialog>);
}

NewFeaturesModal.propTypes = {
	open: PropTypes.bool, onClose: PropTypes.func
};

export default NewFeaturesModal