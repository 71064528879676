/** Overrides for the default themes in Material UI **/

let darkTheme = {
	palette: {
		background: {
			default: "#2B2B2B",
			paper: "#202020",
			paperGradient: "linear-gradient(to top, #000000, #202020)",
			errorGradient: "linear-gradient(to top, #550000, #220000)",
			banner: "#000000"
		},
		primary: {
			main: "#646464",
		},
		secondary: {
			main: "#A0A0A0",
		},
		success: {
			main: '#63AF76',
			timelineDark: 'rgba(99, 175, 118,.2)',
		},
		error: {
			main: '#C14444',
			timelineDark: 'rgba(193, 68, 68,.2)',
		},
		warning: {
			main: '#A79E14',
		},
		colorChart: [
			"#f09434",
			"#88498F",
			"#63AF76",
			"#7A8450",
			"#5F74A5",
			"#7DBCD5",
			"#EFA6A6",
			"#83779A",
			"#C14444",
			"#E7E247",],
		nth: "#E7792B",
		nthTheme: {
			main: "#E7792B",
			dark: '#663513',
			light: '#FFA769'
		},
		gauge0: "#C14444",
		gaugeLowLow: "#F09351",
		gaugeLow: "#DAC87B",
		gaugeTarget: "#63AF76",
		gauge1: "#63AF76",
		veil: "#E7792B66",
		nthDark: "#663513",
		nthLight: "#FFA769",
		text: {
			primary: "#FFFFFF",
			secondary: "#A0A0A0",
			disabled: "#646464"
		},
		type: 'dark'
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				body: {
					background:
						"#111"
				}
			}
		}
	},
	typography: {
		fontFamily: "'Lato'",
		h1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h3: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h4: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h5: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h6: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		button: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		caption: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		overline: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		}
	},
	components: {
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fill: "#AAA"
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					display: 'none'
				}
			}
		}
	}
}


let darkThemeCB = {
	palette: {
		background: {
			default: "#2B2B2B",
			paper: "#202020",
			paperGradient: "linear-gradient(to top, #000000, #202020)",
			errorGradient: "linear-gradient(to top, #550000, #220000)",
			banner: "#000000"
		},
		primary: {
			main: "#646464",
		},
		secondary: {
			main: "#A0A0A0",
		},
		success: {
			main: '#FF8800',
			timelineDark: '#FF880033',
		},
		error: {
			main: '#0000FF',
			timelineDark: '#0000FF33',
		},
		warning: {
			main: "#6666ff",
		},
		colorChart: [
			"#f09434",
			"#88498F",
			"#63AF76",
			"#7A8450",
			"#5F74A5",
			"#7DBCD5",
			"#EFA6A6",
			"#83779A",
			"#C14444",
			"#E7E247",],
		nth: "#E7792B",
		nthTheme: {
			main: "#E7792B",
			dark: '#663513',
			light: '#FFA769'
		},
		gauge0: "#0000ff",
		gaugeLowLow: "#3232ff",
		gaugeLow: "#6666ff",
		gaugeTarget: "#9999ff",
		gauge1: "#ccccff",
		veil: "#E7792B66",
		nthDark: "#663513",
		nthLight: "#FFA769",
		text: {
			primary: "#FFFFFF",
			secondary: "#A0A0A0",
			disabled: "#646464"
		},
		type: 'dark'
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				body: {
					background:
						"#111"
				}
			}
		}
	},
	typography: {
		fontFamily: "'Lato'",
		h1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h3: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h4: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h5: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h6: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		button: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		caption: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		overline: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		}
	},
	components: {
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fill: "#AAA"
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					display: 'none'
				}
			}
		}
	}
}

let lightTheme = {
	body: {},
	palette: {
		background: {
			default: "#E8E8E8",
			paper: "#F3F3F3",
			paperGradient: "linear-gradient(to top, #F3F3F3, #FFFFFF)",
			errorGradient: "linear-gradient(to top, #550000, #220000)",
			banner: "#EEEEEE",
		},
		primary: {
			main: "#929292",
		},
		secondary: {
			main: "#c5c5c5",
		},
		success: {
			main: '#63AF76',
			timelineDark: '#63AF7633',
		},
		error: {
			main: '#C14444',
			timelineDark: '#C1444433',
		},
		warning: {
			main: '#A79E14',
		},
		colorChart: [
			"#f09434",
			"#88498F",
			"#63AF76",
			"#7A8450",
			"#5F74A5",
			"#7DBCD5",
			"#EFA6A6",
			"#83779A",
			"#C14444",
			"#E7E247",],
		nth: "#E7792B",
		nthTheme: {
			main: "#E7792B",
			dark: '#663513',
			light: '#FFA769'
		},
		gauge0: "#C14444",
		gaugeLowLow: "#F09351",
		gaugeLow: "#DAC87B",
		gaugeTarget: "#63AF76",
		gauge1: "#63AF76",
		veil: "#E7792B66",
		nthDark: "#783C14",
		nthLight: "#ffaa50",
		text: {
			primary: "#333",
			secondary: "#646464",
			disabled: "#A0A0A0"
		},
		type: 'light'
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				body: {
					background:
						"#111"
				}
			}
		}
	},
	typography: {
		fontFamily: "'Lato'",
		h1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h3: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h4: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h5: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h6: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		button: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		caption: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		overline: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		}
	},
	components: {
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fill: "#AAA"
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					display: 'none'
				}
			}
		}
	}
}


let lightThemeCB = {
	body: {},
	palette: {
		background: {
			default: "#E8E8E8",
			paper: "#F3F3F3",
			paperGradient: "linear-gradient(to top, #F3F3F3, #FFFFFF)",
			errorGradient: "linear-gradient(to top, #550000, #220000)",
			banner: "#EEEEEE",
		},
		primary: {
			main: "#929292",
		},
		secondary: {
			main: "#c5c5c5",
		},
		success: {
			main: '#0000FF',
			timelineDark: '#0000FF33',
		},
		error: {
			main: '#FF8800',
			timelineDark: '#FF880033',
		},
		warning: {
			main: "#6666ff",
		},
		colorChart: [
			"#f09434",
			"#88498F",
			"#63AF76",
			"#7A8450",
			"#5F74A5",
			"#7DBCD5",
			"#EFA6A6",
			"#83779A",
			"#C14444",
			"#E7E247",],
		nth: "#E7792B",
		nthTheme: {
			main: "#E7792B",
			dark: '#663513',
			light: '#FFA769'
		},
		gauge0: "#0000ff",
		gaugeLowLow: "#3232ff",
		gaugeLow: "#6666ff",
		gaugeTarget: "#9999ff",
		gauge1: "#ccccff",
		veil: "#E7792B66",
		nthDark: "#783C14",
		nthLight: "#ffaa50",
		text: {
			primary: "#333",
			secondary: "#646464",
			disabled: "#A0A0A0"
		},
		type: 'light'
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				body: {
					background:
						"#111"
				}
			}
		}
	},
	typography: {
		fontFamily: "'Lato'",
		h1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h3: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h4: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h5: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h6: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		button: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		caption: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		overline: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		}
	},
	components: {
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fill: "#AAA"
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					display: 'none'
				}
			}
		}
	}
}

let cursedTheme = {
	palette: {
		background: {
			default: "#a33c00",
			paper: "#8a3200",
			paperGradient: "linear-gradient(to top, #8a3200, #ff8aed)",
			errorGradient: "linear-gradient(to top, #8a3200, #ff8aed)",
			banner: "#ff8aed"
		},
		primary: {
			main: "#ff8aed",
		},
		secondary: {
			main: "#ff4fe5",
		},
		success: {
			main: '#39008a',
			timelineDark: 'rgba(99, 175, 118,.2)',
		},
		error: {
			main: '#C14444',
			timelineDark: 'rgba(193, 68, 68,.2)',
		},
		warning: {
			main: '#A79E14',
		},
		colorChart: [
			"#f09434",
			"#88498F",
			"#63AF76",
			"#7A8450",
			"#5F74A5",
			"#7DBCD5",
			"#EFA6A6",
			"#83779A",
			"#C14444",
			"#E7E247",],
		nth: "#E7792B",
		nthTheme: {
			main: "#E7792B",
			dark: '#663513',
			light: '#FFA769'
		},
		gauge0: "#C14444",
		gaugeLowLow: "#F09351",
		gaugeLow: "#DAC87B",
		gaugeTarget: "#63AF76",
		gauge1: "#63AF76",
		veil: "#E7792B66",
		nthDark: "#663513",
		nthLight: "#FFA769",
		text: {
			primary: "#FFFFFF",
			secondary: "#A0A0A0",
			disabled: "#646464"
		},
		type: 'dark'
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				body: {
					background:
						"#111"
				}
			}
		}
	},
	typography: {
		fontFamily: "'Lato'",
		h1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h3: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h4: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h5: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		h6: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		subtitle2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body1: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		body2: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		button: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		caption: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		},
		overline: {
			fontFamily: "'Lato', 'Arial', 'Helvetica'",
			fontWeight: 100,
		}
	},
	components: {
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fill: "#AAA"
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					display: 'none'
				}
			}
		}
	}
}

export {darkTheme, lightTheme, darkThemeCB, lightThemeCB, cursedTheme}
