import {WikiEntry} from "../WikiEntry";
import image from "../images/image.PNG";
import {Grid, Typography} from '@mui/material';
import nav1 from "../images/OverviewNav.PNG";
import nav2 from "../images/IndvNav.PNG";

const Navigation = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Page Navigation" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>Our updated navigation has shifted focus from view to products. Customers can now
                            toggle between products with ease, without having to visit the Product Pane. Views can be
                            selected within a submenu below the product navigation options. The All option takes users
                            to the Customer Overviewpages, while individual product options lead to their respective products.
                            Below are navigation options for Customer Overview (left) and an individual product (right).
                        </Typography>
                    </Grid>
                    <br/>
                    <Grid item xs style={{textAlign: 'center'}}>
                        <img src={nav1}  height={"100%"} width={"50%"} resizeMode={'left'}
                             alt="Image of cell nav"
                        ></img>
                        <img src={nav2}  height={"100%"} width={"50%"} resizeMode={'left'}
                             alt="Image of system nav"
                        ></img>
                    </Grid>
                    <br/>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>The selected product is highlighted in purple, while the selected page is highlighted in blue.
                        </Typography>
                    </Grid>
                    <br/>
                </Grid>

            </WikiEntry>
        </>)
    }



    return (<>
            {page()}
        </>
    )
}

export {Navigation}