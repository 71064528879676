import srr1 from "../images/srr1.png";
import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';


const SystemRejectReasons = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="System View - Reject Reasons" height={15} width={10} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <p> The System View provides a Reject Reasons chart that displays the most common reject reasons,
                            in descending order.</p>

                            <br/>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img alt="Image of Optimizer" src={srr1}  height={"25%"} resizeMode={'center'}></img>
                            </Grid>
                            <br/>
                            <p>This Reject Reasons chart can also be scrolled with a mouse wheel
                                or using the scroll bar.
                                </p>
                        </Typography>

                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {SystemRejectReasons}