import {useAuth0}                                                             from "@auth0/auth0-react";
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import getDashboardToken                                                      from "../utils/PBI Utils/getDashboardToken";
import getReportToken                                                         from "../utils/PBI Utils/getReportToken";
import pbiProductType                                                         from "../utils/PBI Utils/pbiProductType.json"
import useInterval                                                            from "../utils/useInterval";
import {useToken}                                                             from "./TokenProvider";


const PbiContext = createContext({});

/**
 * Provides Power BI access token to children. Only makes request if auth0 token is validated
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PbiContextProvider = (props) => {

    const productPath = useMemo(() => window.location.pathname.split("/")
                                            .at(-1), [window.location.pathname])

    const {user} = useAuth0();

    const token       = useToken()
    const accessToken = token.token

    /** PowerBI Global Variables **/
    const [tokens, setTokens] = useState({})
    const [reqLoading, setReqLoading]        = useState(false)
    const [datasetIDs, setDatasetIDs]        = useState(undefined)
    const [reportIDs, setReportIDs]          = useState(undefined)
    const [currentDashboards, setCurrentDashboards] = useState([])
    const [loading, setLoading]              = useState(true)
    const [expire, setExpire]                = useState(0)
    const [refresh, setRefresh]              = useState(false)
    const [badRequestCounter, setBadRequest] = useState(0)
    const [paidMachines, setPaidMachines]    = useState({})
    const embedType = useMemo(()=>pbiProductType[productPath.toLowerCase()],[productPath])

    useEffect(() => {
    }, [refresh])

    /*** dev states ***/
    const [devReport, setDevReport]   = useState(undefined)
    const [devDataset, setDevDataset] = useState(undefined)
    const [devSubmit, setDevSubmit]   = useState(false)


    const setTokenValues = useCallback(token => setTokens(x => ({
        ...x,
        [productPath]: token
    })), [setTokens, productPath])

    useEffect(()=>{
        setCurrentDashboards([])
        if(!tokens[productPath]) setLoading(true)
        setBadRequest(0)
    },[embedType])

    useEffect(()=>{
        if(tokens[productPath])
            setCurrentDashboards(Object.keys(tokens[productPath]))
    },[tokens, productPath])

    const TokenIntervalCallback = useCallback(async () => {
        if (reqLoading && !user) {
            return
        }
        if ((expire - new Date().getTime() < 15000 || !tokens[productPath]) && badRequestCounter < 5) {
            try {
                let expired = (expire - new Date().getTime() < 15000) && expire !== 0
                switch (pbiProductType[productPath.toLowerCase()]) {
                    case "report":
                        await getReportToken(expired,
                                             setReqLoading,
                                             accessToken,
                                             devReport,
                                             devDataset,
                                             setBadRequest,
                                             setPaidMachines,
                                             setTokenValues,
                                             setDatasetIDs,
                                             setReportIDs,
                                             setExpire,
                                             setLoading,
                                             setRefresh,
                                             productPath)
                        break
                    case "dashboard":
                        await getDashboardToken(expired,
                                                setReqLoading,
                                                accessToken,
                                                setBadRequest,
                                                setExpire,
                                                setLoading,
                                                setRefresh,
                                                productPath,
                                                setTokenValues)
                        break
                    default:
                        console.log("Product isn't available")
                }
            }
            catch (e) {
                console.error('failed PowerBI token fetch: ', e)
            }
        }
    }, [reqLoading, user, expire, tokens, productPath, badRequestCounter, embedType])

    useInterval(TokenIntervalCallback, !tokens[productPath] ? 2000 : 10000, embedType)


    /*** Spoofing Test Content ***/
    const [spoofUser, setSpoofUser] = useState("dev")
    const spoofLookup               = useMemo(() => ({
        dexcom  : ['G6', 'G7'],
        illumina: ['SCOTCH'],
        g6      : ['G6'],
        g7      : ['G7'],
        dev     : []
    }), [])
    const spoofDatasetIDs           = useMemo(() => {
        let returnDatasets = {}
        if (spoofUser === "dev") {
            return datasetIDs
        }
        spoofLookup[spoofUser].forEach(x => returnDatasets[x] = datasetIDs[x])
        return returnDatasets
    }, [spoofUser, datasetIDs, spoofLookup])
    /*** End Spoofing Test Content ***/

    /*** Dev state submission ***/
    useEffect(() => {
        if (devSubmit) {
            setLoading(true)
            getReportToken(
                undefined,
                setReqLoading,
                accessToken,
                devReport,
                devDataset,
                setBadRequest,
                setPaidMachines,
                setTokenValues,
                setDatasetIDs,
                setReportIDs,
                setExpire,
                setLoading,
                setRefresh)
                .then(() => setDevSubmit(x => !x))
        }
    }, [devSubmit])
    /*** End Dev State Submission ***/

    const value = {
        tokens,
        productPath,
        setTokenValues,
        paidMachines,
        spoofUser,
        setSpoofUser,
        spoofLookup,
        datasetIDs: spoofDatasetIDs,
        currentDashboards,
        setDatasetIDs,
        reportIDs,
        setReportIDs,
        loading,
        setLoading,
        expire,
        setExpire,
        badRequestCounter,
        devDataset,
        devReport,
        setDevReport,
        setDevDataset,
        setDevSubmit
    }
    return (
        <PbiContext.Provider value={value}>
            {(embedType === pbiProductType[productPath.toLowerCase()]) ? props.children : null}
        </PbiContext.Provider>
    )
}

function usePbiContext() {
    return useContext(PbiContext)
}

export {PbiContextProvider, usePbiContext}