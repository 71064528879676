import co1 from "../images/OutputGeneralIntervalTotal.png";
import co2 from "../images/OutputGeneralTotalByMachine.png";
import co4 from "../images/OutputGeneralDragSelect.png";
import co5 from "../images/OutputGeneralDragSelectFiltered.png";
import co6 from "../images/OutputGeneralDragSelectFiltered2.png";
import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';

const CellOutput = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Cell View - Output" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography>
                            <p>The Output Chart is the large bar chart that sits below the selector summary and gauges.</p>
                            <p>Hovering over any of the bar chart segments will allow you to see a tooltip that describes the data that bar represents.
                                Within this tooltip, the exact amount of good and bad parts along with the time slice that
                                the bar represents within the chart. </p>
                            <p>Additionally, the purple line represents downtime percentage during the time period of each bar,
                                while the yellow bar represents reject percentage in the same manner.</p>
                            <h2>Output Chart: Data Visualization: </h2>

                            <ol>
                                <h3>Interval Total: </h3>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img src={co1}  height={"25%"} width={"100%"} resizeMode={'left'}
                                         alt="Image of Output Time Chart"
                                    ></img>
                                </Grid>
                                <br/>
                                <ol>Each bar represents a portion of the timeframe that was selected.
                                    Its green component represents the number of good parts produced during that portion and the red component represents the number of bad parts.
                                    The height of the bar indicates the total number of parts produced in relation to the OEE.
                                    To see the specific number of parts involved in each bar, just hover over the bar and a tooltip will appear indicating the number of good and bad parts, as well as the timeframe.
                                    This graph is useful for seeing the whole on longer timeframes.</ol>
                                    <p></p> {/*paragraph break*/}
                                <h3>Total by Machine: </h3>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img src={co2}  height={"25%"} width={"100%"} resizeMode={'left'}
                                         alt="Image of Output by Machine Chart"
                                    ></img>
                                </Grid>
                                <br/>
                                    <ol>This graph type sorts and presents the data set by machine during the timeframe.
                                    As the same with Interval total, green portions are indicative of the good parts produced and the red portions are the bad parts.
                                    This graph type is useful to determine to compare machine output over the timeframe as well as isolate overall yield issues.</ol>
                                <p></p> {/*paragraph break*/}
                                <ol>The downtime and reject percentage lines in this scenario display the downtime and reject percentages
                                    for each machine within the overall time period selected, instead of at each interval.</ol>
                                <p></p> {/*paragraph break*/}
                                <h3>Output Charts: Drag-click function  </h3>
                                <Grid item xs style={{textAlign: 'center'}}>
                                    <img src={co4} height={"25%"} width={"100%"} resizeMode={'left'}
                                         alt="Image of drag-select on Output Chart"
                                    ></img>
                                    <img src={co5} height={"25%"} width={"100%"} resizeMode={'left'}
                                         alt="Image of selected bars on Output Chart"
                                    ></img>
                                </Grid>
                                <br/>
                                <ol>By left-clicking and dragging a box over a selection, Reject Reasons, Downtime Events and other data filters to that
                                    specific timeframe.</ol>
                                <br/>
                                <p></p> {/*paragraph break*/}
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }

    return (<>
            {page()}
        </>
    )
}

export {CellOutput}