import {CircularProgress, Typography, useMediaQuery} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import EmbeddedDashboard                             from "../components/EmbeddedDashboard";
import ErrorMessage                                  from "../components/ErrorMessage";
import NthDrawer                                     from "../components/NthDrawer";
import '../components/styles/PBIEmbed.css'
import {useJokeContext}                              from "../Contexts/JokeProvider";
import {usePbiContext}                               from "../Contexts/PbiContextProvider";
import {filterTemplate}                              from "../utils/PBI Utils/FilterUtils";
import {PBIDashURLSetter, urlLoad}                   from "../utils/PBI Utils/PBI_URL";


/**
 * Embeds Power BI Report after obtaining a valid AccessToken from Power BI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PbiDash(props) {
    const pullerWidth = 32;

    /** props and Contexts **/
    const {
              pdfConfig,
              params
          } = props
    const {
              tokens,
              productPath,
              paidMachines,
              loading,
              badRequestCounter,
              currentDashboards,
              embedType
          } = usePbiContext()
    const {
              currentJoke,
              newJoke
          } = useJokeContext()

    const [refresh, setRefresh]                   = useState(true)
    const [dashboard, setDashboard]               = useState(undefined);
    const [currentDashboard, setCurrentDashboard] = useState(params.load('product', currentDashboards[0]))
    const [isLoaded, setIsLoaded]                 = useState(false) //used to trigger useEffect, where async function can be def
    const [isRendered, setIsRendered]             = useState(false) // same but for rendered
    const [firstLoad, setFirstLoad]               = useState(true) // used to load from URL if first time loading report

    const dashToken = useMemo(() => {
        if (!tokens.hasOwnProperty(productPath)) return undefined
        if (!tokens[productPath].hasOwnProperty(currentDashboard)) return undefined
        return tokens[productPath][currentDashboard]
    }, [tokens[productPath], productPath, currentDashboards, currentDashboard, embedType])

    /*** Layout States ***/
    const mobileView       = useMediaQuery(theme => theme.breakpoints.down('md'))
    const tabletView       = useMediaQuery(theme => theme.breakpoints.down('lg'))
    const loadingCircleRef = useRef()

    const layoutType = useMemo(() => {
        if (mobileView) {
            return "oneColumn"
        }
        if (tabletView) {
            return "fitToWidth"
        }
        return "fitToWidth"
    }, [mobileView, tabletView])

    /*** Dashboard Filters ***/
    const [locationFilter] = useState(null)
    const [islandFilter]   = useState(null)
    const [lineFilter]     = useState(null)
    const [macFilter]      = useState(null)
    const paidFilter       = useMemo(() => {
        if (paidMachines && paidMachines.hasOwnProperty(currentDashboard)) {
            return filterTemplate("MACHINE", paidMachines[currentDashboard], undefined, undefined, true)
        }
        return undefined
    }, [currentDashboard, paidMachines])
    const filters          = useMemo(() => [locationFilter, islandFilter, lineFilter, macFilter, paidFilter],
                                     [islandFilter, lineFilter, locationFilter, macFilter, paidFilter])
    /*** End Dashboard Filters ***/

    useEffect(() => {
        if (!currentDashboard && params.load("product")) {
            setCurrentDashboard(params.load("product", currentDashboards[0]))
        }
        else if (!params.load("product")) setCurrentDashboard(currentDashboards[0])
    }, [currentDashboards])

    // allow initializing when new dashboard/ dataset is selected
    useEffect(() => {
        setRefresh(false)
        setIsLoaded(false)
        setIsRendered(false)
    }, [currentDashboard])

    useEffect(() => {
        if (!refresh) setRefresh(true)
    }, [refresh])

    //refresh access token
    useEffect(() => {
        const setAccessTokenForDashboard = async () => {
            if (!!dashboard) await dashboard.setAccessToken(dashToken.token)
        }
        setAccessTokenForDashboard()
            .catch(e => console.log(e))
    }, [dashToken])

    // initialization block that runs after loaded, but before rendered
    useEffect(() => {
        const filteredParams = new urlLoad({
                                               ...Object.keys(params.url)
                                                        .filter(x => x !== "product")
                                                        .map(x => ({[x]: params.url[x]}))
                                           })

        /** Here's the actual code that runs **/
        if (isLoaded && firstLoad && Object.keys(filteredParams.url).length > 0) {
            // set starting filters here from params
            setFirstLoad(false)
            /** Report Example **/
            /**
             setPageToView(params.load("page", params.load("view", undefined)))
             .finally(async () => await setStartingFilters())
             .finally(async () => await setChartFromURLParams(dashboard, params))
             .finally(() => setFirstLoad(false))
             .finally(async () => setTimeout(async () => {
                    await setSlicerFromURLParams(dashboard, params)
                }, 1000))
             **/
        }
    }, [isLoaded, filters])

    //when rendered after dashboard is loaded change the joke
    useEffect(() => {
        if (isRendered) {
            newJoke()
        }
    }, [isRendered, dashboard])

    return <>
        <PBIDashURLSetter/>
        <NthDrawer products={currentDashboards} selectedProduct={currentDashboard} pullerProps={{width: pullerWidth}}
                   changeProduct={setCurrentDashboard} product={currentDashboard}
        />
        <div style={
            {
                position: 'absolute',
                left    : pdfConfig.pdf ? 0 : pullerWidth,
                width   : pdfConfig.pdf ? '100%' : `Calc(100% - ${pullerWidth}px)`
            }}
        >
            {
                loading
                ? badRequestCounter < 5
                  ? <>
                      <div
                          id="PBILoadingCircle"
                          style={{
                              position: 'fixed',
                              top     : "Calc(50vh - 100px)",
                              left    : "Calc(50vw - 100px)"
                          }}
                      >
                          <CircularProgress size={200}/>
                          <img id="loadingLogo" src="/logo192.png" alt="loading logo"/>
                          <Typography width="100%" textAlign="center" style={{color: "#fff"}} id="loadingText">
                              Loading 1
                          </Typography>
                          <br/>
                          <Typography width={200} textAlign="center" style={{color: "#fff"}} id="loadingText">
                              {currentJoke}
                          </Typography>
                      </div>
                  </>
                  : <ErrorMessage message="Failed to load the reports"/>
                : <>
                    {
                        !currentDashboard
                        ? <div
                            id="PBILoadingCircle"
                            style={{
                                position: 'fixed',
                                top     : "Calc(50vh - 100px)",
                                left    : "Calc(50vw - 100px)"
                            }}
                        >
                            <CircularProgress size={200}/>
                            <img id="loadingLogo" src="/logo192.png" alt="loading logo"/>
                            <Typography width="100%" textAlign="center" style={{color: "#fff"}}
                                        id="loadingText"
                            >
                                Loading 2
                            </Typography>
                            <br/>
                            <Typography width={200} textAlign="center" style={{color: "#fff"}}
                                        id="loadingText"
                            >
                                {currentJoke}
                            </Typography>
                        </div>
                        : <EmbeddedDashboard {
                                                 ...{
                                                     loading,
                                                     dashToken,
                                                     refresh,
                                                     loadingCircleRef,
                                                     layoutType,
                                                     setIsLoaded,
                                                     setIsRendered,
                                                     setDashboard,
                                                 }}/>
                    }
                </>

            }
        </div>
    </>
}
