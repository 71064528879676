import {useAuth0} from "@auth0/auth0-react";
import {Button, Grid, Paper, useMediaQuery, useTheme} from '@mui/material';
import React, {useEffect, useMemo} from 'react'
import {Redirect, Route, Switch, useLocation, useParams} from "react-router-dom";
import NthDrawer from "./components/NthDrawer";
import {DateTimePickerProvider} from "./Contexts/DateTimePickerProvider";
import {JokeProvider} from "./Contexts/JokeProvider";
import {PbiContextProvider} from "./Contexts/PbiContextProvider";
import {themes, useThemeContext} from "./Contexts/ThemeProvider";
import {TokenProvider} from "./Contexts/TokenProvider"
import PbiDash from "./layout/PbiDash";
import PbiReport from "./layout/PbiReport";
import {urlLoad} from "./utils/PBI Utils/PBI_URL";


/**
 * Provides Layout for Power BI page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function App(props) {
    const {
        isAuthenticated,
        loginWithPopup
    } = useAuth0();
    const [loginError, setLoginError] = React.useState(false)

    const pdfConfig = useParams()

    function useQuery() {
        return new URLSearchParams(useLocation()
            .search
            .substring(1));
    }

    const url = Object.fromEntries(useQuery()
        .entries());
    const params = useMemo(() => new urlLoad(url), [])

    const theme = useTheme()
    const {setTheme} = useThemeContext()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    useEffect(() => {
        setTheme(themes.darkTheme)
    }, [])

    if (pdfConfig.pdf === "kenobi") {
        window.location.replace("https://reddit.com/r/prequelmemes")
    }

    return (
        <JokeProvider>
            <DateTimePickerProvider>
                <TokenProvider loginError={{
                    loginError,
                    setLoginError
                }}
                >
                    {isAuthenticated ?
                        <PbiContextProvider>
                            <Switch>
                                <Route path="/obi/realtime">
                                    <PbiDash {...{
                                        pdfConfig,
                                        params
                                    }}/>
                                </Route>
                                <Route path="/obi/*">
                                    <Redirect to="/obi"/>
                                </Route>
                                <Route path="/obi">
                                    <PbiReport {...{
                                        pdfConfig,
                                        params
                                    }}/>
                                </Route>
                            </Switch>
                        </PbiContextProvider> :
                        <>
                            {pdfConfig.pdf ? null : <NthDrawer/>}
                            <Paper style={{
                                width: '100%',
                                height: '100%'
                            }}
                            >
                                <Grid style={{height: '100%'}} xs={12} item container
                                      justifyContent="center"
                                      alignContent="center"
                                      direction="column"
                                >
                                    {!loginError ?
                                        <Grid item>
                                            Please
                                            <Button onClick={loginWithPopup}>
                                                Log in
                                            </Button>
                                            to continue
                                        </Grid>
                                        : loginError?.message
                                            ? <>
                                                <Grid item width="fit-content" alignSelf="center"
                                                      textAlign="center">
                                                    Error Logging in.
                                                    <br/>
                                                    {loginError.message}
                                                    <br/>
                                                </Grid>
                                                <Grid item width="fit-content" alignSelf="center">
                                                    Please
                                                    <Button onClick={() => loginWithPopup({max_age: 0})}>
                                                        Log in
                                                    </Button>
                                                    to continue
                                                </Grid>
                                            </>
                                            : <Grid item>
                                                Error Logging in. Please enable third party cookies and try again.
                                                <br/>
                                                If you are using Firefox, please disable Enhanced Tracking
                                                Protection
                                                using the
                                                shield
                                                on the url bar, or enable third party cookies in the settings.
                                                <br/>
                                            </Grid>
                                    }
                                </Grid>
                            </Paper>
                        </>}
                </TokenProvider>
            </DateTimePickerProvider>
        </JokeProvider>
    )
}