import {models} from "powerbi-client";

export function createEmbedConfig(devMode, currentReport, reportIDs, reportToken, filters, startingDateFilter, layoutType) {
    return {
        type: 'report',
        id: devMode ? currentReport : reportIDs[currentReport],
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        accessToken: `${reportToken}`,    // Keep as empty string, null or undefined
        tokenType: models.TokenType.Embed,
        filters: [...(filters ? filters?.filter(x => x !== null) : [])],
        slicers: [{
            selector: {
                $schema: "http://powerbi.com/product/schema#slicerTargetSelector", target: {
                    table: "TimeZoneMapping", column: "AdjustedTime"
                }
            }, state: {
                filters: [startingDateFilter]
            }
        }],
        settings: {
            visualRenderedEvents: true, layoutType, customLayout: {
                displayOption: models.DisplayOption.FitToPage
            }, navContentPaneEnabled: false
        }
    };
}