import {WikiEntry} from "../WikiEntry";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

const FeatureOverview = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Feature Overview" height={15} width={3} screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography style={{overflowWrap: 'break-word'}}>OEEOptimizer ® is a visualization tool for real-time, in-process data and analytics.
                            From providing top-level views of equipment effectiveness to showcasing occurrences and frequencies of downtime events; OEEOptimizer ®
                            serves as a portable, fast way to detect, optimize, and improve processes. OEEOptimizer ® has a wide range of features that help
                            visualize how the process is running:
                            <ul>
                                <li>Realtime data refreshes provide fresh data every 15 seconds.</li>
                                <li>Timeframes of machine output are easily filtered by our Quick Select menu feature.</li>
                                <li>Customer Overview allows for a quick view of production across all of a customer's products.</li>
                                <li>Output data can be sorted by machine or time interval (or, additionally, by line for System View), allowing greater visibility
                                    across every part of the integrated ecosystem. </li>
                                <li>Gauge indicators itemize equipment effectiveness to quickly summarize run-time information. </li>
                                <li>Downtime Events and Reject Reasons make it easy to see primary causes of issues within the system.</li>
                                <li>Yield Trends, Downtime Trends, and Output Summary pages allow for a more in-depth view of different metrics.</li>
                                <li>The heatmap (available in System View) allows for a quick breakdown of machine efficiency,
                                    with the drill-through option allowing for a quick and easy way of getting more details for an individual cell.</li>
                                {/*<li>Downtime Events and Reject Reasons are mapped to our Timeline feature and can be toggled to indicate*/}
                                {/*    where events or rejects occurred. </li>*/}
                                {/*<li>Accessibility options such as Light/Dark page themes as well as a Colorblind toggle.</li>*/}
                            </ul>
                            Currently, OEEOptimizer ® supports two types of products (Cell View and System View), with the modularity to expand support to more products in
                            the future. Supported browsers and versions include Google Chrome (91+), Microsoft Edge (91+), Mozilla Firefox (89+) and Safari (13+).
                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }



    return (<>
            {page()}
        </>
    )
}

export {FeatureOverview}