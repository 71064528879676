export default async function getDashboardToken(refresh = false,
                                                setReqLoading,
                                                accessToken,
                                                setBadRequest,
                                                setExpire,
                                                setLoading,
                                                setRefresh,
                                                productPath,
                                                setTokenValues) {
    setReqLoading(!refresh)
    if (!accessToken) {
        setReqLoading(false)
        return
    }
    const res = await fetch(`/snow/getToken`, {
        method : 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            body          : JSON.stringify({
                                               tokenType: "dashboard",
                                               product  : productPath
                                           }),
            Authorization : `Bearer ${accessToken}`
        }
    })
        .then(res => {
            setReqLoading(false)
            return res
        })
        .catch(res => {
            setReqLoading(false)
            console.error(res)
            return res
        })
    if (!res.ok) {
        setBadRequest(x => x + 1)
    }
    else {
        setBadRequest(0)
    }
    const json = await res.json()
    setTokenValues(json.productTokens)
    setExpire(json.expire)

    if (!!json.productTokens) {
        setLoading(false)
    }
    setRefresh(x => !x)
}
