import cdte1 from "../images/DTEFirstActive.png";
import dtec from "../images/CellDowntimeCount.png"
import {WikiEntry} from "../WikiEntry";
import {Grid, Typography} from '@mui/material';

const CellDowntimeEvents = (props) => {
	const {
		screenSize
	} = props


	return (<>
		{/*The total height for each page should add up to 15*/}
		<WikiEntry key='cellBC' title="Cell View - Downtime Events" height={15} width={10} screenHeight={screenSize.screen.height}>
			<Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
			      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
			</Grid>
			<Grid container direction='column' alignItems="center" justifyContent={"center"}
			      style={{width: "100%", height: "80%", userSelect: "none"}}>
				<Grid item xs zeroMinWidth style={{width: "80%"}}>
					<Typography>
						<p>The Downtime Events Chart sits below the output chart and to the left of the Reject
							Reasons Chart.</p>
						<p>Hovering over any of the items within the chart will allow you to see a tooltip that describes
							the data that bar represents.
							Within this tooltip, the Downtime Event Code which identifies the Event, the name of the Event, and the time that elapsed
							while the Event was active.</p>
						<br/>
						<Grid item xs style={{textAlign: 'center'}}>
							<img src={cdte1} height={"25%"} width={"50%"} resizeMode={'center'}
								 alt="Image of Downtime Pareto Chart"
							></img>
						</Grid>
						<br/>
						<p>
							The Downtime Events Chart can also be toggled to Event counts, rather than Event duration.
						</p>
						<p>
							To switch to the counts chart, click the Count button.
						</p>
						<br/>
						<Grid item xs style={{textAlign: 'center'}}>
							<img src={dtec} height={"25%"} width={"50%"} resizeMode={'center'}
								 alt="Image of Downtime Pareto Chart"
							></img>
						</Grid>
						<br/>
						<p>
							A selected Downtime Event will retain its color, while unselected events will be faded out.
							To deselect the bar, left-click once on the bar that is selected.
							Hovering over a bar without selecting will perform the highlighting operation if another bar
							has not been selected.
							You can use the scroll bar on the right of the chart or scroll with your mouse wheel while your mouse is within the chart
							to navigate through all Downtime Events.
						</p>
					</Typography>
				</Grid>
			</Grid>
		</WikiEntry>
	</>)
}

export {CellDowntimeEvents}