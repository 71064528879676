import {Button, Checkbox, FormControl, FormControlLabel, Popover, TextField} from "@mui/material";
import React, {useRef, useState} from "react";

function DevReportForm(props) {
	const {
		devMode,
		setDevReport,
		setDevDataset,
		setCurrentReport,
		setCurrentDataset,
		setTokenValues,
		setDevMode,
		devReport,
		devDataset,
		setDevSubmit,
		reportIDs,
		currentReport,
		datasetIDs,
		currentDataset
	} = props
	const [devClick, setDevClick] = useState(false)
	const reportAnchor = useRef()
	return <>
		<img ref={reportAnchor}
		     alt="NTH logo"
		     id="NTHfixedLogo"
		     src={"/nthLite.png"}
		     width="100px"
		     style={{pointerEvents: "all"}}
		     onClick={() => setDevClick(x => !x)}
		/>
		<Popover open={devClick}
		         anchorOrigin={{vertical: "top", horizontal: "left"}}
		         transformOrigin={{vertical: "bottom", horizontal: "right"}}
		         style={{zIndex: 999}}
		         anchorEl={reportAnchor.current}>
			<FormControlLabel control={<Checkbox/>} label={"Dev Mode"} checked={devMode}
			                  onChange={x => {
				                  if (!x.target.checked) {
					                  setDevReport(undefined)
					                  setDevDataset(undefined)
					                  setCurrentReport(undefined)
					                  setCurrentDataset(undefined)
					                  setTokenValues(undefined)
				                  }
				                  setDevMode(x.target.checked)
			                  }}/>
			{devMode ? <>
				<FormControl>
					<TextField label={"report"} value={devReport} onChange={x => setDevReport(x.currentTarget.value)}/>
					<TextField label={"dataset"} value={devDataset}
					           onChange={x => setDevDataset(x.currentTarget.value)}/>
				</FormControl><br/>
				<Button onClick={() => setDevClick(false)}>Close</Button>
				<Button onClick={() => {
					setDevSubmit(true)
					setCurrentReport(devReport || reportIDs[currentReport])
					setCurrentDataset(devDataset || datasetIDs[currentDataset])
					setDevClick(false)
				}}>Submit</Button>
			</> : <Button onClick={() => setDevClick(false)}>Close</Button>}
		</Popover>
	</>
}

export default DevReportForm