import {AppBar, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {FAIcon} from "./fa-icon";
import Auth from "./Auth";
import React from "react";

export default function NthToolbar(props){
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    return <>
        <AppBar color="nthTheme" position="sticky"><Toolbar>
            <Grid container>
                <Grid item container xs={5} alignContent="center">
                    <Typography variant={mobile ? "h6" : "h5"} whiteSpace="nowrap">
                        { props.name }
                    </Typography>
                </Grid>
                <Grid container item xs={7} justifyContent="flex-end">
                    <IconButton title="Home" href="/" size="large">
                        <FAIcon icon=""/>
                    </IconButton>
                    <Auth/>
                    <IconButton
                        title="Report Bugs"
                        href={
                            `mailto:oeeo@automationnth.com/
                                        ?subject=${encodeURIComponent("OEE Optimizer Bug Report")}
                                        &body=${encodeURIComponent(window.location + '\n' +
                                navigator.userAgent + '\n\n' +
                                'Please submit your feedback below:\n')}`}
                        size="large">
                        <FAIcon icon={"\uf188".toString()}/>
                    </IconButton>
                    <IconButton size="small" title="Wiki" href="/wiki">
                        <FAIcon
                            icon={"\uf059".toString()}/>
                    </IconButton>
                </Grid>
            </Grid>
        </Toolbar></AppBar>
    </>;
}