import {models} from "powerbi-client";
import {setSlicerToValues} from "./ApplyEventHandlers";
import targetSwitch from "../TargetSwitch";

export const filterTemplate = (column = "LOCATION", values = [], table = "SCHEMA", single = true, hidden = false) => ({
	$schema: "http://powerbi.com/product/schema#basic",
	target: {
		table,
		column
	},
	operator: "In",
	values,
	filterType: models.FilterType.Basic,
	requireSingleSelection: single,
	displaySettings: {
		isLockedInViewMode: hidden,
		isHiddenInViewMode: hidden
	}
})

export const slicerLookup = {
	"LabelOrder|LabelForVisual": {
		parameter: "DateSelect",
		slicer: "Common Time Windows"
	},
	"TimeZoneMapping|AdjustedTime": {
		parameter: "Time",
		slicer: "Custom Time Range"
	}
}

export const setSlicerFromURLParams = (report, URLLoadObject) => {
	let parameters = URLLoadObject?.url
	for (let key in parameters) {
		let target = key[0].toUpperCase() + key.slice(1).toLowerCase()
		if (Object.keys(targetSwitch(target)).length)
			setSlicerToValues(report, target, parameters[key].split('|').map(x => !!+x && target === "Number" ? +x : x)).catch(e => console.log(e))
	}
}

export const setChartFromURLParams = async (report, URLLoadObject) => {
	let params = URLLoadObject?.url
	if (!params["charts"])
		return
	let load = JSON.parse(params["charts"])
	let types = Object.keys(load)
	let bookmarks = await report.bookmarksManager.getBookmarks()
	for (const type of types) {
		let bookmark = bookmarks.find(x => x.displayName === type)
		if (bookmark) {
			let innerMark = bookmark.children.find(x => x.displayName === load[type])
			await report.bookmarksManager.apply(innerMark.name)
				.catch(e => console.log(e))
		}
	}
}

export async function setAndGetFilter(report, filters) {
	if (report !== undefined) {
		if (filters.filter(x => x !== null).length > 0) {
			await report.updateFilters(models.FiltersOperations.Replace, filters.filter(x => x != null))
		}
	}
}