import React, {useMemo} from "react";
import {Grid, Paper, useTheme, Typography} from '@mui/material';
import calcWidgetAndTitleDimensions from "../utils/calcWidgetAndTitleDimensions";

// Assuming screen size 1920x1080, a monitor's usable browser space could be ~960px


/**
 * @returns {JSX.Element} - react element wrapper for a widget
 */
const Widget = (props) => {
    const {
        title,
        noTitle=false,
        height = 2,
        width = 3,
        gridProps,
        paperProps,
        paperStyle,
        titleProps,
        titleGridProps,
        screenHeight,
    } = props

    const theme = useTheme()

    const {widgetHeight, widgetWidth, titleSize, titleWidth} = calcWidgetAndTitleDimensions(height, width, screenHeight)

    return useMemo(()=>
        <Grid
            item
            container
            lg={12}
            xl={widgetWidth}
            id={(title + 'wrapper').replace(/\s/g,'')}
            style={{
                height: widgetHeight,
                padding: '4px',
                justifyContent: 'center'
            }}
            {...gridProps}
        >
            <Grid
                container
                component={Paper}
                style={{
                    width: '100%',
                    height: '100%',
                    fontFamily: "Raleway",
                    background: theme.palette.background?.paperGradient,
                    ...paperStyle
                }}
                {...paperProps}
            >
                {!noTitle?<Grid item style={{width: titleWidth+'%',height: titleSize+'%', padding: '0px 10px 5px'}} {...titleGridProps}>
                    <Typography
                        variant='subtitle1'
                        align='left'
                        style={{fontSize: widgetHeight * titleSize / (200)}}
                        {...titleProps}
                >
                    {title}
                </Typography></Grid>:''}
                {React.Children.toArray(props.children)}
            </Grid>
        </Grid>
    ,[gridProps, noTitle, paperProps, props.children, theme.palette.background?.paperGradient, title, titleGridProps, titleProps, titleSize, titleWidth, widgetHeight, widgetWidth]);
}

export { Widget }