export default async function getReportToken(refresh = false,
                                             setReqLoading,
                                             accessToken,
                                             devReport,
                                             devDataset,
                                             setBadRequest,
                                             setPaidMachines,
                                             setTokenValues,
                                             setDatasetIDs,
                                             setReportIDs,
                                             setExpire,
                                             setLoading,
                                             setRefresh,
                                             productPath) {
    setReqLoading(!refresh)
    if (!accessToken) {
        setReqLoading(false)
        return
    }
    const res = await fetch(`/snow/getToken`, {
        method : 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            body          : JSON.stringify({
                                               report   : devReport,
                                               dataset  : devDataset,
                                               tokenType: "report",
                                               product  : productPath
                                           }),
            Authorization : `Bearer ${accessToken}`
        }
    })
        .then(res => {
            setReqLoading(false)
            return res
        })
        .catch(res => {
            setReqLoading(false)
            console.error(res)
            return res
        })
    if (!res.ok) {
        setBadRequest(x => x + 1)
    }
    else {
        setBadRequest(0)
    }
    const json = await res.json()
    setPaidMachines(json.paidMachines)
    setTokenValues(json.token)
    if (!refresh) {
        setDatasetIDs(json.datasetIDs)
        setReportIDs(json.reportIDs)
    }
    setExpire(json.expire)

    if (json.token) {
        setLoading(false)
    }
    setRefresh(x => !x)
}
