/**
 * returns the column name for the selection
 * @param {string}targetColumn
 * @returns {{column: string, table: string}|{}}
 */
const targetSwitch = (targetColumn) => {
	switch (targetColumn) {
		case 'Number':
			return {
				table: 'MACHINELOCATIONS',
				column: 'NUMBER'
			};
		case 'Machine':
			return {
				table: "SCHEMA",
				column: "MACHINE"
			}
		case 'Location':
			return {
				table: "SCHEMA",
				column: "LOCATION"
			}
		case 'Islands':
		case 'Islandgroup':
			return {
				table: "SCHEMA",
				column: "ISLANDGROUP"
			}
		case 'Island':
			return {
				table: "SCHEMA",
				column: "ISLAND"
			}
		case 'Line':
			return {
				table: "SCHEMA",
				column: "LINE"
			}
		case 'Type':
			return {
				table: "SCHEMA",
				column: "TYPE"
			}
		default:
			return {}
	}
}
export default targetSwitch;