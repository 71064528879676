import {WikiEntry} from "../WikiEntry";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import outputcombined from "../images/OverallOutputCombined.png";
import outputindv from "../images/OverallOutputIndividual.PNG";
import yieldcombined from "../images/OverallYieldCombined.png";
import yieldindv from "../images/OverallYieldIndividual.PNG";

const CustomerOverview = (props) => {
    const {
        screenSize
    } = props


    const page = () => {
        return (<>
            {/*The total height for each page should add up to 15*/}
            <WikiEntry key='cellBC' title="Customer Overview" height={15} width={3}
                       screenHeight={screenSize.screen.height}>
                <Grid item container direction={"row"} alignItems={"flex-start"} justifyContent={"flex-end"}
                      style={{flexWrap: "nowrap", width: "80%", paddingTop: "10px"}}>
                </Grid>
                <Grid container direction='column' alignItems="center" justifyContent={"center"}
                      style={{width: "100%", height: "80%", userSelect: "none"}}>
                    <Grid item xs zeroMinWidth style={{width: "80%"}}>
                        <Typography style={{overflowWrap: 'break-word'}}>New to OEEOptimizer ® is Customer Overview.
                            Customer Overview allows customers to get a quick overview of all products associated with their company.
                            This is split into two main pages: Overall Output and Overall Yield.

                            <br/>
                            <h3>Overall Output</h3>
                            The Overall Output page provides a quick glance at output for all customer products within
                            the selected time period. An output chart is shown for all products combined, followed by
                            output charts for each product. The individual product charts are scrollable, allowing users
                            to cycle between products. Below is an example of the combined chart as well as one individual chart.
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={outputcombined}  height={"50%"} width={"50%"} resizeMode={'center'}
                                     alt="Image of Optimizer"
                                ></img>
                            </Grid>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={outputindv}  height={"50%"} width={"50%"} resizeMode={'center'}
                                     alt="Image of Optimizer"
                                ></img>
                            </Grid>

                            <br/>
                            <h3>Overall Yield</h3>
                            The Overall Yield page tracks yield percentages in a similar manner to the Overall Output page.
                            The layout is also similar, with one chart for combined yield and subsequent charts for individual
                            product yields. These charts are again scrollable.
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={yieldcombined}  height={"50%"} width={"50%"} resizeMode={'center'}
                                     alt="Image of Optimizer"
                                ></img>
                            </Grid>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img src={yieldindv}  height={"50%"} width={"50%"} resizeMode={'center'}
                                     alt="Image of Optimizer"
                                ></img>
                            </Grid>

                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </WikiEntry>
        </>)
    }
    return (<>
            {page()}
        </>
    )
}

export {CustomerOverview}