import '../App.css';
import '../common.css'
import {Grid, ListItemButton, Paper, Typography, useMediaQuery, useTheme} from '@mui/material'
import React, {useEffect, useMemo, useRef, useState} from "react";
import {fromEvent} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {select} from "d3-selection";
import {PinnedLogo} from "../components/pinnedLogo";
import "url-search-params-polyfill"
import {useAuth0} from "@auth0/auth0-react";
import {TokenProvider} from "../Contexts/TokenProvider";
import {Link, Route, Switch} from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {FeatureOverview} from "./pages/FeatureOverview";
import {NavigationIcons} from "./pages/NavigationIcons";
import {DatePicker} from "./pages/DatePicker";
import {MachineSelections} from "./pages/MachineSelections";
import {SystemOutput} from "./pages/SystemOutput";
import {SystemHeatmap} from "./pages/SystemHeatmap";
import {SystemDowntimeEvents} from "./pages/SystemDowntimeEvents";
import {SystemRejectReasons} from "./pages/SystemRejectReasons";
import {Navigation} from "./pages/Navigation";
import {CellOutputSummary} from "./pages/CellOutputSummary";
import {CustomerOverview} from "./pages/CustomerOverview"

import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelIcon from '@mui/icons-material/Label';
import {DownloadingData} from "./pages/DownloadingData";
import {CellRejectReasons} from "./pages/CellRejectReasons";
import {CellOutput} from "./pages/CellOutput";
import {CellDowntimeEvents} from "./pages/CellDowntimeEvents";
import {CellDetails} from "./pages/CellDetails";
import NthDrawer from "../components/NthDrawer";
import {CellYieldTrends} from "./pages/CellYieldTrends";
import {CellDowntimeTrends} from "./pages/CellDowntimeTrends";
import {SystemCellDetails} from "./pages/SystemCellDetails";
import {SystemOutputSummary} from "./pages/SystemOutputSummary";
import {SystemYieldTrends} from "./pages/SystemYieldTrends";
import {SystemDowntimeTrends} from "./pages/SystemDowntimeTrends";

require('d3')


/**
 * Creates the Main App for the Dashboard. It's where all the data lives
 * @param props - Provides access to routing and parent information
 * @returns {JSX.Element}
 * @constructor
 */
function App(props) {
	const {user, isAuthenticated, loginWithPopup} = useAuth0();
	const [loginError, setLoginError] = useState(false)

	/*** User Metadata Variables ***/
	const userAccess = useMemo(() => {
		if (user && user[`${window.location.origin}/app_metadata`]) {
			return user[`${window.location.origin}/app_metadata`] || {}
		}
		return {}
	}, [user])
	/*** End User Metadata Variables ***/

	const WidgetsNode = useRef()

	/** Theme functions **/
	const theme = useTheme()
	const [widgetsSize, setWidgetsSize] = useState({height: window.innerHeight, width: 0})
	const [winResizeSubGauges, setWinResizeSubGauges] = useState()
	const [screenSize, setScreenSize] = useState({screen: {width: 0, height: 0}});
	const [navigationOpen, setNavigationOpen] = React.useState(false);
	const [cellViewOpen, setCellViewOpen] = React.useState(false);
	const [systemViewOpen, setSystemViewOpen] = React.useState(false);
	/** end theme functions **/

	useEffect(() => {
		select('body').style('background', theme.palette.background.default)
		if (!winResizeSubGauges) {
			setWinResizeSubGauges(fromEvent(window, "resize").pipe(debounceTime(50))
				.subscribe(_ => {
					setWidgetsSize({
						height: window.innerHeight, width: window.innerWidth
					})
				}))
		}
		return function cleanup() {
			winResizeSubGauges?.unsubscribe();
		};
	}, [theme.palette.background.default, winResizeSubGauges])

	useEffect(() => {
		let screen = {
			width: widgetsSize.width, height: widgetsSize.height
		};
		setScreenSize({screen: screen,});
	}, [widgetsSize.height, widgetsSize.width])

	const EntryListItem = props => {
		const {
			to, text, collapse, onClick, isOpen, inner
		} = props
		return <ListItemButton component={collapse ? undefined : Link}
		                       onClick={collapse ? () => onClick(x => !x) : null}
		                       key={text}
		                       {...{to, style: inner ? {paddingLeft: theme.spacing(4)} : null}}>
			<ListItemIcon>{<LabelIcon/>}</ListItemIcon>
			<ListItemText primary={text}/>
			{collapse ? isOpen ? <ExpandLess/> : <ExpandMore/> : null}
		</ListItemButton>
	}

	const pages = [
		{title: "Feature Overview", type: "link", link: "/OBI/wiki/FeatureOverview", Component: FeatureOverview},
		{title: "Customer Overview", type: "link", link: "/OBI/wiki/CustomerOverview", Component: CustomerOverview},
		{
			title: "Navigation", type: "collapse", onClick: setNavigationOpen, isOpen: navigationOpen,
			content: [
				{title: "Page Navigation", link: "/OBI/wiki/PageNavigation", Component: Navigation},
				{title: "Product Navigation", link: "/OBI/wiki/ProductNavigation", Component: NavigationIcons},
				{title: "Datepicker", link: "/OBI/wiki/Datepicker", Component: DatePicker},
				{title: "Machine Selections", link: "/OBI/wiki/MachineSelections", Component: MachineSelections}
			]
		},
		{
			title: "Cell View", type: "collapse", onClick: setCellViewOpen, isOpen: cellViewOpen,
			content: [
				{title: "Output", link: "/OBI/wiki/CellViewOutput", Component: CellOutput},
				{title: "Cell Details", link: "/OBI/wiki/CellDetails", Component: CellDetails},
				{title: "Downtime Events", link: "/OBI/wiki/CellViewDowntimeEvents", Component: CellDowntimeEvents},
				{title: "Reject Reasons", link: "/OBI/wiki/CellViewRejectReasons", Component: CellRejectReasons},
				{title: "Output Summary", link: "/OBI/wiki/CellViewOutputSummary", Component: CellOutputSummary},
				{title: "Yield Trends", link: "/OBI/wiki/CellViewYieldTrends", Component: CellYieldTrends},
				{title: "Downtime Trends", link: "/OBI/wiki/CellViewDowntimeTrends", Component: CellDowntimeTrends},
			]
		},
		{
			title: "System View", type: "collapse", onClick: setSystemViewOpen, isOpen: systemViewOpen,
			content: [
				{title: "Output", link: "/OBI/wiki/SystemViewOutput", Component: SystemOutput},
				{title: "Heatmap", link: "/OBI/wiki/SystemViewHeatmap", Component: SystemHeatmap},
				{title: "Cell Details", link: "/OBI/wiki/SystemViewCellDetails", Component: SystemCellDetails},
				{title: "Downtime Events", link: "/OBI/wiki/SystemViewDowntimeEvents", Component: SystemDowntimeEvents},
				{title: "Reject Reasons", link: "/OBI/wiki/SystemViewRejectReasons", Component: SystemRejectReasons},
				{title: "Output Summary", link: "/OBI/wiki/SystemViewOutputSummary", Component: SystemOutputSummary},
				{title: "Yield Trends", link: "/OBI/wiki/SystemViewYieldTrends", Component: SystemYieldTrends},
				{title: "Downtime Trends", link: "/OBI/wiki/SystemViewDowntimeTrends", Component: SystemDowntimeTrends},
			]
		},
		{title: "Downloading Data", type: "link", link: "/OBI/wiki/DownloadingData", Component: DownloadingData}
	]

	const MakeNavLinks = pages => pages.map(link => {
		if (link.type !== "collapse")
			return <EntryListItem to={link.link} text={link.title} inner={link.type !== "link"}/>

		return <>
			<EntryListItem collapse text={link.title} onClick={link.onClick} isOpen={link.isOpen}/>
			<Collapse in={link.isOpen} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{MakeNavLinks(link.content)}
				</List>
			</Collapse>
		</>
	})

	const MakeRoutes = pages => pages.map(link => {
		const {Component} = link
		if (link.type !== "collapse")
			return <Route exact path={link.link}>
				<Component screenSize={screenSize}/>
			</Route>
		return MakeRoutes(link.content)
	})


	return (<TokenProvider loginError={{loginError, setLoginError}}>
		<Grid container spacing={2} className="AppGrid"
		      style={{height: '100vh', width: '100%', paddingLeft: 32}}>
			<NthDrawer/>
			<Grid item
			      container
			      xs={12}
			      md={3}
			      xl={2}
			      component={Paper}
			      alignContent="flex-start"
			      alignItems="flex-start"
			      style={{
				      height: useMediaQuery(theme.breakpoints.up('md')) ? `Max(100vh, ${select(".WidgetContainer").node.clientHeight})` : '',
				      backgroundColor: "#2b2b2b",//theme.palette.background?.banner,
				      paddingLeft: 16
			      }}
			      className="NavigationBar">
				<PinnedLogo/>
				<Grid item id="TitleContainer" xs={12}>
					<Typography className={"logo-text"} color='textPrimary' style={{fontSize: "1.5em", userSelect: "none"}}
					            variant="h6" noWrap align={"center"}>
						<span style={{color: theme.palette.nth}}>OEE</span>Optimizer
						<sup style={{fontSize: ".7em"}}>®</sup>
					</Typography>
				</Grid>
				<Grid item xs={12} id="SelectorContainer">
					<List component={"nav"}>
						{MakeNavLinks(pages)}
					</List>
				</Grid>
			</Grid>
			<Grid xs={12}
			      md={9}
			      xl={10}
			      item
			      container
			      ref={WidgetsNode}
			      className="WidgetContainer">
				<Switch>
					<Route exact path="/OBI/wiki/">
						<FeatureOverview screenSize={screenSize}/>
					</Route>
					{MakeRoutes(pages)}
				</Switch>
			</Grid>
		</Grid>
	</TokenProvider>);
}

export default App;
