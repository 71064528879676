import React, {useMemo} from "react";
import {useAuth0} from "@auth0/auth0-react";

/**
 * Creates an Auth0 user profile component with the user's metadata
 * @returns {boolean|JSX.Element}
 * @constructor
 */
const Profile = () => {
	const {user, isAuthenticated} = useAuth0();
	const userMetadata = useMemo(() => {
		if (user)
			return user[`${window.location.origin}/user_metadata`] || {}
		return {}

	}, [user]);

	return (
		isAuthenticated && (
			<div id="accountDetailsTooltip" style={{zIndex: 999}}>
				<img src={user.picture} alt={user.name} width={100}/>
				<h2 id="accountUserNameText">{user.name}</h2>
				<p id="accountEmailText">{user.email}</p>
				{/*User Metadata*/}
				{/*{Object.keys(userMetadata).length ? (<>*/}
				{/*	<h3>User info</h3>*/}
				{/*	<pre>{Object.keys(userMetadata).map((d) => `${d}: ${userMetadata[d]}\n`)}</pre>*/}
				{/*</>) : (*/}
				{/*	"No user metadata defined"*/}
				{/*)}*/}
			</div>
		)
	);
};

export default Profile;
